import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { requestFilters,
         setFilter,
         clearFilters,
         filtersChanged } from '../../reducers/filters';
import SaveSegmentBtn from './saveSegmentButton';

function sortFilterOpts (opts, topFilterVar='ticketArea',) {
    if (!opts || opts.length < 1) return [];
    const hierarchy = ['ticketArea', 'ticketSubArea', 'ticketChannel', 'ticketCategory', 'ticketSubCategory', 'ticketAgentName'];
    const rest = hierarchy.filter(k => k !== topFilterVar);
    const topFilterOpt = opts.find(o => o.var === topFilterVar);
    const byHierarchy = rest.map(k => opts.find(o => o.var === k));
    return [topFilterOpt].concat(byHierarchy);
}

const styles = {
    ticketArea: {
        width: "554px",
        maxHeight: "79px",
    },
    selector: {
        minWidth: "400px",
        maxWidth: "711px",
        marginBottom: "16px",
        wordWrap: "break-word",
        wordBreak: "break-word",
    },
};

const FiltersIndicator = (props) => {
    const { onClick, count, disableAction } = props;
    return (
        <div className="flexAlignRight flexCenter filterLabel">
          {count} Filtros aplicados
          {!disableAction && (
            <span
              onClick={onClick}
              className="filterLabelCross">
              X
            </span>
          )}
        </div>
    );
};

class Filters extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        const { requestFilters } = props;
        requestFilters();
    }

    toggleState = () => {
      this.setState(prev => ({
        isOpen: !prev.isOpen
      }));
    }

    handleUpdateOnClick = (e) => {
        e.preventDefault();
        const { filtersChanged } = this.props;
        filtersChanged();
    }

    handleSelectMultipleOnChange = (values, action) => {
        const { setFilter } = this.props;
        const { name } = action;
        setFilter(values, name);
    }

    handleClearOnClick = () => {
        const { clearFilters } = this.props;
        clearFilters();
    }



    render() {
        const { data, defaults, filter, hasConstraint, reportsFetching } = this.props;
        const { isOpen } = this.state;
        const limitedFields = ['ticketArea', 'ticketSubArea', 'ticketChannel', 'ticketAgentName']

        let dataToShow = [];
        data.forEach(it => {
            if (!it.hasOwnProperty('dependentFilter')) {
                dataToShow.push(it);
            }
            else {
                const found = data.find(i => i._id === it.dependentFilter);
                if (found !== undefined){
                    const parentName = found.var;
                    const values = defaults[parentName] || [];
                    let item = {...it};
                    item.choices = it.choices.filter(c => {
                        const selected = values.find(v => v.value === c.dependentValue);
                        return selected !== undefined;
                    });
                    dataToShow.push(item);
                }
            }
        });

        let optionsViews = sortFilterOpts(dataToShow).map((option, index) => {
            const { var: values } = option;
            const defaultValues = defaults[values] || null;
            const choices = option.choices.map(it => ({
                label: it.display,
                value: it.value
            }));
            let style = index === 0 ? styles.ticketArea : styles.selector;
            style = index === 1 ? { ...style, marginTop: "16px" } : style;
            const isFixed = hasConstraint && limitedFields.includes(option.var)
            return (
                <Select
                  name={option.var}
                  isDisabled={isFixed}
                  className="filtersSelect"
                  styles={{
                    container: () => ({
                      ...style,
                      display: !isOpen && index !== 0 ? 'none' : 'block',
                    }),
                    multiValue: (base) => {
                      return isFixed ? { ...base, backgroundColor: 'transparent' } : base
                    },
                    multiValueLabel: (base) => {
                      return isFixed ? { ...base, paddingRight: 6 } : base
                    },
                    multiValueRemove: (base) => {
                      return isFixed ? { ...base, display: 'none' } : base
                    }
                  }}
                  key={option._id}
                  options={choices}
                  isMulti
                  value={defaultValues}
                  placeholder={option.default}
                  closeMenuOnSelect={false}
                  isClearable={false}
                  menuPosition="fixed"
                  menuPlacement="auto"
                  onChange={this.handleSelectMultipleOnChange}
                />
            );
        });
        const firstOption = optionsViews.shift();
        const filtersCount = filter === null ? null : Object.keys(filter).length;
        return (
            <div className="flexColumn"
                 style={{maxWidth: "960px"}}>
              <div className="flexRow flexCenter">
                <div
                  onClick={this.toggleState}>
                  <span className="dot">
                    { isOpen ? '-' : '+' }
                  </span>
                </div>
                {firstOption}

                { filtersCount &&
                  <FiltersIndicator
                    onClick={this.handleClearOnClick}
                    count={filtersCount}
                    disableAction={hasConstraint}
                  />
                }

                {!hasConstraint && <SaveSegmentBtn/>}
                <button
                  disabled={reportsFetching.some(s => s === true)}
                  style={{maxHeight: "38px"}}
                  onClick={this.handleUpdateOnClick}
                  type="submit"
                  className="btn btn-sm btn-primary flexAlignRight">
                  Actualizar
                </button>
              </div>
              <div className="flexColumn" style={{marginLeft: "20px"}}>
                {optionsViews}
              </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
      Filters: { filters: { data }, filter, defaults }, Segments: { hasConstraint },
      Performance: { summary: { fetching: summaryFetching }, responsesReceived: { fetching: responsesReceivedFetching }, distribution: { fetching: distributionFetching }, variants: { fetching: variantsFetching } },
      Trends: { fetching: trendsFetching },
      Kpis: { general: { fetching: generalFetching }, history: { fetching: historyFetching }, area: { fetching: areaFetching }, detail: { fetching: detailFetching }, trends: { fetching: kpiTrendsFetching } },
      TextAnalytics: { nss: { fetching: nssFetching }, historical: { fetching: historicalFetching }, distribution: { fetching: distributionTAFetching }, mentions: { fetching: mentionsFetching } }
    } = state;
    return {
        data,
        filter,
        defaults,
        hasConstraint,
        reportsFetching: [
          summaryFetching, responsesReceivedFetching, distributionFetching, variantsFetching, trendsFetching,
          generalFetching, historyFetching, areaFetching, detailFetching, kpiTrendsFetching,
          nssFetching, historicalFetching, distributionTAFetching, mentionsFetching
        ]
    };
};

const mapDispatchToProps = dispatch => ({
    requestFilters: () => dispatch(requestFilters()),
    setFilter: (values, name) => dispatch(setFilter(values, name)),
    filtersChanged: () => dispatch(filtersChanged()),
    clearFilters: () => dispatch(clearFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
