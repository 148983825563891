import React from 'react';
import Card from '../../widgets/card';
import MyPieChart from '../../widgets/pieChart';
import Loading from '../../widgets/loading';
import { connect } from 'react-redux';
import { requestResponsesReceived } from '../../reducers/performance';
import { COLORS_CONTRAST as COLORS } from '../../utils/colors';
import { toNumber, toPercent } from '../../utils/formatter';

const styles = {
    container: {
        display: "flex",
        flexFlow: "row wrap"
    },
    color: {
        width: "28px",
        height: "14px",
        verticalAlign: "middle",
        display: "inline-block",
        marginRight: "4px",
    },
    containerValues: {
        flexGrow: "1",
        display: "block",
        marginLeft: "24px"
    },
    valuesItem: {
        fontSize: "12px",
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "flex-end",
        alignContent: "center",
        marginBottom: "3px"
    }
};


const Chart = (props) => {
    const { data: { total, display, chart, aditional } } = props;
    const dataChart = chart.map(it => ({name: it.display, value: it.n}));
    return (
        <div style={styles.container}>
          <MyPieChart
            total={total}
            display={display}
            data={dataChart}
          />
          <div style={styles.containerValues}>
            { chart.map((value, index) => (
                <div
                  key={value.display}
                  style={styles.valuesItem}>
                  <span
                    style={{...styles.color, backgroundColor: COLORS[index % COLORS.length]}}> </span>
                  <strong style={{ marginRight: '0.5em' }}>{toNumber(value.n)}</strong>
                  <div> {value.display} ({toPercent(value.percentage, 2)}) </div>
                </div>
            ))}
            <div style={{marginTop: "24px"}}>
              { aditional.map(value => (
                  <div
                    key={value.display}
                    style={styles.valuesItem}>
                    <strong style={{ marginRight: '0.5em' }}>{toPercent(value.percentage, 2)}</strong>
                    <div>{value.display}</div>
                  </div>
              ))}
            </div>
          </div>
        </div>
    );
};


class ResponseReceived extends React.Component {

    componentDidUpdate = () => {
        const { needUpdate } = this.props;
        if (needUpdate) {
            this.loadData();
        }
    }

    loadData = () => {
        const { request } = this.props;
        request();
    }

    render() {
        const { data: { fetching, data }} = this.props;
        const chart = Object.keys(data).length > 0 ? <Chart data={data}/> : null;
        const view = fetching === true ? (<Loading/>) : chart;
        return(
            <Card size="md">
              <div className="cardTitle">
                Respuestas recibidas
              </div>
              {view}
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const { Performance: { responsesReceived }} = state;
    const { needUpdate } = responsesReceived;
    return {
        data: responsesReceived,
        needUpdate
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestResponsesReceived())
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseReceived);
