import React from 'react';
import Card from '../../widgets/card.jsx';
import CardToolbar from '../../widgets/cardToolbar.jsx';
import { connect } from 'react-redux';
import { requestGeneral } from '../../reducers/kpis';
import { PieChart, Pie, Cell, XAxis, YAxis,
         Tooltip, Bar, BarChart, LabelList } from 'recharts';
import { COLORS } from '../../utils/colors';
import Loading from '../../widgets/loading';
import { ReactComponent as Increase } from '../../images/increase.svg';
import { ReactComponent as Decrease } from '../../images/decrease.svg';
import { toNumber } from '../../utils/formatter';

class MyPieChart extends React.Component {

    render() {
        const { total, display } = this.props;
        const data = [ { value: 100}];
        return(
            <div style={{
            }}>
              <PieChart
                width={200} height={100}>
                <Pie
                  data={data}
                  cx={100}
                  dataKey={"value"}
                  cy={100}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={80}
                  outerRadius={90}>
                  {
                      data.map((entry, index) =>
                               <Cell
                                 key={`${index}`}
                                 fill={COLORS[index % COLORS.length]}/>
                              )

                  }
                </Pie>
                <text
                  style={{fontSize: "30px"}}
                  x={100}
                  y={60}
                  dx={8}
                  dy={8}
                  fill="#333"
                  textAnchor="middle">
                  {total}
                </text>
                <text
                  style={{fontSize: "10px"}}
                  x={100}
                  y={80}
                  dy={8}
                  dx={8}
                  fill="#707070"
                  textAnchor="middle">
                  {display}
                </text>
              </PieChart>
            </div>
        );
    }
}
const Chart = (props) => {
    const { data: { value, display, chart,
                    aditional, flag, diff } } = props;
    const dataChart = chart.map(it => ({name: it.display, value: it.n}));
    const isPositive = flag === "positive";
    const img = isPositive ? <Increase/> : <Decrease/>;
    const color = isPositive ? "#279B4B" : "#E84147";
    return (
        <div className="flexRow">
          <MyPieChart
            total={value}
            display={display}
            data={dataChart}
          />
          <div className="flexColumn flexAlignRight">
            <div
              style={{
                  color: "#333",
                  fontSize: "12px",
                  alignItems: "center",
                  marginBottom: "16px",
                  marginRight: "8px"
              }}
              className="flexRow">
              {img}
              <div className="flexRow"
                   style={{alignItems: "baseline"}}>
                <div className="ml-2"
                     style={{fontSize: "20px", color: color}}>
                  {diff}
                </div>
                <div className="ml-1" style={{color: "#707070"}}>
                  vs periodo anterior
                </div>
              </div>
            </div>
            { aditional.map(it => (
                <div key={it.display} style={{fontSize: "12px"}}>
                  <strong>{toNumber(it.n)}</strong>
                  {`  ${it.display} (${it.percentage}%)`}
                </div>
            ))}
          </div>
        </div>
    );
};

const MyBarChart = (props) => {
    const { data, display } = props;
    return (
        <BarChart 
          width={400}
          height={115}
          data={data} >
          <YAxis orientation="right"/>
          <XAxis dataKey="display" />
          <Tooltip
            cursor={false}
            offset={40}
            separator=""
            labelFormatter={value => `${display} ${value}`}
            formatter={(v, n, p) => [`${v} (${p.payload.percentage}%)`, '']}
          />
          <Bar
            dataKey="n"
            background={{fill: '#eee'}}
            fill="#0088fe">
            <LabelList
              dataKey="n"
              position="insideBottom"
              formatter={(v) =>(toNumber(v))}
              offset={8} />
            {/* <LabelList dataKey="percentage" position="insideLeft" formatter={v => v === '0' ? '' : `(${v}%)`} offset={56} /> */}
          </Bar>
        </BarChart>
    );
};

class General extends React.Component {

    componentDidMount = () => {
      const { data, needUpdate } = this.props
        if (needUpdate) {
          this.loadData();
      }
    }

    componentDidUpdate = (prevProps) => {
        const { needUpdate } = this.props;
        if (needUpdate) {
            this.loadData();
        }
    }

    loadData = () => {
        const { request, hasConstraint, segmentData, constraintSetted } = this.props;
        if (!segmentData.length > 0) return;
        if (hasConstraint && !constraintSetted) return;
        request();
    }

    render() {
        const { data, fetching } = this.props;
        const { chart = [], display = ''} = data;
        const title = `${data.display} General`;
        const chartV = Object.keys(data).length > 1 ? <Chart data={data}/> : null;
        const barChart = chart.length > 0 ? <MyBarChart data={chart} display={display} /> : null;
        const view = fetching ? (
            <div style={{margin: "auto auto"}}>
              <Loading/>
            </div>
        ) : (
            <>
              {chartV}
              <div className="flexCenter"
                   style={{marginTop: "14px"}}>
                {barChart}
              </div>
            </>
        );
        return(
            <Card size="md">
              <CardToolbar title={title} />
              <div className="felxColumn">
                {view}
              </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {
      Kpis: { general: { data, needUpdate, fetching }},
      Segments: { segments: { data: segmentData }, hasConstraint, constraintSetted }
    } = state;
    return {
        data,
        needUpdate,
        fetching,
        hasConstraint,
        segmentData,
        constraintSetted,
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestGeneral())
});

export default connect(mapStateToProps, mapDispatchToProps)(General);
