import React from 'react';

import Card from '../../widgets/card';
import MyPieChart from '../../widgets/pieChart';
import Loading from '../../widgets/loading';
import { connect } from 'react-redux';
import { requestVariants, setVariantOption } from '../../reducers/performance';
import { COLORS_CONTRAST as COLORS } from '../../utils/colors';
import { toPercent, toNumber } from '../../utils/formatter';

const styles = {
    container: {
        display: "flex",
        flexFlow: "row nowrap"
    },
    color: {
        width: "28px",
        height: "14px",
        verticalAlign: "middle",
        display: "inline-block",
        marginRight: "4px",
    },
    containerValues: {
        flexGrow: "1",
        display: "block",
        marginLeft: "24px"
    },
    valuesItem: {
        fontSize: "12px",
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "flex-end",
        alignContent: "center",
        marginBottom: "3px"
    }
};

const Chart = (props) => {
    const { data: { total, display, chart, aditional } } = props;
    const dataChart = chart.map(it => ({name: it.display, value: it.n}));
    return (
        <div style={styles.container}>
          <MyPieChart
            total={total}
            display={display}
            data={dataChart}
          />
          <div style={styles.containerValues}>
            { chart.map((value, index) => (
                <div
                  key={value.display}
                  style={styles.valuesItem}>
                  <span
                    style={{...styles.color, backgroundColor: COLORS[index % COLORS.length]}}> </span>
                  <strong style={{ marginRight: '0.5em' }}>{toNumber(value.n)}</strong>
                  <div> {value.display} ({toPercent(value.percentage, 2)}) </div>
                </div>
            ))}
            <div style={{marginTop: "24px"}}>
              { aditional.map(value => (
                  <div
                    key={value.display}
                    style={styles.valuesItem}>
                    <strong style={{ marginRight: '0.5em' }}>{toNumber(value.n)}</strong>
                    <div style={{ marginRight: '0.5em' }}>{value.display}</div>
                    <div>({toPercent(value.percentage, 2)})</div>
                  </div>
              ))}
            </div>
          </div>
        </div>
    );
};

class InvitationSent extends React.Component {

    componentDidUpdate = (prevProps) => {
        const {data: { option: prevOption } } = prevProps;
        const { needUpdate, option } = this.props;
        if (needUpdate || prevOption !== option) {
            this.loadData();
        }
    }

    loadData = () => {
        const { request } = this.props;
        request();
    }

    handleOptionOnClick = (option, display) => () => {
        const { setVariantOption } = this.props;
        setVariantOption(option, display);
    }

    render() {
        const { data: { fetching, data, display }} = this.props;
        const chart = Object.keys(data).length > 0 ? <Chart data={data}/> : null;
        const view = fetching === true ? (<Loading/>) : chart;
        return(
            <div style={{marginLeft: "24px"}}>
              <Card size="md">
            <div className="navbar-collapse" style={{marginBottom: "20px"}}>
              <ul className="navbar-nav cardTitleDropDown">
                    <li className="nav-item dropdown">
                      <span
                        role="button"
                        className="nav-link dropdown-toggle centered"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{padding: "0px"}}
                        id="invitationSent"
                        data-toggle="dropdown">
                          {display}
                      </span>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="invitationSent">
                        <span className="dropdown-item"
                              onClick={this.handleOptionOnClick('invitationSent', 'Invitaciones enviadas')}>
                          Invitaciones enviadas
                        </span>
                        <span className="dropdown-item"
                              onClick={this.handleOptionOnClick('remindersSent', 'Recordatorios enviados')}>
                          Recordatorios enviados
                        </span>
                        <span className="dropdown-item"
                              onClick={this.handleOptionOnClick('interactionsReceived', 'Interacciones recibidas')}>
                          Interacciones recibidas
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                {view}
              </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { Performance: { variants }} = state;
    const { needUpdate, option } = variants;
    return {
        data: variants,
        needUpdate,
        option
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestVariants()),
    setVariantOption: (option, display) => dispatch(setVariantOption(option, display))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationSent);
