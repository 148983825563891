import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Card from '../../../widgets/card';
import { toNumber } from '../../../utils/formatter';

const xTickFormatter = (tick, dataLen) => {
  /* if (dataLen > 5) {
    return '';
  } */
  return tick.split('.')[0];
};

const TrendChart = ({
  data = [],
  label = '',
  xAxisKey = 'display',
  yAxisKey = 'n',
}) => (
  <Card size="wideChart" extraStyle={{ marginBottom: '1em', paddingBottom: 0 }}>
    <ResponsiveContainer width={'100%'} height={200}>
      <LineChart data={data} margin={{ left: -32, bottom: 0 }}>
        <XAxis
          padding={{ left: 40, right: 40 }}
          tickFormatter={(t) => xTickFormatter(t, data.length)}
          dataKey={xAxisKey}
          tickSize={0}
          height={56}
          label={label}
        />
        <YAxis
          dataKey={yAxisKey}
          tickSize={0}
          allowDecimals={false}
        />
        <CartesianGrid strokeDasharray="2 2" stroke="#EEEEEE" vertical={false} />
        <Tooltip
          offset={40}
          separator=""
          formatter={(v, n, p) => [`${toNumber(v)}`, '']}
        />
        <Line type="monotone"
          dataKey={yAxisKey}
          // stroke={COLORS[0]}
        />
      </LineChart>
    </ResponsiveContainer>
  </Card>
);

export default TrendChart;