import { makeTypes, makeActions as mac } from '../utils/reducers';

import { handleError } from './session';
import { getNSS, getHistorical,
         getDistribution, getMentions } from '../services/textAnalytics';
import { FILTERS_CHANGED, getFilterToApply } from './filters';

//TYPES
const t = makeTypes('textAnalytics');

const FETCH_NSS = t('fetchNSS', true);
const FETCH_HISTORICAL = t('fetchHistorical', true);
const FETCH_DISTRIBUTION = t('fetchDistribution', true);
const FETCH_MENTIONS = t('fetchMentions', true);
const SET_HISTORICAL_GROUP = t('setHistoricalGroup', false);
const SET_DISTRIBUTION_GROUP = t('setDistributionGroup', false);
const SET_MENTIONS_GROUP = t('setMentionsGroup', false);
export const FETCH_TOPICS = { START: 'textAnalytics/fetchTopics-start' }

//ACTIONS
const fetchNSSStart = mac(FETCH_NSS.START);
const fetchNSSSuccess = mac(FETCH_NSS.SUCCESS, 'payload');
const fetchNSSError = mac(FETCH_NSS.ERROR, 'error');

export const setHistoricalGroup = mac(SET_HISTORICAL_GROUP, "group", "display");
const fetchHistoricalStart = mac(FETCH_HISTORICAL.START);
const fetchHistoricalSuccess = mac(FETCH_HISTORICAL.SUCCESS, 'payload');
const fetchHistoricalError = mac(FETCH_HISTORICAL.ERROR, 'error');

export const setDistributionGroup = mac(SET_DISTRIBUTION_GROUP, "group", "display");
const fetchDistributionStart = mac(FETCH_DISTRIBUTION.START);
const fetchDistributionSuccess = mac(FETCH_DISTRIBUTION.SUCCESS, 'payload');
const fetchDistributionError = mac(FETCH_DISTRIBUTION.ERROR, 'error');

export const setMentionsGroup = mac(SET_MENTIONS_GROUP, "group", "display");
const fetchMentionsStart = mac(FETCH_MENTIONS.START);
const fetchMentionsSuccess = mac(FETCH_MENTIONS.SUCCESS, 'payload');
const fetchMentionsError = mac(FETCH_MENTIONS.ERROR, 'error');

//STATE
const initState = {
    nss: {
        data: {},
        error: null,
        fetching: false,
        needUpdate: false,
    },
    historical: {
        data: [],
        error: null,
        fetching: false,
        needUpdate: false,
        group: "weekly",
        groupDisplay: "Semanal"
    },
    distribution: {
        data: [],
        error: null,
        fetching: false,
        needUpdate: false,
        group: "area",
        groupDisplay: "Área"
    },
    mentions: {
        data: [],
        error: null,
        fetching: false,
        needUpdate: false,
        group: "comp1",
        groupDisplay: "Frases"
    },
    topics: {
        needUpdate: false,
    },
};

//REDUCER
export default function reducer(state = initState, action) {
    switch(action.type) {
    case FETCH_NSS.START:
        return {
            ...state,
            nss: {
                ...state.nss,
                fetching: true,
                error: null,
                needUpdate: false,
            },
        };
    case FETCH_NSS.SUCCESS:
        return {
            ...state,
            nss: {
                ...state.nss,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_NSS.ERROR:
        return {
            ...state,
            nss: {
                ...state.nss,
                fetching: false,
                error: action.error
            },
        };
    case FETCH_HISTORICAL.START:
        return {
            ...state,
            historical: {
                ...state.historical,
                fetching: true,
                error: null,
                needUpdate: false,
            },
        };
    case FETCH_HISTORICAL.SUCCESS:
        return {
            ...state,
            historical: {
                ...state.historical,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_HISTORICAL.ERROR:
        return {
            ...state,
            historical: {
                ...state.historical,
                fetching: false,
                error: action.error
            },
        };
    case SET_HISTORICAL_GROUP:
        return {
            ...state,
            historical: {
                ...state.historical,
                group: action.group,
                groupDisplay: action.display,
                needUpdate: true,
            }
        };
    case SET_DISTRIBUTION_GROUP:
        return {
            ...state,
            distribution: {
                ...state.distribution,
                group: action.group,
                groupDisplay: action.display,
                needUpdate: true,
            }
        };
    case FETCH_DISTRIBUTION.START:
        return {
            ...state,
            distribution: {
                ...state.distribution,
                fetching: true,
                error: null,
                needUpdate: false,
            },
        };
    case FETCH_DISTRIBUTION.SUCCESS:
        return {
            ...state,
            distribution: {
                ...state.distribution,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_DISTRIBUTION.ERROR:
        return {
            ...state,
            distribution: {
                ...state.distribution,
                fetching: false,
                error: action.error
            },
        };
    case SET_MENTIONS_GROUP:
        return {
            ...state,
            mentions: {
                ...state.mentions,
                group: action.group,
                groupDisplay: action.display,
                needUpdate: true,
            }
        };
    case FETCH_MENTIONS.START:
        return {
            ...state,
            mentions: {
                ...state.mentions,
                fetching: true,
                error: null,
                needUpdate: false,
            },
        };
    case FETCH_MENTIONS.SUCCESS:
        return {
            ...state,
            mentions: {
                ...state.mentions,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_MENTIONS.ERROR:
        return {
            ...state,
            mentions: {
                ...state.mentions,
                fetching: false,
                error: action.error
            },
        };
    case FETCH_TOPICS.START:
        return {
            ...state,
            topics: {
                ...state.topics,
                needUpdate: false,
            },
        };
    case FILTERS_CHANGED:
        return {
            ...state,
            nss: {
                ...state.nss,
                needUpdate: true,
            },
            historical: {
                ...state.historical,
                needUpdate: true,
            },
            distribution: {
                ...state.distribution,
                needUpdate: true,
            },
            mentions: {
                ...state.mentions,
                needUpdate: true,
            },
            topics: {
                ...state.topics,
                needUpdate: true,
            },
        };
    default:
        return state;
    }
};

// Thunks
export const requestNSS = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        try {
            dispatch(fetchNSSStart());
            const filters = getFilterToApply(state);
            const response = await getNSS(token, filters);
            const data = response.data;
            dispatch(fetchNSSSuccess(data));
        }
        catch(error) {
            console.error(`[textAnalytics] requestNSS:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchNSSError(error));
        }
    };


export const requestHistorical = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { TextAnalytics: { historical: { group } } } = state;
        try {
            dispatch(fetchHistoricalStart());
            const filters = getFilterToApply(state);
            const response = await getHistorical(token, filters, group);
            const data = response.data.chart;
            dispatch(fetchHistoricalSuccess(data));
        }
        catch(error) {
            console.error(`[textAnalytics] requestHistorical:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchHistoricalError(error));
        }
    };

export const requestDistribution = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { TextAnalytics: { distribution: { group } } } = state;
        try {
            dispatch(fetchDistributionStart());
            const filters = getFilterToApply(state);
            const response = await getDistribution(token, filters, group);
            const data = response.data.chart;
            dispatch(fetchDistributionSuccess(data));
        }
        catch(error) {
            console.error(`[textAnalytics] requestDistribution:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchDistributionError(error));
        }
    };

export const requestMentions = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { TextAnalytics: { mentions: { group } } } = state;
        try {
            dispatch(fetchMentionsStart());
            const filters = getFilterToApply(state);
            const response = await getMentions(token, filters, group);
            const d = response.data;
            const data = d.map(it => {
                const { aditional } = it;
                const obj =  {
                    display: it.display,
                    totals: it.n,
                    nss: it.value,
                    positive: aditional[0],
                    negative: aditional[1],
                };
                return obj;
            });
            dispatch(fetchMentionsSuccess(data));
        }
        catch(error) {
            console.error(`[textAnalytics] requestMentions:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchMentionsError(error));
        }
    };
