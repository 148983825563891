import { get, getIntervalsQuery } from './clipAPI';

const getNSS = async (token, filters) => {
    const { filter, interval } = filters;
    const intervalQuery = getIntervalsQuery(interval);
    let URL = `/TextAnalytics/nss`;
    URL += `?${intervalQuery}`;
    URL += `&survey=5f10be84dfc8a23be8fca484`;
    if (filter !== "null") {
        URL += `&filter=${filter}`;
    }
    // console.log(URL);
    return await get(URL, token);
};

const getHistorical = async (token, filters, group) => {
    const { filter, interval } = filters;
    const intervalQuery = getIntervalsQuery(interval);
    let URL = `/TextAnalytics/nss/trends`;
    URL += `?${intervalQuery}`;
    URL += `&survey=5f10be84dfc8a23be8fca484`;
    if (filter !== "null") {
        URL += `&filter=${filter}`;
    }
    URL += `&group=${group}`;
    // console.log(URL);
    return await get(URL, token);
};

const getDistribution = async (token, filters, group) => {
    const { filter, interval } = filters;
    const intervalQuery = getIntervalsQuery(interval);
    let URL = `/TextAnalytics/nss/group`;
    URL += `?${intervalQuery}`;
    URL += `&survey=5f10be84dfc8a23be8fca484`;
    if (filter !== "null") {
        URL += `&filter=${filter}`;
    }
    URL += `&group=${group}`;
    // console.log(URL);
    return await get(URL, token);
};

const getMentions = async (token, filters, group) => {
    const { filter, interval } = filters;
    const intervalQuery = getIntervalsQuery(interval);
    let URL = `/TextAnalytics/mentions`;
    if (group === 'comp1') {
        URL = `/TextAnalytics/mentions/composed`;
    }
    URL += `?${intervalQuery}`;
    URL += `&survey=5f10be84dfc8a23be8fca484`;
    if (filter !== "null") {
        URL += `&filter=${filter}`;
    }
    URL += `&group=${group}`;
    // console.log(URL);
    return await get(URL, token);
};

export { getNSS, getHistorical, getDistribution, getMentions };
