import React from 'react';

const styles = {
    card: {
        background: "#FFF",
        borderRadius: "5px",
        padding: "16px"
    },

    md: {
        width: "472px",
        height: "289px",
    },
    lg: {
        width: "716px",
        height: "289px",
    },
    sm: {
        width: "228px",
        height: "289px",
    },
    xl: {
        width: "970px",
        minHeight: "133px",
    },
    vlg: {
        width: "472px",
        height: "541px",
    },
    smMd: {
        width: "472px",
        height: "130px",
    },
    mdLg: {
        width: "472px",
        height: "443px",
    },
    xxl: {
        width: "960px",
        height: "568px",
    },
    lgMd: {
        width: "970px",
        maxHeight: "287px",
    },
    full: {
      width: '100%',
      height: '100%',
    },
    wideChart: {
      width: '100%',
    },
};

export default class Card extends React.Component {

    render() {
        const { children, size = 'md', extraStyle = {} } = this.props;
        let sizeStyle = styles[size];
        const style = {
            ...styles.card,
            ...sizeStyle,
            ...extraStyle,
        };
        return(
            <div style={style}>
              {children}
            </div>
        );
    }
}
