export const COLORS_CONTRAST = [
    '#31AAEB', // BLUE
    "#E84147", //RED
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
];

export const COLORS = [
    '#31AAEB',
    '#BA9E00',
    '#FF7784',
    '#77A3FA',
    '#A0B100',
    '#FF8A4E',
    '#FC4B05',
    '#E944A8',
    '#B896F7',
    '#DE7208',
    '#7EC21D',
    '#9272D9',
    '#FF2D4C',
    '#5980DC',
    '#CF8800',
    '#0288D1',
    '#E84147',
    '#F085E0',
    '#E7592D',
    '#49D14D',
];


export const getColorByKey = (key) => {
    let color = '#31AAEB';
    switch(key) {
    case "Promotores":
    case "Positivo":
    case "Positivos":
    case "Fácil":
    case "1":
        color = "#41e875";
        break;
    case "Negativo":
    case "Negativos":
    case "Detractores":
    case "Difícil":
    case "2":
        color = "#E84147";
        break;
    case "Neutro":
    case "Neutros":
    case "3":
        color = "#e6e6e6";
        break;
    case "Mixed":
    case "Mixtos":
    case "Mixto":
    case "4":
        color = "#31AAEB";
        break;
    default:
        color = '#7EC21D';
    }
    return color;
};
