import { makeTypes, makeActions as mac } from '../utils/reducers';

import { getSegments, create, deleteSegment as deleteSeg } from '../services/segments';
import { handleError } from './session';
import { SET_FILTER } from './filters';


const t = makeTypes('segments');

//TYPES
const FETCH_SEGMENTS = t('fetch', true);

const CREATE_SEGMENT = t('create', true);
const SEGMENT_CREATED = t('segmentCreated', false);

const DELETE_SEGMENT = t('deleteSegment', true);
const SEGMENT_DELETED = t('segmentDeleted', false);

export const SET_SEGMENT = t('setSegment', false);
export const SET_CONSTRAINT = t('setConstraint', false);

//ACTIONS
const fetchSegmentsStart = mac(FETCH_SEGMENTS.START);
const fetchSegmentsSuccess = mac(FETCH_SEGMENTS.SUCCESS, 'payload');
const fetchSegmentsError = mac(FETCH_SEGMENTS.ERROR, 'error');
export const setSegment = mac(SET_SEGMENT, 'payload');
export const setConstraint = mac(SET_CONSTRAINT, 'payload');

const createSegmentsStart = mac(CREATE_SEGMENT.START);
const createSegmentsSuccess = mac(CREATE_SEGMENT.SUCCESS, 'payload');
const createSegmentsError = mac(CREATE_SEGMENT.ERROR, 'error');
export const segmentCreated = mac(SEGMENT_CREATED, 'payload');

const deleteSegmentsStart = mac(DELETE_SEGMENT.START);
const deleteSegmentsSuccess = mac(DELETE_SEGMENT.SUCCESS, 'payload');
const deleteSegmentsError = mac(DELETE_SEGMENT.ERROR, 'error');
export const segmentDeleted = mac(SEGMENT_DELETED, 'payload');

//STATE
const initState = {
    segments: {
        data: [],
        publicSegments: [],
        privateSegments: [],
        error: null,
        fetching: false,
        needUpdate: false,
    },
    create: {
        loading: false,
        error: null,
        hasSuccess: false,
    },
    deleteSegment: {
        loading: false,
        error: null,
        hasSuccess: false,
    },
    selectedValue: null,
    constraint: null,
    hasConstraint: false,
    constraintSetted: false
};

//REDUCER
export default function reducer(state = initState, action) {
    switch(action.type) {
    case FETCH_SEGMENTS.START:
        return {
            ...state,
            segments: {
                ...state.segments,
                fetching: true,
                error: null,
                needUpdate: false,
            }
        };
    case FETCH_SEGMENTS.SUCCESS:
        const { payload } = action;
        const publicSegments = [];
        const privateSegments = [];
        let constraint = null
        let hasConstraint = false
        payload.forEach((it) => {
            if (it.isConstraint) {
                constraint = {
                  ...it,
                  label: it.name,
                  value: it.query
                }
                hasConstraint = true
                return
            }
            if (it.public) {
                publicSegments.push({
                    ...it,
                    label: it.name,
                    value: it.query});
            }
            else {
                privateSegments.push({
                    ...it,
                    label: it.name,
                    value: it.query});
            }
        });
        return {
            ...state,
            segments: {
                ...state.segments,
                fetching: false,
                data: action.payload,
                publicSegments,
                privateSegments,
            },
            constraint,
            hasConstraint
        };
    case FETCH_SEGMENTS.ERROR:
        return {
            ...state,
            segments: {
                ...state.segments,
                fetching: false,
                error: action.error,
            }
        };
    case SET_SEGMENT:
        return {
            ...state,
            selectedValue: action.payload,
        };
    case SET_CONSTRAINT:
        return {
            ...state,
            selectedValue: action.payload,
            constraintSetted: true
        };
    case SET_FILTER:
        if (action.values === null) return state;
        return {
            ...state,
            selectedValue: null
        };
    case CREATE_SEGMENT.START:
        return {
            ...state,
            create: {
                ...state.create,
                error: null,
                loading: true,
                hasSuccess: false,
            },
        };
    case CREATE_SEGMENT.SUCCESS:
        return {
            ...state,
            create: {
                ...state.create,
                error: null,
                loading: false,
                hasSuccess: true,
            },
            segments: {
                ...state.segments,
                needUpdate: true,
            }
        };
    case CREATE_SEGMENT.ERROR:
        return {
            ...state,
            create: {
                ...state.create,
                error: action.error,
                loading: false,
                hasSuccess: false,
            },
        };
    case SEGMENT_CREATED:
        return {
            ...state,
            create: {
                ...state.create,
                error: null,
                loading: false,
                hasSuccess: false,
            },
        };
    case DELETE_SEGMENT.START:
        return {
            ...state,
            deleteSegment: {
                ...state.deleteSegment,
                error: null,
                loading: true,
                hasSuccess: false,
            },
        };
    case DELETE_SEGMENT.SUCCESS:
        return {
            ...state,
            deleteSegment: {
                ...state.deleteSegment,
                error: null,
                loading: false,
                hasSuccess: true,
            },
            segments: {
                ...state.segments,
                needUpdate: true,
            }
        };
    case DELETE_SEGMENT.ERROR:
        return {
            ...state,
            deleteSegment: {
                ...state.deleteSegment,
                error: action.error,
                loading: false,
                hasSuccess: false,
            },
        };
    case SEGMENT_DELETED:
        return {
            ...state,
            deleteSegment: {
                ...state.deleteSegment,
                error: null,
                loading: false,
                hasSuccess: false,
            },
            selectedValue: null,
        };

    default:
        return state;
    }
}

//THUNKS
export const request = () =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const { Session: { token }} = state;
            dispatch(fetchSegmentsStart());
            const response = await getSegments(token);
            const data = response.data;
            dispatch(fetchSegmentsSuccess(data));
        }
        catch(error) {
            console.error(`[segments] request:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchSegmentsError(error));
        }
    };

export const createSegment = (name, isPublic) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const { Session: { token }} = state;
            const { Filters: { filter }} = state;
            if (filter === null) {
                dispatch(createSegmentsError('No se puede guardar un filtro vacío'));
            }
            else {
                const query = encodeURIComponent(JSON.stringify(filter));
                dispatch(createSegmentsStart());
                const response = await create(token, name, query, isPublic);
                const data = response.data;
                dispatch(createSegmentsSuccess(data));
            }
        }
        catch(error) {
            console.error(`[segments] createSegment:: ${error}`);
            dispatch(handleError(error));
            dispatch(createSegmentsError(error));
        }
    };

export const deleteSegment = (id) =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const { Session: { token }} = state;
            dispatch(deleteSegmentsStart());
            const response = await deleteSeg(token, id);
            const data = response.data;
            dispatch(deleteSegmentsSuccess(data));
        }
        catch(error) {
            console.error(`[segments] deleteSegment:: ${error}`);
            dispatch(handleError(error));
            dispatch(deleteSegmentsError(error));
        }
    };
