import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { connect } from 'react-redux';
import { requestDetail,
         setDetailSelectedItems,
         detailUpdateBtnClicked } from '../../../reducers/kpis';
import { toNumber } from '../../../utils/formatter';
import Loading from '../../../widgets/loading';
import { ReactComponent as Increase } from '../../../images/increase.svg';
import { ReactComponent as Decrease } from '../../../images/decrease.svg';
import { ReactComponent as Neutro } from '../../../images/neutro.svg';
import { COLORS } from '../../../utils/colors';

const styles = {
    tableSize: {
        width: "436px",
        height: "500px",
    },
    input: {
        width: "240px",
        height: "28px",
        marginBottom: "16px"
    },
    checkbox: {
        fontSize: "12px",
        color: "#707070",
        display: "flex",
        alignItems: "center",
    },
    updateBtn: {
        height: "28px",
        padding: "4px",
        fontSize: "14px"
    }
};

const getItem = (flag, value) => {
    let img = null;
    let color = "#333333";
    switch (flag) {
    case 'positive':
            img = <Increase width="8px" height="8px" style={{ marginLeft:"auto"}}/>;
        color = "#279B4B";
        break;
    case 'neutral':
            img = <Neutro width="8px" height="8px" style={{ marginLeft: "auto" }}/>;
        color = "#333333";
        break;
    case 'negative':
            img = <Decrease width="8px" height="8px" style={{ marginLeft: "auto" }}/>;
        color = "#E84147";
        break;
    default:
        img = null;
        color = "#333333";
    }
    return (
        <div className="flexRow flexAlignRight"
             style={{alignItems: "center", justifyContent: "end"}}>
          {img}
          <div
            className="ml-1"
            style={{fontSize: "10px",
                    color: color}} >
            {value}
          </div>
        </div>
    );
};

const TableList = ({columns, data, sortBy}) => {
    const { getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
          } = useTable({columns,
                        data,
                        disableSortRemove: true,
                        autoResetSortBy: false,
                        initialState: {
                            sortBy: [ sortBy ],
                        }}, useSortBy);

    return (
        <table
          style={{
              display: 'block',
              height: "415px",
              overflowY: "auto",
              overflowX: "hidden",
              fontSize: "12px",
              marginTop: "8px",
              color: "#707070",
              borderCollapse: "separate",
              borderSpacing: "0 5px"
          }}
          {...getTableProps()}>
          <thead
            style={{width: "100%"}}>
            {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                      <th
                        width={column.width}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{textAlign: (i !== 0) ? "right" : "left"}}>
                        {column.render('Header')}
                        <span
                        >
                          {column.isSorted
                           ? column.isSortedDesc
                           ? ' 🔽'
                           : ' 🔼'
                           : ''}
                        </span>
                      </th>
                  ))}
                </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(
                (row, i) => {
                    prepareRow(row);
                    return (
                        <tr
                          style={{
                              boxShadow: "0px 1px rgba(0, 0, 0, 0.16)",
                          }}
                          {...row.getRowProps()}>
                          {row.cells.map((cell, i) => {
                              return (
                                  <td
                                    style={{
                                        textAlign: (i !== 0) ? "right" : "left",

                                    }}
                                    {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                              );
                          })}
                        </tr>
                    );
                }
            )}
          </tbody>
        </table>
    );
};

const Display = (props) => {

    const { display, color, checked } = props;

    return (
        <label
            style={{ fontWeight: "normal", ...styles.checkbox}} className="script">
          <input
            className="unique-class"
            name={display}
            style={{marginRight: "2px",
                    backgroundColor: "#7bbe72"}}
            type="checkbox"
            onChange={props.onChange}
            checked={checked}
          />
          <span className="geekmark"
                style={{ backgroundColor: color}}></span>
          {display}
        </label>
    );
};


const TableContainer = (props) => {

    const { data, type } = props;
    const columns = React.useMemo( () => [
        { Header: "Nombre",
          accessor: ({display, color, checked}) => {
              return ( <Display
                        display={display}
                        onChange={props.onChange}
                        color={color}
                        checked={checked}
                      />);
          },
          width: "30%",
          sortType: (itemA, itemB) => {
              const a = itemA.original.display;
              const b = itemB.original.display;
              return a > b ? 1 : -1;
          }
        },
        { Header: type.toUpperCase(),
          accessor: "value",
          width: "20%" },
        { Header: "Cambio",
          accessor: ({diff, flag}) => getItem(flag, diff),
          sortType: (itemA, itemB) => {

              const objA = itemA.original;
              const symbolA = objA.flag === "negative" ? -1 : 1;
              const a = (objA.diff || 0) * symbolA;

              const objB = itemB.original;
              const symbolB = objB.flag === "negative" ? -1 : 1;
              const b = (objB.diff || 0) * symbolB;

              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
          },
          width: "25%" },
        { Header: "Res",
          accessor: ({n}) => toNumber(n),
          sortType: (itemA, itemB) => {
              const a = itemA.original.n;
              const b = itemB.original.n;
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
          },
          width: "25%" },
    ], []);

    const sortBy = { id: "Nombre", desc: false};
    return(
        <TableList
          columns={columns}
          data={data}
          sortBy={sortBy}
        />
    );
};

class Table extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hideZeroResponse: false,
            showSelectedOnly: false,
            selectAll: false,
            search: '',
        };

        const { setSelectedItems } = this.props;
        setSelectedItems([]);
    }

    componentDidUpdate = (prevProps) => {
        const { needUpdate, selectedItems, data } = this.props;
        const { selectAll } = this.state;
        if (needUpdate) {
            this.loadData();
        }

        const dataSize = data.length > 20 ? 20 : data.length;

        if (!selectAll && selectedItems.length === dataSize) {
            this.setState({selectAll: true});
        }
        if (selectAll && selectedItems.length !== dataSize) {
            this.setState({selectAll: false});
        }
    }

    loadData = () => {
        const { request } = this.props;
        request();
    }

    handleCheckboxOnChange = ({target: {name, checked}}) => {
        const { setSelectedItems, selectedItems } = this.props;
        if (selectedItems.length > 20) {
            console.error(`No more options to select`);
            return;
        }
        let items = [...selectedItems];
        if (checked) {
            items.push(name);
        }
        else {
            const index = items.indexOf(name);
            if (index !== -1) {
                items.splice(index, 1);
            };
        }
        setSelectedItems(items);
    }

    handleZeroResponseOnChange = ({target: { checked}}) => {
        this.setState({hideZeroResponse: checked});
    }

    handleShowSelectedOnChange = ({target: { checked}}) => {
        this.setState({showSelectedOnly: checked});
    }

    handleSelectAllOnChange = ({target: { checked }}) => {
        const { setSelectedItems, selectedItems } = this.props;
        const data = this.getFilteredData();
        let items = [];
        if (checked) {
            data.forEach((it, index) => {
                if (index < 20) {
                    items.push(it.display);
                }
            });
        }
        setSelectedItems(items);
        //this.setState({selectAll: checked});
    }

    handleInputOnChange = ({target}) => {
        const { value } = target;
        this.setState({search: value});
    }

    handleUpdateBtnClicked = () => {
        const { onUpdateClicked } = this.props;
        onUpdateClicked();
    }

    getFilteredData = () => {
        const { data, selectedItems } = this.props;
        const { search,
                hideZeroResponse,
                showSelectedOnly,
                selectAll } = this.state;

        let filterData = data.filter(d => {
            if (hideZeroResponse && d.n === 0) {
                return false;
            }
            if (showSelectedOnly) {
                const index = selectedItems.indexOf(d.display);
                return index !== -1;
            }
            if (search === "") return true;

            if (!d.display
                .toLowerCase()
                .includes(search.toLowerCase())) {
                return false;
            }
            return true;
        });
        let markData = filterData.map((it) => {
            const { display } = it;
            const index = selectedItems.indexOf(display);
            const color = index === -1 ? "#FFFFFF" : COLORS[index];
            const checked = index !== -1;

            return {
                ...it,
                color,
                checked,
            };
        });
        return markData;
    }

    render() {
        const { data,
                type,
                fetching,
                selectedItems } = this.props;
        const { hideZeroResponse,
                showSelectedOnly,
                selectAll,
                search } = this.state;

        const markData = this.getFilteredData();

        const selectedView = selectedItems.length !== 0 || showSelectedOnly ? (
            <label
              style={{
                  marginLeft: "64px",
                  fontSize: "10px",
                  fontWeight: "normal",
                  ...styles.checkboxMenu
              }}>
              <input
                    style={{ marginRight: "8px", verticalAlign: "text-top"}}
                type="checkbox"
                onChange={this.handleShowSelectedOnChange}
              />
              Mostrar solo seleccionados
            </label>
        ) : null;

        const view = fetching ? (
            <div className="marginAuto">
              <Loading/>
            </div>
        ) : (
            <TableContainer
              type={type}
              data={markData}
              selectAll={selectAll}
              onChange={this.handleCheckboxOnChange}
            />
        );

        return(
            <div style={styles.tableSize}>
              <div className="felxColumn">

                <div className="flexRow">
                  <input
                    style={styles.input}
                    type="text"
                    className="form-control"
                    placeholder="Buscar"
                    value={search}
                    onChange={this.handleInputOnChange}
                  />

                  <button
                    onClick={this.handleUpdateBtnClicked}
                    type="button"
                    className="btn btn-outline-primary flexAlignRight"
                    style={styles.updateBtn}>
                    Actualizar
                  </button>

                </div>

                <div className="flexRow">
                  <label
                    style={{
                        fontSize: "10px",
                        fontWeight: "normal",
                        ...styles.checkboxMenu
                    }}>
                    <input
                        style={{ marginRight: "8px", verticalAlign: "text-top"}}
                      type="checkbox"
                      checked={hideZeroResponse}
                      onChange={this.handleZeroResponseOnChange}
                    />
                    Ocultar registro sin respuestas
                  </label>
                  {selectedView}
                </div>
                <label
                  style={{
                      fontSize: "10px",
                      fontWeight: "normal",
                      ...styles.checkboxMenu,
                  }}>
                  <input
                    style={{ marginRight: "8px", verticalAlign: "text-top"}}
                    type="checkbox"
                    checked={selectAll}
                    onChange={this.handleSelectAllOnChange}
                  />
                  Seleccionar Todos
                </label>
                {view}
              </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { Kpis: { type,
                    detail: { data,
                              fetching,
                              needUpdate,
                              selectedItems } } } = state;
    return {
        data,
        fetching,
        needUpdate,
        selectedItems,
        type,
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestDetail()),
    setSelectedItems: (items) => dispatch(setDetailSelectedItems(items)),
    onUpdateClicked: () => dispatch(detailUpdateBtnClicked()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
