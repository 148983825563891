import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { filtersChanged } from '../reducers/filters';

class TriggerUpdate extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      console.log('Nueva ruta');
      this.props.filtersChanged()
    }
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  filtersChanged: () => dispatch(filtersChanged())
});

export default connect(null, mapDispatchToProps)(withRouter(TriggerUpdate));