import React, { Component } from 'react';
import { isEmailValid, isPasswordValid } from '../../utils/formValidation';
import FormInput from '../../widgets/FormInput';
import LoadingButton from '../../widgets/LoadingButton';
import { Link } from 'react-router-dom';

const validate = (email, password) => {
    const errors = {};

    try {
        isEmailValid(email);
    }
    catch(e) {
        errors.email = e.message;
    }
    try {
        isPasswordValid(password);
    }
    catch(e) {
        errors.password = e.message;
    }
    return errors;
};

export default class LoginForm extends Component {

    state = {
        errors: {},
    }

    handleChange = target => {
        this.setState({
            [target.name]: target.value
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        const { email, password } = this.state;
        const result = validate(email, password);
        this.setState({errors: result});
        if (!Object.keys(result).length) {
            const { onSubmit } = this.props;
            if (typeof onSubmit === 'function') {
                onSubmit(email, password);
            }
        }
    }

    render() {
        var { isLoading } = this.props;
        return (
            <form
              noValidate
              onSubmit={this.handleSubmit}>
              <FormInput
                type="email"
                name="email"
                placeholder="Correo Electrónico"
                errorMessage={this.state.errors.email}
                handleChange={this.handleChange}
              />
              <FormInput
                type="password"
                name="password"
                placeholder="Contraseña"
                errorMessage={this.state.errors.password}
                handleChange={this.handleChange}
              />
                <div className="login-actions">
                    <Link to="/olvide-contrasena" className="action-link login">
                        ¿Olvidaste tu contraseña?
                    </Link>
                    <LoadingButton
                        title="Iniciar Sesión"
                        isLoading={isLoading}
                    />
                </div>
            </form>
        );
    }
}

