import firebase from '../firebase';

//TYPES
const SET_USER = 'session/set/user';
const SET_TOKEN = 'session/set/token';
const EXPIRED_SESSION = 'session/expired';

//ACTIONS

export const setUser = user => ({
    type: SET_USER,
    user
});

export const setToken = token => ({
    type: SET_TOKEN,
    token
});

export const expiredSession = () => ({
    type: EXPIRED_SESSION,
});


// STATE

const initialState = {
    user: null,
    token: null,
};

// REDUCER

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SET_USER:
        return {
            ...state,
            user: action.user
        };
    case SET_TOKEN:
        return {
            ...state,
            token: action.token
        };
    case EXPIRED_SESSION:
        return {
            ...state,
            token: null,
            user: null
        };
    default: return state;
    }
}

export const loadSession = () =>
    async (dispatch, getState) => {
        firebase.isInitialized()
            .then(async val => {
                const username = val.displayName || val.email;
                dispatch(setUser(username));
                const token = await val.getIdToken();
                // console.log(`[session] loadSession:: token: ${token}`);
                // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsZXZlbCI6IlJPT1QiLCJlbWFpbCI6InJvb3RAYXBpLmNvbSIsImdlbmVyYXRlIjp0cnVlLCJfaWQiOiI1ZjFiM2JhNjJkYTYwYTVlMWIzZTdiMDQiLCJpYXQiOjE1OTU2NDc1NTN9.JVD-jIYj3Wzsdyu3vnNJK42a3Ou69G35Tp9hEksHZ_Q";
                dispatch(setToken(token));
            })
            .catch(ex => {
                //TODO
                console.error(`[session] loadSession: ${ex}`);
            });
    };

export const handleError = (error) =>
    async (dispatch, getState) => {
        // console.log('[session] handleError', error);
        // console.error(error);
        const response = error.response;
        if (response === undefined) return;
        const data = response.data;
        if (data === undefined) return;
        const statusCode = data.statusCode || 500;
        if (statusCode === 401) {
            try {
                await firebase.logout();
            }
            catch(error) {
                console.error(`[session] handleError: ${error}`);
            }
            dispatch(expiredSession());
        }
    };
