import React, { Component } from 'react';
import RecoverPasswordForm from './RecoverPasswordForm';
import img from '../../images/brand-logo-white_2.png';
import firebase from '../../firebase';
import queryString from 'query-string';
import ErrorAlert from '../../widgets/ErrorAlert';


export default class RecoverPassword extends Component {

    state = {
        error: null,
        isLoading: false,
    }

    handleSubmit = async (password) => {
        const { history } = this.props;
        const params = queryString.parse(this.props.location.search);
        const token = params.oobCode;
        this.setState({
            error: null,
            isLoading: true
        });
        try {
            await firebase.sendNewPassword(token, password);
            history.replace('/login');
        }
        catch(ex) {
            const code = ex.code || "default";
            this.handleError(code);
        }
        finally {
            this.setState({ isLoading: false });
        }
    }

    handleError = (errorCode) => {
        var error = "";
        switch (errorCode) {
            case "auth/expired-action-code":
            case "auth/invalid-action-code":
                error = "El código para recuperar tu contraseña ha caducado o es invalido.";
            break;
            case "auth/user-disabled":
            case "auth/user-not-found":
                error = "El usuario esta deshabilitado o ha sido eliminado";
                break;
            case "auth/weak-password":
                error = "La contraseña es debil.";
                break;
            default:
                error = "Se ha producido un error al intentar iniciar sesión.";
                break;
        }
        this.setState({ error: error });
    }

    handleErrorDismiss = () => {
        this.setState({error: null});
    }

    render() {
        const { isLoading, error } = this.state;

        return (
            <div className="login-wrapper">
                <div className="login-card">
                    <div className="login-brand">
                        <img src={img}  height="80" alt=""/>
                        <h2 className="login-title">Customer Happiness</h2>
                    </div>
                    <div className="w-form">
                        { error ?
                            <ErrorAlert
                            error={error}
                            onDismiss={this.handleErrorDismiss}/>
                            : null
                        }
                        <RecoverPasswordForm
                            isLoading={isLoading}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </div>
                <div className="login-media">
                    <div className="login-media-overlay"></div>
                </div>
            </div>
        );
    }
}
