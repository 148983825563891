import React from 'react';
import './navbar.css';
import DateFilter from '../../widgets/dateFilter/dateFilter.jsx';
import { connect } from 'react-redux';
import { fetchFilters, setSegment,
       requestGenExport } from '../../reducers/export';
import Select from 'react-select';
import { EXPORT_TYPE } from '../../services/files';

class Navbar extends React.Component {

    constructor(props) {
        super(props);
        const { token, request } = props;
        request(token);
    }

    handleSegmentOnChange = ({value}) => {
        const { setSegment } = this.props;
        setSegment(value);
    }

    handleExportBtnOnClick = (e) => {
        e.preventDefault();
        const { segmentQuery: exportType, interval, genExport, token, filters } = this.props;
        const constraint = filters.find(s => s.isConstraint)
        let extraConditions = {}
        if (constraint) {
          const aux = decodeURIComponent(constraint.query)
          extraConditions = Object.assign({}, JSON.parse(aux))
        }
        genExport(interval.start,
                  interval.end,
                  token,
                  extraConditions,
                  exportType);
    }

    render() {
        const { filters, interval } = this.props;
        const { start, end } = interval;
        // NOTE: Se comenta para corregir CLCX-263
        // let options = filters.map(filter => {
        //     return {
        //         label: filter.name,
        //         value: filter.query,
        //     };
        // });
        // options.unshift({label: "Todas", value: "{}"});
        let options = [
          { label: "Todas las respuestas", value: EXPORT_TYPE.RESPONSES },
          { label: "Tickets rechazados", value: EXPORT_TYPE.BOUNCED },
          { label: "Tickets internos", value: EXPORT_TYPE.INTERNAL },
          { label: "Tickets mal asignados", value: EXPORT_TYPE.MISALLOCATED },
        ]
        return(
            <div style={{ flexDirection: "column", width: "100%"}}>
              <div className="navBar">
                <span className="title">
                  <strong>
                    Exportar
                  </strong>
                </span>
                <form
                  noValidate
                  style={{marginTop: "24px"}}
                  className="form-inline">
                  <Select
                    options={options}
                    className="filtersSelect mr-3"
                    defaultValue={options[0]}
                    onChange={this.handleSegmentOnChange}
                  />
                  <DateFilter
                    start={start}
                    end={end}
                  />
                  <button
                    disabled={this.props.generating}
                    onClick={this.handleExportBtnOnClick}
                    type="submit"
                    className="btn btn-primary ml-3">
                    Exportar
                  </button>
                </form>
              </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { Export: { filters: { data }, segmentQuery, generating }} = state;
    const { Filters: { filters: { interval } }} = state;
    const { Session: { token }} = state;
    return {
        token,
        filters: data,
        segmentQuery,
        generating,
        interval
    };
};

const mapDispatchToProps = dispatch => ({
    request: token => dispatch(fetchFilters(token)),
    setSegment: query => dispatch(setSegment(query)),
    genExport: (start, end, token, filters, exportType) =>
      dispatch(requestGenExport(start, end, token, filters, exportType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
