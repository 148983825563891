import React, { useState, useEffect, useCallback } from 'react';
import MyPieChart from '../../widgets/pieChart';
import Loading from '../../widgets/loading';
import { useSelector } from 'react-redux';
import { getTopicScorecard } from '../../services/textAnalytics-topic'
import { getColorByKey } from '../../utils/colors';
import { toNumber, toPercent } from '../../utils/formatter';

const styles = {
    container: {
        display: "flex",
        flexFlow: "row wrap"
    },
    color: {
        width: "28px",
        height: "14px",
        verticalAlign: "middle",
        display: "inline-block",
        marginRight: "4px",
    },
    containerValues: {
        flexGrow: "1",
        display: "block",
        marginLeft: "24px"
    },
    valuesItem: {
        fontSize: "12px",
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "flex-end",
        alignContent: "center",
        marginBottom: "3px"
    }
};


const Chart = (props) => {
    const { data: { total, display, chart } } = props;
    const dataChart = chart.map(it => ({name: it.display, value: it.n}));
    return (
        <div style={styles.container}>
          <MyPieChart
            total={total}
            display={display}
            data={dataChart}
            useColorsByKey={true}
          />
          <div style={styles.containerValues}>
            { chart.map((value, index) => (
                <div
                  key={value.display}
                  style={styles.valuesItem}>
                  <span
                    style={{...styles.color, backgroundColor: getColorByKey(value.display)}}> </span>
                  <strong style={{ marginRight: '0.5em' }}>{toNumber(value.n)}</strong>
                  <div> {value.display} ({toPercent(value.percentage, 2)}) </div>
                </div>
            ))}
          </div>
        </div>
    );
};


const TopicScorecard = ({ topic }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const token = useSelector(state => state.Session.token)
  const filter = useSelector(state => state.Filters.filter)
  const interval = useSelector(state => state.Filters.filters.interval)
  const needUpdate = useSelector(state => state.TextAnalytics.topics.needUpdate )

  const loadData = useCallback(async () => {
    setLoading(true)
    let extraFilter = {}
    if (topic !== 'all') {
      extraFilter['COMENTARIO_classify.Topic'] = topic
    }
    const response = await getTopicScorecard(token, interval, filter, extraFilter)
    setData(response.data)
    setLoading(false)
  }, [token, interval, filter, topic])

  useEffect(() => {
    needUpdate && loadData()
  }, [needUpdate, loadData])

  return (
    <div className="topic-card">
      <div className="cardTitle">
        Sentimiento de comentarios
      </div>
      {loading && <Loading />}
      {!loading && <Chart data={{ ...data, chart: data.aditional, total: data.value }}/>}
    </div>
  )
}

export default TopicScorecard
