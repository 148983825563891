import React from 'react';

const Item = (props) => {
    const { selected, item } = props;
    const isSelected = selected === item.value;
    const style = isSelected ? "cardMenuItemSelected" : "cardMenuItem";
    return(
        <div className={style}>
          {item.label}
        </div>
    );
};

export default class CardMenu extends React.Component {

    handleOptionOnClick = (value) => () => {
        const { onChange } = this.props;
        if (typeof onChange !== 'function') return;
        onChange(value);
    }

    render() {
        const { selected, options } = this.props;
        return(
            <nav >
              <ul className="cardMenu">
                { options.map(option => (
                    <li
                      onClick={this.handleOptionOnClick(option.value)}
                      key={option.value}>
                      <Item
                        selected={selected}
                        item={option}
                      />
                    </li>
                ))}
              </ul>
            </nav>
        );
    }
}
