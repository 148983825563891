import React from 'react'

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="8.162" viewBox="0 0 10.828 8.162">
    <g id="Group_121" data-name="Group 121" transform="translate(-278.086 -180.086)">
      <line id="Line_20" data-name="Line 20" x2="2.667" y2="2.667" transform="translate(279.5 184.167)" fill="none" stroke="#0288d1" strokeLinecap="round" strokeWidth="2" />
      <line id="Line_21" data-name="Line 21" x1="5.333" y2="5.333" transform="translate(282.167 181.5)" fill="none" stroke="#0288d1" strokeLinecap="round" strokeWidth="2" />
    </g>
  </svg>
)

const Dropdown = ({ options, selectedValue, display, menuTitle, onChange }) => (
  <div className="dropdown t-controls_dropdown">
    <div className="dropdown-toggle t-controls_dropdown-toggle" data-toggle="dropdown">
      <img src="/images/t_feed-icon-menu.svg" alt="" />
    </div>
    <div className="dropdown-menu dropdown-menu-right">
      <span className="dropdown-header"><b>{menuTitle}</b></span>
      { options.map(o => (
        <span
          key={o.label}
          className="dropdown-item"
          onClick={() => onChange && onChange(o)}>
          {o.value === selectedValue && <Icon />}
          {o.label}
        </span>
      )) }
    </div>
  </div>
)

export { Dropdown }
