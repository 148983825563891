import React from 'react';
import { Route } from 'react-router-dom';
import Trends from './trends/trends.jsx';
import Indicators from './indicators';
import Audit from './audit';
import AuditTrend from './audit-trend';

const Performance = () => (
  <>
    <Route exact path="/desempeno/indicadores" component={Indicators} />
    <Route exact path="/desempeno/tendencias" component={Trends}/>
    <Route exact path="/desempeno/auditoria" component={Audit}/>
    <Route exact path="/desempeno/tendencia-auditoria" component={AuditTrend}/>
  </>
)

export default Performance;