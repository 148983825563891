import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';

import { createSegment, segmentCreated } from '../../reducers/segments';
import FormInput from '../../widgets/FormInput';
import Loading from '../../widgets/loading';

const styles ={
    style: {
        fontSize: "10px",
        color: "#707070",
        padding: "4px",
        cursor: "pointer"
    },
    label: {
        fontSize: "14px",
        color: "#707070"
    }
};

const validate = (name) => {
    const errors = {};

    if (name === undefined) {
        errors.name = 'El Nombre es un campo requerido';
    }
    else {
        const len = name.length;
        if (len === 0) {
            errors.name = 'El Nombre es un campo requerido';
        }
        if (len > 40) {
            errors.name = 'El Nombre no puede ser mayor a 40 caracteres';
        }
    }
    return errors;
};

class SaveSegmentBtn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            errors: {},
            radioPrivate: false,
            radioPublic: true,
            show: false,
        };
    }

    componentDidUpdate = (prevProps) => {
        const { hasSuccess: prevHasSuccess } = prevProps;
        const { hasSuccess, created } = this.props;
        if (hasSuccess && prevHasSuccess !== hasSuccess) {
            created();
            this.handleCloseModal();
        }
    }

    handleSubmit = () => {
        const { name, radioPublic } = this.state;
        const result = validate(name);
        this.setState({errors: result});
        if (!Object.keys(result).length) {
            const { create } = this.props;
            create(name, radioPublic);
        }
    }

    handleNameOnChange = ({value}) => {
        this.setState({name: value});
    }

    handlePublicOnChange = () => {
        this.setState({radioPublic: true, radioPrivate: false});
    }

    handlePrivateOnChange = () => {
        this.setState({radioPrivate: true, radioPublic: false});
    }

    handleOpenModal = () => {
        this.setState({show: true});
    }

    handleCloseModal = () => {
        this.setState({
            name: '',
            errors: {},
            radioPrivate: false,
            radioPublic: true,
            show: false,
        });
    }

    render() {
        const { radioPublic, show, name, radioPrivate } = this.state;
        const { loading, filter } = this.props;
        const view = loading ? (
            <div className="marginAuto">
              <Loading/>
            </div>
        )
              :
              (<>
                 <button
                   type="button"
                   className="btn btn-error"
                   onClick={this.handleCloseModal}>Cancelar</button>
                 <button
                   type="button"
                   onClick={this.handleSubmit}
                   className="btn btn-primary">Guardar</button>
               </>
              );
        return(
            <>
              { filter &&
                <div
                  style={styles.style}
                  onClick={this.handleOpenModal}>
                  Guardar Segmento
                </div>
              }

              <Modal
                show={show}
                onHide={this.handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>CREAR NUEVO SEGMENTO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormInput
                    type="text"
                    name="name"
                    placeholder="Nombre del Segmento"
                    value={name}
                    errorMessage={this.state.errors.name}
                    handleChange={this.handleNameOnChange}
                  />
                  <p style={styles.label}> Compartido con </p>
                  <div className="form-check"
                       onClick={this.handlePrivateOnChange} >
                    <input className="form-check-input"
                           name="isPublic"
                           type="radio"
                           id="radioPrivate"
                           checked={radioPrivate}
                           onChange={this.handlePrivateOnChange}
                    />
                    <label style={styles.label}>Privado (solo yo puedo verlo)</label>
                  </div>
                  <div className="form-check"
                       onClick={this.handlePublicOnChange}>
                    <input className="form-check-input"
                           name="isPublic"
                           type="radio"
                           id="radioPublic"
                           checked={radioPublic}
                           onChange={this.handlePublicOnChange}
                    />
                    <label style={styles.label}>Compartido (cualquier con acceso puede verlo)</label>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {view}
                </Modal.Footer>
              </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    const { Segments: { create: { loading, error, hasSuccess } } } = state;
    const { Filters: { filter }} = state;
    return {
        loading,
        error,
        hasSuccess,
        filter
    };
};

const mapDispatchToProps = dispatch => ({
    create: (name, isPublic) => dispatch(createSegment(name, isPublic)),
    created: () => dispatch(segmentCreated()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveSegmentBtn);
