import React, { Component } from 'react';
import firebase from '../../firebase';
import img from '../../images/brand-logo-white_2.png';
import LoginForm from './LoginForm';
import ErrorAlert from '../../widgets/ErrorAlert';

export default class Login extends Component {

    state = {
        error: null,
        loading: false
    }

    componentDidMount = () =>  {
        this.verifySession();
    }

    componentDidUpdate = () => {
        this.verifySession();
    }

    verifySession = () => {
        const { history } = this.props;
        firebase.isInitialized()
            .then(val => {
                if (val) {
                    history.replace('/');
                }
            });
    }

    handleOnSubmit = async (email, password) => {
        const { history } = this.props;
        this.setState({
            loading: true,
            error: null
        });
        try {
            await firebase.login(email, password);
            history.replace('/desempeno/indicadores');
        }
        catch(ex) {
            const code = ex.code || "default";
            this.handleError(code);
        }
        finally {
            this.setState({loading: false});
        }
    }

    handleError = (errorCode) => {
        var error = "";
        switch(errorCode) {
        case "auth/invalid-email":
        case "auth/user-not-found":
        case "auth/wrong-password":
            error = "El Usuario o Contraseña son incorrectos.";
            break;
        case "auth/user-disabled":
            error = "El usuario esta deshabilitado.";
            break;
        default:
            error = "Se ha producido un error al intentar iniciar sesión.";
            break;
        }
        this.setState({
            error: error
        });
    }

    handleErrorDismiss = () => {
        this.setState({
            error: null
        });
    }

    render() {
        const { error, loading } = this.state;
        const errorComponent = (
            error !== null ? 
                <ErrorAlert error={error} onDismiss={this.handleErrorDismiss}/>
            : null
        );
        return (
            <div className="login-wrapper">
              <div className="login-card">
                <div className="login-brand">
                  <img src={img}  height="80" alt=""/>
                  <h2 className="login-title">Customer Happiness</h2>
                </div>
                { errorComponent }
                <div className="w-form">
                  <LoginForm
                    isLoading={loading}
                    onSubmit={this.handleOnSubmit}
                  />
                </div>
              </div>
              <div className="login-media">
                <div className="login-media-overlay"></div>
              </div>
            </div>
        );
    }
}

