import { get, getIntervalsQuery } from './clipAPI';

const getInfo = async (token, type, option, group, filters) => {
    const { filter, interval } = filters;
    const intervalQuery = getIntervalsQuery(interval);
    let URL = `/kpi/${option}/${type}`;
    URL += `?${intervalQuery}`;
    URL += `&survey=5f10be84dfc8a23be8fca484`;
    if (filter !== "null") {
        URL += `&filter=${filter}`;
    }
    URL += `&group=${group}`;
    // console.log(URL);
    return await get(URL, token);
};

const getInfoKeyOptions = async (token, type, group, filters, optionsName,keys ) => {
    const { filter, interval } = filters;
    const intervalQuery = getIntervalsQuery(interval);
    let URL = `/kpi/trends/${type}`;
    URL += `?${intervalQuery}`;
    URL += `&survey=5f10be84dfc8a23be8fca484`;
    if (filter !== "null") {
        URL += `&filter=${filter}`;
    }
    URL += `&group=${group}`;
    URL += `&optionsName=${optionsName}`;
    URL += `&keys=${encodeURIComponent(JSON.stringify(keys))}`;
    // console.log(URL);
    return await get(URL, token);
};

export { getInfo, getInfoKeyOptions };
