import React, { Component } from 'react'

export default class FormInput extends Component {

    handleOnChange = ({ target }) => {
        const { handleChange } = this.props
        handleChange(target)
    }

    render() {
        const { errorMessage, className, handleChange, ...otherProps } = this.props
        const errorClass = (errorMessage !== undefined) && errorMessage !== '' ? 'is-invalid' : ''

        return (
            <div className={`form-group ${className}`}>
                <input
                    className={`form-control ${errorClass}`}
                    {...otherProps}
                    onChange={this.handleOnChange}
                     />
                <div className="invalid-feedback">{errorMessage}</div>
            </div>
        );
    }
}