import React from 'react'
const intl = new Intl.NumberFormat('es-MX')

export const breakdownOptions = [
  { label: "Área", value: "ticketArea"},
  { label: "Subárea", value: "ticketSubArea"},
  { label: "Canal", value: "ticketChannel"},
  // { label: "Categoría", value: "ticketCategory"},
  // { label: "Subcategoría", value: "ticketSubCategory"},
  // { label: "Email del Agente", value: "ticketAgentEmail"},
  // { label: "Nombre del Agente", value: "ticketAgentName"},
  { label: "Team Leader", value: "tl"},
]

export const metricOptions = [
  { label: "Tickets rebotados", value: "bounced"},
  { label: "Tickets internos", value: "internal"},
  { label: "Tickets mal asignados", value: "misallocated"},
]

export const timeOptions = [
  { label: "Semana", value: "weekly" },
  { label: "Mes", value: "monthly" },
  { label: "Día", value: "day" },
  // { label: "Hora", value: "hour" },
]

export const numberColumn = {
  type: 'number',
  flex: 1,
  headerAlign: 'right',
  align: 'right',
  valueFormatter: (row) => intl.format(row.value)
}

export const percentageColumn = {
  type: 'number',
  showHeader: false,
  flex: 0.6,
  headerAlign: 'right',
  align: 'right',
  valueFormatter: (row) => `${row.value}%`,
  renderHeader: () => <span>%</span>,
}

export const tableCols = [
  {
    headerName: 'ID',
    field: 'display',
    flex: 2,
    fixed: true
  },
  {
    headerName: 'Total',
    field: 'total',
    description: 'Número de tickets recibidos en el perido',
    ...numberColumn
  },
]