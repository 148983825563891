import React from 'react';
import { useLocation } from 'react-router-dom';
import Filters from './filters.jsx';
import DateFilter from '../../widgets/dateFilter/dateFilter.jsx';
import Segment from './segment';
const ROUTE_NAMES = {
  '/desempeno/indicadores': 'Desempeño de encuesta',
  '/desempeno/tendencias': 'Desempeño de encuesta',
  '/desempeno/auditoria': 'Auditoría',
  '/desempeno/tendencia-auditoria': 'Auditoría',
  '/kpis/general': 'Key Performance Indicators',
  '/kpis/detalle': 'Key Performance Indicators',
  '/analytics': 'Análisis de sentimiento',
  '/topics': 'Clasificación de comentarios',
  '/feed': 'Comentarios'
}

const NavOptions = () => {
  const location = useLocation()
  const title = ROUTE_NAMES[location.pathname] || 'Clip'

  return (
    <div className="flexRow navBarNavigation">
      <span className="navBarTitle">
        <strong style={{fontSize: "14px", textTransform: 'uppercase'}}>{title}</strong>
      </span>
      <Segment/>
      <div className="navBarDateContainer">
        <DateFilter />
      </div>
    </div>
  )
}

class Navbar extends React.Component {

    render() {
        const { title } = this.props;
        return(
            <div style={{ width: "100%"}} >
              <div className="navBar">
                <NavOptions title={title} />
                <Filters />
              </div>
            </div>
        );
    }
}

export default Navbar;
