import { BASE_URL } from './settings';
import moment from 'moment';

const EXPORT_TYPE = {
  RESPONSES: 'RESPONSES',
  BOUNCED: 'BOUNCED',
  INTERNAL: 'ITERNAL',
  MISALLOCATED: 'MISALLOCATED',
}

const exportPaths = {
  [EXPORT_TYPE.RESPONSES]: '/export?survey=5f10be84dfc8a23be8fca484',
  [EXPORT_TYPE.BOUNCED]: '/export-ix?type=bounced&survey=5f10be84dfc8a23be8fca484',
  [EXPORT_TYPE.INTERNAL]: '/export-ix?type=internal&survey=5f10be84dfc8a23be8fca484',
  [EXPORT_TYPE.MISALLOCATED]: '/export-ix?type=misallocated&survey=5f10be84dfc8a23be8fca484',
}

const exportFileNames = {
  [EXPORT_TYPE.RESPONSES]: 'Respuestas.csv',
  [EXPORT_TYPE.BOUNCED]: 'TicketsRechazados.csv',
  [EXPORT_TYPE.INTERNAL]: 'TicketsInternos.csv',
  [EXPORT_TYPE.MISALLOCATED]: 'TicketsMalAsignados.csv',
}

const getIntervalsObj = (interval) => {
    const current = moment().startOf('day');
    const start = interval.start
          .clone()
          .startOf('day');
    let endDate = interval.end
        .clone()
        .startOf('day');
    if (current.diff(endDate) === 0) {
        endDate = moment()
            .second(0)
            .millisecond(0)
            .subtract(1, 'minute');
    }
    else {
        endDate = interval.end.clone().endOf('day');
    }
    return {
        createdAt: {
            $gte: start,
            $lte: endDate
        }
    };
};

function genExport (token, start, end, filters, type = EXPORT_TYPE.RESPONSES) {
    let conditions = getIntervalsObj({start, end});
    if (Object.keys(filters).length > 0) {
        conditions = Object.assign(conditions, filters);
    }
    const path = exportPaths[type];
    let URL = `${BASE_URL}/response${path}&conditions=${JSON.stringify(conditions)}`;
    const options = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        }
    };
    return fetch(URL, options);
}

function downloadFileByName (token, fileName) {
    let URL = `${BASE_URL}/files/download?name=${fileName}`;
    const options = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'text/csv'
        }
    };
    return fetch(URL, options);
}

function saveFileAs (blob, suggestedName) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${suggestedName}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return;
}

export { genExport, downloadFileByName, saveFileAs, EXPORT_TYPE, exportFileNames }
