import { get, getIntervalsQuery } from './clipAPI';

const getInfo = async (option, token, filters) => {
    const { filter, interval } = filters;
    const intervalQuery = getIntervalsQuery(interval);
    let URL = `performance/${option}`;
    URL += `?${intervalQuery}`;
    URL += `&survey=5f10be84dfc8a23be8fca484`;
    if (filter !== "null") {
        URL += `&filter=${filter}`;
    }
    // console.log(URL);
    return await get(URL, token);
};

const getData = async (token, filters) => {
    return await getInfo('responsesReceived', token, filters);
};

const getFilters = async (token) => {
    return await get('filter', token);
};

const getSummary = async (token, filters) => {
    return await getInfo('summary', token, filters);
};

const getDistribution = async (option, token, filters, group) => {
    const { filter, interval } = filters;
    const intervalQuery = getIntervalsQuery(interval);
    let URL = `/performance/distribution/${option}`;
    URL += `?${intervalQuery}`;
    URL += `&survey=5f10be84dfc8a23be8fca484`;
    if (filter !== "null") {
        URL += `&filter=${filter}`;
    }
    URL += `&group=${group}`;
    return await get(URL, token);
};

const getTrends = async (type, token, filters, group) => {
    const { filter, interval } = filters;
    const intervalQuery = getIntervalsQuery(interval);
    let URL = `/performance/trends/${type}`;
    URL += `?${intervalQuery}`;
    URL += `&survey=5f10be84dfc8a23be8fca484`;
    if (filter !== "null") {
        URL += `&filter=${filter}`;
    }
    URL += `&group=${group}`;
    return await get(URL, token);
};

export { getData, getInfo, getFilters, getDistribution, getSummary, getTrends };
