import React from 'react';
import Card from '../../widgets/card.jsx';
import CardToolbar from '../../widgets/cardToolbar.jsx';
import { connect } from 'react-redux';
import { setHistoryGroup, requestHistory } from '../../reducers/kpis';
import { Legend, ComposedChart,
         CartesianGrid, XAxis, YAxis,
         Tooltip, Bar, Line, LabelList} from 'recharts';
import Loading from '../../widgets/loading';
import { getColorByKey } from '../../utils/colors';

const groupOptions = [
    {label: "Mensual", value: "monthly"},
    {label: "Semanal", value: "weekly"},
    {label: "Día", value: "day"},
];

const xTickFormatter = (tick, dataLen, group = "") => {
    if (dataLen > 5) {
        return '';
    }
    if (group === "weekly") {
        const values = tick.split(" ").slice(0, 2);
        return values.join(" ");
    }
    return tick;
};

class Chart extends React.Component {
    render() {
        const { data, kpiDisplay, group } = this.props;
        const bars = [];
        const chartData = data.map(it => {
            const { aditional, display, value } = it;
            let item = {
                display,
                [kpiDisplay]: value
            };
            aditional.forEach(a => {
                item[a.display] = a.value;
                if (bars.indexOf(a.display) === -1) {
                    bars.push(a.display);
                }
            });
            return item;
        });
        const len = chartData.length;
        return (
          <ComposedChart
            width={422}
            height={228}
            data={chartData}
          >
            <XAxis dataKey="display"
                   tickFormatter={(t) => xTickFormatter(t, len, group)}
            />
            <YAxis />
            <YAxis dataKey={kpiDisplay} orientation="right"/>
            <CartesianGrid strokeDasharray="3 3"/>
            <Tooltip
              offset={40}
              separator=": "
              formatter={(v, name, {dataKey}) => [ ['NPS', 'CES'].includes(dataKey) ? `${v}` : `${v}%`, `${dataKey}`]}
            />
            <Legend/>
            {bars.map((b, index) => {
                const color = getColorByKey(b);
                return (
                    <Bar
                      key={b}
                      stackId="a"
                      dataKey={b}
                      fill={color}
                      maxBarSize={48}
                    >
                    </Bar>
                );
            })}
            <Line
              dataKey={kpiDisplay}
              type="monotone"
              stroke="#070707"
            >
              <LabelList
                dataKey={kpiDisplay}
                position="top"
                offset={8}
                style={{ fontSize: '12px' }}
                formatter={v => (v !== 0 ? (['NPS', 'CES'].includes(kpiDisplay) ? `${v}` : `${v}%`) : '')}
              />
            </Line>
          </ComposedChart>
          );
    }
}

class History extends React.Component {

    componentDidMount = () => {
      this.loadChart();
    }

    componentDidUpdate = (prevProps) => {
        const { group: prevGroup } = prevProps;
        const { needUpdate, group } = this.props;
        if (needUpdate || prevGroup !== group) {
            this.loadChart();
        }
    }

    loadChart = () => {
        const { request, hasConstraint, segmentData, constraintSetted } = this.props;
        if (!segmentData.length > 0) return;
        if (hasConstraint && !constraintSetted) return;
        request();
    }

    handleGroupOnChange = ({label, value}) => {
        const { setHistoryGroup } = this.props;
        setHistoryGroup(value, label);
    }

    render() {
        const { display, fetching, type } = this.props;
        const view = fetching ? (
            <div style={{margin: "auto auto"}}>
              <Loading/>
            </div>
        ) : (<Chart {...this.props} kpiDisplay={type.toUpperCase()} />);
        const title = `Histórico ${type.toUpperCase()}`;
        return(
            <Card size="md">
              <CardToolbar
                onChange={this.handleGroupOnChange}
                title={title}
                options={groupOptions}
                display={display}
              />
              {view}
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {
      Kpis: {
        type,
        history: { groupDisplay, data, group, needUpdate, fetching }
      },
      Segments: { segments: { data: segmentData }, hasConstraint, constraintSetted }
    } = state;
    return {
        display: groupDisplay,
        data,
        fetching,
        needUpdate,
        group,
        type,
        hasConstraint,
        segmentData,
        constraintSetted,
    };
};

const mapDispatchToProps = dispatch => ({
    setHistoryGroup: (group, display) => dispatch(setHistoryGroup(group, display)),
    request: () => dispatch(requestHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
