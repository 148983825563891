import React from 'react';

import Loading from '../../widgets/loading';
import { connect } from 'react-redux';
import { requestSummary } from '../../reducers/performance';
import { toNumber } from '../../utils/formatter';
import Card from '../../widgets/card';

const styles = {
    container: {
        display: "flex",
        flexFlow: "row wrap"
    },
    itemWrapper: {
        marginBottom: "12px",
        width: "100%",
        height: "30px",
        justifyContent: "left",
        alignItems: "center",
        padding: "4px",
        display: "flex",
        position: "relative"
    },
    item: ({value}) => ({
        position: "absolute",
        width: `${value}%`,
        height: "30px",
        backgroundColor: "#D5EEFB",
        zIndex: "1",
        left: "0",
    }),
    itemTextWrapper: {
        position: "absolute",
        zIndex: "3",
        fontSize: "12px",
        width: "100%"
    },
    horizontalContainer: {
        display: "flex",
        flexFlow: "row wrap",
    },
    textEnd: {
        marginLeft: "auto",
        marginRight: "5px"
    },
};

const Progress = (props) => {
    let { item: { display, n, percentage } } = props;
    if (percentage > 100) {
        percentage = 100;
    }

    return(
        <div style={styles.itemWrapper}>
          <div style={styles.item({value: percentage})}>
          </div>
          <div style={styles.itemTextWrapper}>
            <div style={styles.horizontalContainer}>
              <div>
                {toNumber(n)} {display}
              </div>
              <div style={styles.textEnd}>
                {percentage}%
              </div>
            </div>
          </div>
        </div>
    );
};

class Summary extends React.Component {

    componentDidMount = () => {
      this.loadData(this.props);
    }

    componentDidUpdate = () => {
        const { data, fetching, needUpdate } = this.props;
        if ((needUpdate || data.length < 1) && !fetching) {
            this.loadData(this.props);
        }
    }

    loadData = (props) => {
        const { request, hasConstraint, segmentData, constraintSetted } = props;
        if (!segmentData.length > 0) return;
        if (hasConstraint && !constraintSetted) return;
        request();
    }

    render() {
        const { data, fetching } = this.props;
        const chart = (
            <div style={styles.container}>
              {data.map(item => (
                  <Progress
                    key={item.display}
                    item={item}
                  />
              ))}
            </div>
        );
        const view = fetching === true ? (<Loading/>) : chart;
        return(
            <div style={{marginLeft: "24px"}}>
              <Card size="sm">
                <div className="cardTitle">
                  Resumen de envíos
                </div>
                {view}
              </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
      Performance: { summary: { data, fetching, needUpdate } },
      Segments: { segments: { data: segmentData }, hasConstraint, constraintSetted }
    } = state;
    return {
        data,
        fetching,
        needUpdate,
        hasConstraint,
        segmentData,
        constraintSetted,
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestSummary())
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
