import React from 'react'
const intl = new Intl.NumberFormat('es-MX')

export const breakdownOptions = [
  { label: "Área", value: "ticketArea"},
  { label: "Subárea", value: "ticketSubArea"},
  { label: "Canal", value: "ticketChannel"},
  { label: "Categoría", value: "ticketCategory"},
  { label: "Subcategoría", value: "ticketSubCategory"},
  { label: "Email del Agente", value: "ticketAgentEmail"},
  { label: "Nombre del Agente", value: "ticketAgentName"},
  { label: "Team Leader", value: "tl"},
]

const numberColumn = {
  type: 'number',
  flex: 1,
  headerAlign: 'right',
  align: 'right',
  valueFormatter: (row) => intl.format(row.value)
}

const percentageColumn = {
  type: 'number',
  showHeader: false,
  flex: 0.6,
  headerAlign: 'right',
  align: 'right',
  valueFormatter: (row) => `${row.value}%`,
  renderHeader: () => <span>%</span>,
}

export const tableCols = [
  {
    headerName: 'ID',
    field: 'display',
    flex: 2
  },
  {
    headerName: 'Tickets',
    field: 'tickets',
    description: 'Número de tickets recibidos',
    ...numberColumn
  },
  {
    headerName: 'Internos',
    field: 'discardedByInternal',
    description:
      'Número de tickets que fueron descartados debido a que están asignados a correos con dominio @payclip.com, @clipdashboard.com y @eficasia.com, además de no pertenecer al canal Tasa Natural',
    ...numberColumn
  },
  {
    headerName: '% Internos',
    field: 'internalPercentage',
    ...percentageColumn
  },
  {
    headerName: 'Invitación activa',
    field: 'discardedByActive',
    description: 'Número de tickets descartados porque ya contaban con una invitación reciente, menor a 7 días.',
    ...numberColumn
  },
  {
    headerName: '% Invitación activa',
    field: 'activePercentage',
    ...percentageColumn
  },
  {
    headerName: 'Cuarentena',
    field: 'discardedByspread',
    description: 'Número de tickets a los que se descartó por tener una respuesta en los últimos 30 días.',
    ...numberColumn
  },
  {
    headerName: '% Cuarentena',
    field: 'spreadPercentage',
    ...percentageColumn
  },
  {
    headerName: 'Enviados',
    field: 'sent',
    description: 'Número de tickets que recibieron una invitación por email o link.',
    ...numberColumn
  },
  {
    headerName: '% Enviados',
    field: 'sentPercentage',
    ...percentageColumn
  },
  {
    headerName: 'Rebotados',
    field: 'bounceEmail',
    description: 'Número de tickets a los que se intentó enviar invitación por email pero fueron rechazados por el servidor de correos debido a que la cuenta no existe o tiene la bandeja llena.',
    ...numberColumn
  },
  {
    headerName: '% Rebotados',
    field: 'bounceEmailPercentage',
    ...percentageColumn
  },
  {
    headerName: 'Mal asignados',
    field: 'misallocated',
    description: 'Número de tickets que se asignaron a un área diferente a la que pertencia el agente.',
    ...numberColumn
  },
  {
    headerName: '% Mal asignados',
    field: 'misallocatedPercentage',
    ...percentageColumn
  },
]