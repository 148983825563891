import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { connect } from 'react-redux';

import { deleteSegment, segmentDeleted } from '../../reducers/segments';
import Loading from '../../widgets/loading';

const styles ={
    label: {
        fontSize: "12px",
        color: "#707070",
    },
    descriptionLabel: {
        fontSize: "14px",
        color: "#707070",
    },
};

const Label = ({title}) => {
    return (
        <div style={styles.descriptionLabel}>
          {title}
        </div>
    );
};

class DeleteSegmentsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }

    componentDidUpdate = (prevProps) => {
        const { hasSuccess: prevHasSuccess } = prevProps;
        const { hasSuccess, segmentDeleted } = this.props;
        if (hasSuccess && prevHasSuccess !== hasSuccess) {
            segmentDeleted();
            this.handleOnClose();
        }
    }

    handleOnChange = ({target}) => {
        this.setState({value: target.id});
    }

    handleOnSelectItem = (id) => () => {
        this.setState({value: id});
    }

    handleOnClose = () => {
        const { onCloseModal } = this.props;
        this.setState({value: null});
        onCloseModal();
    }

    handleOnDelete = () => {
        const { value } = this.state;
        if (value === null) return;
        const { deleteSegment } = this.props;
        deleteSegment(value);
    }

    render() {
        const { show, segments, loading } = this.props;
        const { value } = this.state;
        const title = segments.length === 0 ?
              "No cuentas con Segmentos Guardados." : "Selecciona un filtro a eliminar";
        const view = loading ? (
            <div className="marginAuto">
              <Loading/>
            </div>
        )
              :
              (<>
                 <button
                   type="button"
                   className="btn btn-error"
                   onClick={this.handleOnClose}>Cancelar</button>
                 <button
                   type="button"
                   onClick={this.handleOnDelete}
                   className="btn btn-primary">Eliminar</button>
               </>
              );
        return(
              <Modal
                show={show}
                onHide={this.handleOnClose}>
                <Modal.Header closeButton>
                  <Modal.Title>ELIMINAR SEGMENTO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Label title={title}/>
                  {
                      segments.map((it, index) => {
                          const style = index === 0 ? { marginTop: "8px" } : {};
                          const checked = it._id === value;
                          return (
                              <div className="form-check"
                                   onClick={this.handleOnSelectItem(it._id)}
                                   style={{...style,
                                          }}
                                   key={it.name}>
                                <input
                                  className="form-check-input"
                                  name="radioSegment"
                                  type="radio"
                                  id={it._id}
                                  checked={checked}
                                  onChange={this.handleOnChange}
                                />
                                <label
                                  style={styles.label}>
                                  {it.name}
                                </label>
                              </div>
                          );
                      })
                  }

                </Modal.Body>
                <Modal.Footer>
                  {view}
                </Modal.Footer>
              </Modal>
        );
    }
}

const mapStateToProps = state => {
    const { Segments: { segments: { privateSegments }}} = state;
    const { Segments: { deleteSegment: { loading, error, hasSuccess } } } = state;
    return {
        segments: privateSegments,
        loading,
        error,
        hasSuccess
    };
};

const mapDispatchToProps = dispatch => ({
    deleteSegment: (id) => dispatch(deleteSegment(id)),
    segmentDeleted: () => dispatch(segmentDeleted()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteSegmentsModal);
