import React, { Component } from 'react';

export default class LoadingButton extends Component {

    render() {
        const { title } = this.props;
        var { isLoading } = this.props;
        isLoading = isLoading || false;
        const view = isLoading ? <Loading/> : title;

        return (
            <button className="action-button w-button"
                    type="submit">
              {view}
            </button>
        );
    }

}

const Loading = () => (
    <div>
      <span
        className="spinner-grow spinner-grow-sm mr-2"
        role="status"
        aria-hidden="true">
      </span>
      <span className="sr-only">Cargando...</span>
      Cargando...
    </div>
);
