import { getSegments } from '../services/segments';
import { makeTypes, makeActions as mac } from '../utils/reducers';
import { genExport, downloadFileByName, saveFileAs, exportFileNames, EXPORT_TYPE } from '../services/files';
import { handleError } from './session';

const t = makeTypes('export');

//TYPES
const FETCH_FILTERS = t('fetchFilters', true);
const GEN_EXPORT = t('getExport', true);
const DOWNLOAD = t('download', true);
const SET_SEGMENT = t('setSegment', false);

//ACTIONS
export const setSegment = mac(SET_SEGMENT, 'query');

const getExportStart = mac(GEN_EXPORT.START);
const getExportSuccess = mac(GEN_EXPORT.SUCCESS, 'payload');
const getExportError = mac(GEN_EXPORT.ERROR, 'error');

const fetchStart = mac(FETCH_FILTERS.START);
const fetchSuccess = mac(FETCH_FILTERS.SUCCESS, 'payload');
const fetchError = mac(FETCH_FILTERS.ERROR, 'error');

const downloadStart = mac(DOWNLOAD.START);
const downloadSuccess = mac(DOWNLOAD.SUCCESS, 'payload');
const downloadError = mac(DOWNLOAD.ERROR, 'error');

//DATA
const initialState = {
    filters: {
        data: [],
        fetching: false,
        error: null,
    },
    segmentQuery: EXPORT_TYPE.RESPONSES,
    generating: false,
    downloadUrl: null,
    error: null,
    download: {
        error: null,
        loading: null,
    }
};

//REDUCER
export default function reducer(state = initialState, action) {
    switch(action.type) {
    case FETCH_FILTERS.START:
        return {
            ...state,
            filters: {
                ...state.filters,
                fetching: true,
            },
        };
    case FETCH_FILTERS.SUCCESS:
        return {
            ...state,
            filters: {
                ...state.filters,

                fetching: false,
                data: action.payload
            },
        };
    case FETCH_FILTERS.ERROR:
        return {
            ...state,
            filters: {
                ...state.filters,
                fetching: false,
                data: action.error
            },
        };
    case SET_SEGMENT:
        return {
            ...state,
            segmentQuery: action.query
        };
    case GEN_EXPORT.START:
        return {
            ...state,
            generating: true
        };
    case GEN_EXPORT.SUCCESS:
        return {
            ...state,
            generating: false,
            downloadUrl: action.payload,
            error: null,
        };
    case GEN_EXPORT.ERROR:
        return {
            ...state,
            generating: false,
            downloadUrl: null,
            error: action.error
        };
    case DOWNLOAD.START:
        return {
            ...state,
            download: {
                loading: true,
                error: null,
            }
        };
    case DOWNLOAD.SUCCESS:
        return {
            ...state,
            downloadUrl: null,
            download: {
                loading: false,
                error: null,
            }
        };
    case DOWNLOAD.ERROR:
        return {
            ...state,
            download: {
                loading: false,
                error: action.error,
            }
        };
    default:
        return state;
    }
}

//THUNKS

export const fetchFilters = (token) =>
    async (dispatch, getState) => {
        dispatch(fetchStart());
        try {
            const response = await getSegments(token);
            dispatch(fetchSuccess(response.data));
        }
        catch(error) {
            console.error(`[export] fetchFilters:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchError(error));
        }
    };

export const requestGenExport = (start, end, token, filters, exportType) =>
    async (dispatch, getState) => {
        dispatch(getExportStart());
        try {
            const response = await genExport(token, start, end, filters, exportType);
            const fileName = await response.text();
            setTimeout(() => {
                dispatch(getExportSuccess(fileName.replace(/"/g, '')));
            }, 16000);
        }
        catch(error) {
            console.error(`[export] requestGenExport:: ${error}`);
            dispatch(handleError(error));
            dispatch(getExportError(error));
        }
    };

export const requestDownloadFile = (url, token) =>
    async (dispatch, getState) => {
        dispatch(downloadStart());
        try {
            const { Export: { segmentQuery: exportType } } = getState();
            const fileName = exportFileNames[exportType] || 'Exportación.csv'
            const response = await downloadFileByName(token, url);
            const blob = await response.blob();
            saveFileAs(blob, fileName);
            dispatch(downloadSuccess());
        }
        catch(error) {
            console.error(`[export] requestDownloadFile:: ${error}`);
            dispatch(handleError(error));
            dispatch(downloadError(error));
        }
    };

