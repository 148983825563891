import React from 'react';
import { connect } from 'react-redux';
import Card from '../../widgets/card';
import { ReactComponent as Increase } from '../../images/increase.svg';
import { ReactComponent as Decrease } from '../../images/decrease.svg';
import { ReactComponent as Neutro } from '../../images/neutro.svg';
import { requestNSS } from '../../reducers/textAnalytics';
import Loading from '../../widgets/loading';
import { toPercent, toNumber } from '../../utils/formatter';

const styles = {
    value: {
        fontSize: "32px",
        fontWeight: "bold",
    },
    valueDescription: {
        fontSize: "12px",
        color: "#707070",
    },
    diff: (color) => ({
        color,
        marginLeft: "8px"
    }),
    diffDescription: {
        fontSize: "10px",
        color: "#707070",
        marginLeft: "6px",
    },
    indicatorValue: {
        marginRight: "4px",
        fontSize: "12px"
    },
    indicatorDescription: {
        fontSize: "12px"
    },
};

const Chart = (props) => {
    const { value, display, diff, flag, aditional = []} = props;
    const options = aditional.filter(it => {
        const { display: d } = it;
        return (d === "Positivo" || d === "Negativo");
    });

    let img = null;
    let color = "#333333";
    switch (flag) {
    case 'positive':
        img = <Increase/>;
        color = "#279B4B";
        break;
    case 'neutral':
        img = <Neutro/>;
        color = "#333333";
        break;
    case 'negative':
        img = <Decrease/>;
        color = "#E84147";
        break;
    default:
        img = null;
        color = "#333333";
    }
    return(
        <div className="flexRow flexSpaceAround">
          <div className="flexColumn">
            <span style={styles.value}>
              {value}
            </span>
            <span
              style={styles.valueDescription}
              className="marginAuto">
              {display}
            </span>
          </div>
          <div className="flexCenter">
            {img}
            <span style={styles.diff(color)}>{diff}</span>
            <span style={styles.diffDescription}>
              vs periodo anterior
            </span>
          </div>
          <div className="flexColumn flexCenter">
            {options.map(it => (
                <div
                  key={it.display}
                  className="flexRow">
                  <strong style={styles.indicatorValue}>
                    {toNumber(it.n)}
                  </strong>
                  <span style={styles.indicatorDescription}>
                    {it.display} ({toPercent(it.percentage, 2)})
                  </span>
                </div>
            ))}
          </div>
        </div>
    );
};
class Segment extends React.Component {

    loadData = () => {
        const { request, hasConstraint, segmentData, constraintSetted } = this.props;
        if (!segmentData.length > 0) return;
        if (hasConstraint && !constraintSetted) return;
        request();
    }

    componentDidMount = () => {
      this.loadData();
    }

    componentDidUpdate = () => {
        const { data, needUpdate } = this.props;
        if (needUpdate) {
            this.loadData();
        }
    }

    render() {
        const { data, fetching } = this.props;
        const chart = Object.keys(data).length > 0 ? <Chart {...data}/> : null;
        const view = fetching === true ? (<Loading/>) : chart;
        return(
            <Card size="smMd">
              <div className="cardTitle">
                NSS de Segmento
              </div>
              {view}
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {
      TextAnalytics: { nss: { data, fetching, needUpdate }},
      Segments: { segments: { data: segmentData }, hasConstraint, constraintSetted }
    } = state;

    return {
        data,
        fetching,
        needUpdate,
        hasConstraint,
        segmentData,
        constraintSetted,
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestNSS()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Segment);
