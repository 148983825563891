import React, { Component } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

const styles = {
    form: {
        width: "100vw",
        maxWidth: "400px",
        padding: "15px",
        margin: "auto"
    },

    passWrapper: {
        position: "relative",
        display: 'flex',
    },

    icon: {
        position: "absolute",
        top: "20%",
        right: "4%"
    }

};
export default class PasswordInput extends Component {

    state ={
        show: false
    }

    handleOnChange = ({ target }) => {
        const { handleChange } = this.props;
        handleChange(target);
    }

    handleOnClickEye = () => {
        const { show } = this.state;
        this.setState({show: !show});
    }

    render() {
        const { errorMessage, className, handleChange, ...otherProps } = this.props;
        const errorClass = (errorMessage !== undefined) && errorMessage !== '' ? 'is-invalid' : '';
        const { show } = this.state;
        const type = show ? "text" : "password";

        return (
            <div
              className={`form-group ${className}`}>
              <div style={styles.passWrapper}>
                <input
                  className={`form-control ${errorClass}`}
                  {...otherProps}
                  type={type}
                  onChange={this.handleOnChange}
                />
                <i
                  style={styles.icon}
                  onClick={this.handleOnClickEye}
                >{eye}</i>
              </div>
              <div className="invalid-feedback">{errorMessage}</div>
            </div>
        );
    }
}
