import React from 'react';

import './menu.css';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Performance } from '../images/performance.svg';
import { ReactComponent as Analytics } from '../images/analytics.svg';
// import { ReactComponent as Dashboard } from '../images/dashboard.svg';
// import { ReactComponent as Segments } from '../images/segments.svg';
import { ReactComponent as Kpis } from '../images/kpis.svg';
import { ReactComponent as Export } from '../images/export.svg';

export default class Menu extends React.Component {

    render() {
        return(
            <nav className="menu">
              <ul className="list">
                {/* <li> */}
                {/*   <NavLink to="/resumen" */}
                {/*            activeClassName="menuItemSelected"> */}
                {/*     <span className="item"> */}
                {/*       <Dashboard className="itemImage"/> */}
                {/*       <div className="itemText">Resumen</div> */}
                {/*     </span> */}
                {/*   </NavLink> */}
                {/* </li> */}
                <li>
                  <span className="item">
                    <Performance className="itemImage"/>
                    <div className="itemText">Desempeño</div>
                  </span>
                </li>
                <li>
                  <NavLink to="/desempeno/indicadores"
                           activeClassName="menuItemSelected">
                    <span className="item sub">
                      <div className="itemText">Indicadores</div>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/desempeno/tendencias"
                           activeClassName="menuItemSelected">
                    <span className="item sub">
                      <div className="itemText">Tendencia</div>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/desempeno/auditoria"
                           activeClassName="menuItemSelected">
                    <span className="item sub">
                      <div className="itemText">Auditoría</div>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/desempeno/tendencia-auditoria"
                           activeClassName="menuItemSelected">
                    <span className="item sub">
                      <div className="itemText">Tendencia de Auditoría</div>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <span className="item">
                      <Kpis className="itemImage"/>
                      <div className="itemText">KPI's</div>
                   </span>
                </li>
                <li>
                  <NavLink to="/kpis/general"
                           activeClassName="menuItemSelected">
                    <span className="item sub">
                      <div className="itemText">Métricas</div>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/kpis/detalle"
                           activeClassName="menuItemSelected">
                    <span className="item sub">
                      <div className="itemText">Detalle</div>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <span className="item">
                      <Analytics className="itemImage"/>
                      <div className="itemText">Text Analytics</div>
                   </span>
                </li>
                <li>
                  <NavLink to="/analytics" activeClassName="menuItemSelected">
                    <span className="item sub">
                      <div className="itemText">Sentimiento</div>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/topics" activeClassName="menuItemSelected">
                    <span className="item sub">
                      <div className="itemText">Temas</div>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/feed" activeClassName="menuItemSelected">
                    <span className="item sub">
                      <div className="itemText">Comentarios</div>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/exportar"
                           activeClassName="menuItemSelected">
                    <span className="item">
                      <Export className="itemImage"/>
                      <div className="itemText">Exportar</div>
                    </span>
                  </NavLink>
                </li>
                {/* <li> */}
                {/*   <NavLink to="/segments" */}
                {/*            activeClassName="menuItemSelected"> */}
                {/*     <span className="item"> */}
                {/*       <Segments className="itemImage"/> */}
                {/*       <div className="itemText">Mis Segmentos</div> */}
                {/*     </span> */}
                {/*   </NavLink> */}
                {/* </li> */}
              </ul>
            </nav>
        );
    }
}
