import React from 'react';

import firebase from '../firebase';
import Sidebar from '../components/sidebar';

import { Route } from 'react-router-dom';
import Navbar from '../widgets/navbar/navbar.jsx';
import TriggerFilterChange from '../widgets/TriggerFilterChange.jsx';
import Performance from './performance/performance';
import Export from './export/export.jsx';
import KPIS from './kpis/kpis';
import TextAnalytics from './textAnalytics/textAnalytics.jsx';
import Topics from './textAnalytics/topics';
import Feed from './textAnalytics/feed';
import { connect } from 'react-redux';
import { loadSession, setToken } from '../reducers/session';

const styles = {
    main: {
        flex: '1',
        overflowY: "scroll",
        backgroundColor: "#F4F7FD",
        padding: "24px 24px"
    },
    container: {
        display: "flex",
        height: "100vh"
    },
    content: {
        flexDirection: "column",
        display: 'flex',
        width: "100%",
        height: '100vh',
    }
};

class Main extends React.Component {

    state = {
        sessionObserver: null,
        timerId: null,
    }

    constructor(props) {
        super(props);
        const { loadSession } = props;
        loadSession();
    }

    componentDidUpdate = (prevProps) => {
        const { token: prevToken } = prevProps;
        const { token } = this.props;

        if (prevToken !== token && token === null) {
            console.log(token);
            const { history } = this.props;
            history.replace('/login');
        }
    }
    componentDidMount = () => {
        this.verifySession();
        this.startTimer();
    }

    componentWillUnmount = () =>  {
        const { sessionObserver, timerId } = this.state;
        if (typeof sessionObserver === 'function') {
            sessionObserver();
        }

        if (timerId !== null) {
            clearInterval(timerId);
        }

    }

    verifySession = () => {
        const { history, setToken } = this.props;
        const sessionObserver = firebase.isInitialized()
            .then( async val => {
                if (!val) {
                    history.replace('/login');
                }
            });
        this.setState({ sessionObserver });
    }

    onLogout = () => {
        const { history } = this.props;
        history.replace('/login');
    }

    startTimer = () => {
        const { setToken } = this.props;
        const timerId = setInterval(async () => {
            try {
                const token = await firebase.refreshToken();
                setToken(token);
            }
            catch(ex) {
                console.error(`[Main] startTimer:: ${ex}`);
            }
        }, 1000 * 60 * 3); //3 min
        this.setState({ timerId });
    }

    render() {
        const { token, user, location } = this.props;
        const includeNavbar = location.pathname !== '/exportar'
        const content = (
            <div style={styles.container}>
              <Sidebar
                username={user}
                onLogout={this.onLogout}/>
              <div style={styles.content}>
                {includeNavbar && <Navbar />}
                <div style={includeNavbar ? styles.main : {}}>
                  <TriggerFilterChange />
                  <Route path="/desempeno" component={Performance}/>
                  <Route path="/kpis" component={KPIS}/>
                  <Route path="/analytics" component={TextAnalytics}/>
                  <Route path="/topics" component={Topics}/>
                  <Route path="/feed" component={Feed}/>
                  <Route path="/exportar" component={Export}/>
                </div>
              </div>
            </div>
        );
        const view = token === null ? (<h1>Cargando..</h1>) : content;
        return(view);
    }
}

const stateToProps = state => {
    const { Session: { token, user }} = state;
    return {
        token,
        user
    };
};

const mapDispatchToProps = dispatch => ({
    loadSession: () => dispatch(loadSession()),
    setToken: (token) => dispatch(setToken(token)),
});

export default connect(stateToProps, mapDispatchToProps)(Main);
