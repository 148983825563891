import React from 'react';
import Card from '../../widgets/card.jsx';
import CardToolbar from '../../widgets/cardToolbar.jsx';
import { connect } from 'react-redux';
import { setAreaGroup, requestArea } from '../../reducers/kpis';
import { ReactComponent as Increase } from '../../images/increase.svg';
import { ReactComponent as Decrease } from '../../images/decrease.svg';
import { ReactComponent as Neutro } from '../../images/neutro.svg';
import Loading from '../../widgets/loading';

const groupOptions = [
    {label: "Área", value: "area"},
    {label: "SubÁrea", value: "subArea"},
    {label: "Canales", value: "channels"},
];

const styles = {
    display: {
        fontSize: "10px",
        color: "#707070",
        fontWeight: "bold"
    },
    value: {
        fontSize: "25px",
        color: "#303030"
    }
};

const Chart = (props) => {
    const { data } = props;
    return (
        <div className="flexRow flexSpaceAround" >
          {data.map(it => {
              const { flag } = it;
              let img = null;
              let color = "#333333";
              switch (flag) {
              case 'positive':
                  img = <Increase/>;
                  color = "#279B4B";
                  break;
              case 'neutral':
                  img = <Neutro/>;
                  color = "#333333";
                  break;
              case 'negative':
                  img = <Decrease/>;
                  color = "#E84147";
                  break;
              default:
                  img = null;
                  color = "#333333";
              }
              return (
                  <div
                    style={{
                        padding: "8px",
                        marginTop: "4px",
                        maringLeft: "4px",
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(96px, 96px))"
                    }}
                    key={it.display}
                    className="">
                    <div style={styles.display}>{it.display}</div>
                      <div style={styles.value}>{it.value}</div>
                    <div className="flexRow"
                         style={{alignItems: "center"}}>
                      {img}
                      <div className="ml-1" style={{fontSize: "12px", color: color}} >
                        {it.diff}
                      </div>
                    </div>
                  </div>
              );
          }
                   )}
        </div>
    );
};

class Area extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false
        };
    }

    componentDidMount = () => {
      const { data, needUpdate } = this.props
      if (needUpdate) {
        this.loadData();
      }
    }

    componentDidUpdate = (prevProps) => {
        const { group: prevGroup } = prevProps;
        const { needUpdate, group } = this.props;
        if (needUpdate || group !== prevGroup) {
            this.loadData();
        }
    }

    loadData = () => {
        const { request } = this.props;
        request();
    }

    handleGroupOnChange = ({label, value}) => {
        const { setGroup } = this.props;
        setGroup(value, label);
    }

    handleOnCheckboxChanged = (checked) => {
        this.setState({checked});
    }

    render() {
        const { display, type, fetching, data } = this.props;
        const { checked } = this.state;
        const chartData = data.filter((it) => {
            if (!checked) return true;
            return it.value !== "-";
        });
        const title = `Desglose ${type.toUpperCase()}`;
        const view = fetching ? (
            <div className="marginAuto"><Loading/></div>
        ) : (<Chart {...this.props} data={chartData}/>);
        const size = chartData.length > 8 ? "lgMd" : "xl";
        return(
            <Card size={size}>
              <CardToolbar
                options={groupOptions}
                display={display}
                onChange={this.handleGroupOnChange}
                title={title}
                checkboxTitle="Ocultar Sin Respuestas"
                onCheckboxChanged={this.handleOnCheckboxChanged}
              />
              {view}
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const { Kpis: { type,
                    area: { groupDisplay, group,
                            fetching,
                            needUpdate, data }}} = state;
    return {
        display: groupDisplay,
        data,
        needUpdate,
        group,
        fetching,
        type,
    };
};

const mapDispatchToProps = dispatch => ({
    setGroup: (group, display) => dispatch(setAreaGroup(group, display)),
    request: () => dispatch(requestArea()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Area);
