
export const isEmailValid = (email) => {
    if (email === undefined) {
        throw Error("El Correo Electrónico es un campo requerido");
    }
    else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
        throw Error("El Correo Electrónico tiene un formato invalido");
    }
    return email;
};


export const isPasswordValid = (password) => {
    if (password === undefined || password === '') {
        throw Error("La Contraseña es un campo requerido");
    }
    return password;
};

