import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { COLORS_CONTRAST as COLORS, getColorByKey } from '../utils/colors';
import { toNumber } from '../utils/formatter';

export default class MyPieChart extends React.Component {

    render() {
        const { total, display, data, useColorsByKey } = this.props;
        return(
            <div style={{
            }}>
              <PieChart
                width={210} height={210}>
                <Pie
                  data={data}
                  cx={100}
                  dataKey={"value"}
                  cy={100}
                  innerRadius={85}
                  outerRadius={100}>
                  {
                      data.map((entry, index) =>
                               <Cell
                                 key={`${index}`}
                                 fill={useColorsByKey ? getColorByKey(entry.name) : COLORS[index % COLORS.length]}/>
                              )

                  }
                </Pie>
                <text
                  style={{fontSize: "40px"}}
                  x={100}
                  y={100}
                  dx={8}
                  dy={8}
                  fill="#333"
                  textAnchor="middle">
                  {toNumber(total)}
                </text>
                <text
                  style={{fontSize: "12px"}}
                  x={100}
                  y={120}
                  dy={8}
                  dx={8}
                  fill="#333"
                  textAnchor="middle">
                  {display}
                </text>
              </PieChart>
            </div>
        );
    }
}
