import { BASE_URL } from './settings';
import axios from 'axios';
import { post, deleteRequest } from './clipAPI';


function getSegments (token) {
    const URL = `${BASE_URL}/segments`;
    const options = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        }
    };
    return axios(URL, options);
}

const create = async (token, name, query, isPublic) => {
    const url = `/segments`;
    const body = { name, query, public: isPublic };
    return await post(url, token, body);
};

const deleteSegment = async (token, id) => {
    const url = `/segments/${id}`;
    const body = {};
    return await deleteRequest(url, token, body);
};

export { getSegments, create, deleteSegment };
