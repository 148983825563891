import React, { Component } from 'react';

import FormInput from '../../widgets/FormInput';
import LoadingButton from '../../widgets/LoadingButton';
import { isEmailValid } from '../../utils/formValidation';
import { Link } from 'react-router-dom';

const validate = (email) => {
    const errors = {};

    try {
        isEmailValid(email);
    }
    catch(e) {
        errors.email = e.message;
    }
    return errors;
};

export default class ForgotPasswordForm extends Component {
    state = {
        errors: {}
    }

    handleChange = target => {
        this.setState({
            [target.name]: target.value
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        const { onSubmit } = this.props;
        const { email } = this.state;
        const result = validate(email);
        this.setState({errors: result});
        if (!Object.keys(result).length) {
            onSubmit(email);
        }
    }

    render() {
        const { isLoading } = this.props;
        return (
            <form
              onSubmit={this.handleSubmit}
              noValidate>
              <FormInput
                type="email"
                name="email"
                placeholder="Correo Electrónico"
                errorMessage={this.state.errors.email}
                handleChange={this.handleChange} />

                <div className="login-actions">
                    <Link to="login" className="action-link login">
                        {"<- "} Regresar
                    </Link>
                    <LoadingButton title="Recuperar Contraseña" isLoading={isLoading} />
                </div>
            </form>
        );
    }
}
