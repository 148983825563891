import React from 'react';
import Navbar from './navbar';
import LoadingAnimation from '../../animations/loading/LoadingAnimation';
import SuccessAnimation from '../../animations/success/SuccessAnimation';
import { connect } from 'react-redux';
import { requestDownloadFile } from '../../reducers/export';

const styles = {
    content: {
        flexDirection: "column",
        width: "100%"
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    cardCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
};

class Export extends React.Component {

    handleDownloadBtnOnClick = () => {
        const { token, download, downloadUrl} = this.props;
        download(downloadUrl, token);
    }

    render() {
        const { downloadUrl, generating } = this.props;
        console.log(downloadUrl, generating);

        return(
            <div style={styles.content}>
              <Navbar/>
              <div className="container mt-4"
                   style={styles.center}>
                <div className="card" style={styles.cardCenter}>
                  {(!generating && downloadUrl === null ) &&
                   <div className="mt-4 mb-4">
                     Selecciona un periodo
                   </div>
                  }
                  { generating &&
                    <div className="card-body text-center" >
                      <LoadingAnimation/>
                      <div className="mt-4">Generando Archivo</div>
                    </div >
                  }
                  { (!generating && downloadUrl) && (
                      <div className="card-body text-center" >
                        <SuccessAnimation/>
                        <div className="mt-4">¡Éxito!</div>
                        <div style={styles.centerContainer}>
                          <button
                            style={styles.downloadBtn}
                            className="btn btn-primary btn-block text-center mt-4"
                            onClick={this.handleDownloadBtnOnClick}
                          >
                            Descargar archivo
                          </button>
                        </div>
                      </div >
                  )}
                </div>
              </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { Export: { downloadUrl, generating }} = state;
    const { Session: { token }} = state;
    return {
        token,
        downloadUrl,
        generating
    };
};

const mapDispatchToProps = dispatch => ({
    download: (url, token) => dispatch(requestDownloadFile(url, token))
});
export default connect(mapStateToProps, mapDispatchToProps)(Export);
