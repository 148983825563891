import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { request, setSegment, setConstraint } from '../../reducers/segments';
import { filtersChanged } from '../../reducers/filters';
import DeleteSegmentsModal from './deleteSegmentsModal';

const styles = {
    groupStyles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    groupBadgeStyles: {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#707070',
        display: 'inline-block',
        fontSize: 10,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    }
};

const formatGroupLabel = data => (
    <div style={styles.groupStyles}>
      <span>{data.label}</span>
      <span style={styles.groupBadgeStyles}>{data.options.length}</span>
    </div>
);

class Segment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDeleteModal: false,
            constraintLoaded: false
        };
        this.loadData();
    }

    componentDidUpdate = () => {
        const { needUpdate, fetching, selectedValue, setConstraint, filters, constraint, filtersChanged } = this.props;
        const { constraintLoaded } = this.state
        // Actualiza los reportes si tiene restricción de segmento
        if (!fetching && constraint && filters.length > 0 && !constraintLoaded) {
          setConstraint(constraint)
          filtersChanged()
          this.setState({ constraintLoaded: true })
        }
        // Dispara los primeros reportes si NO tiene restricción de segmento
        if (!fetching && !constraint && filters.length > 0 && !needUpdate) {
          filtersChanged()
        }
        if (needUpdate) {
            this.loadData();
        }
    }

    loadData = () => {
        const { request } = this.props;
        request();
    }

    handleOnChange = (value) => {
        if (value !== null && value.value === "_configSegments") {
            this.setState({showDeleteModal: true});
            return;
        }
        const { setSegment } = this.props;
        setSegment(value);
    }

    handleOnCloseModal = () => {
        this.setState({showDeleteModal: false});
    }

    render() {
        const { publicSegments,
                selectedValue,
                privateSegments, hasConstraint, constraint } = this.props;
        const { showDeleteModal } = this.state;
        const groupedOptions = [
            {
                label: 'MIS SEGMENTOS',
                options: privateSegments
            },
            {
                label: 'CREADOS POR OTROS',
                options: publicSegments
            },
            {
                label: "Acciones",
                options: [{label: "Gestionar Segmentos", value: "_configSegments"}]
            }
        ];
        return(
            <>
              <div className="filters">
                {!hasConstraint ? (
                  <Select
                    className="filtersSelectSm"
                    placeholder="Segmento"
                    options={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                    onChange={this.handleOnChange}
                    value={selectedValue}
                    isClearable
                    isDisabled={hasConstraint}
                  />
                  ) : (
                    <span>{constraint.name}</span>
                  )
                }
              </div>
              <DeleteSegmentsModal
                show={showDeleteModal}
                onCloseModal={this.handleOnCloseModal}
              />
            </>
        );
    }
}

const mapStateToProps = state => {
    const { Filters: { filters: { data } },
            Segments: { selectedValue, hasConstraint, constraint,
                        segments: { publicSegments,
                                    privateSegments,
                                    fetching, needUpdate }}} = state;
    return {
        publicSegments,
        privateSegments,
        fetching,
        needUpdate,
        selectedValue,
        constraint,
        hasConstraint,
        filters: data
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(request()),
    filtersChanged: () => dispatch(filtersChanged()),
    setSegment: (value) => dispatch(setSegment(value)),
    setConstraint: (value) => dispatch(setConstraint(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Segment);

