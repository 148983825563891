import { get, getIntervalsQuery } from './clipAPI'

export const getAuditSummary = async ({
  token,
  interval,
  filter,
  extraFilter,
  groupBy,
}) => {
  const intervalQuery = getIntervalsQuery(interval)
  const fullFilter = Object.assign({}, filter, extraFilter)
  let URL = `/kpi/audit`
  URL += `?${intervalQuery}`
  URL += `&survey=5f10be84dfc8a23be8fca484`
  const hasFilter = Object.keys(fullFilter).length > 0
  if (hasFilter) {
    URL += `&filter=${encodeURIComponent(JSON.stringify(fullFilter))}`
  }
  if (groupBy) {
    URL += `&group=${groupBy}`
  }
  return await get(URL, token)
}

export const getAuditTrend = async ({
  token,
  interval,
  timeUnit,
  groupBy,
  countField,
  filter,
  extraFilter,
}) => {
  const intervalQuery = getIntervalsQuery(interval)
  const fullFilter = Object.assign({}, filter, extraFilter)
  let URL = `/kpi/audit/trends`
  URL += `?${intervalQuery}`
  URL += `&survey=5f10be84dfc8a23be8fca484`
  URL += `&optionsName=${timeUnit}`
  URL += `&type=${countField}`
  const hasFilter = Object.keys(fullFilter).length > 0
  if (hasFilter) {
    URL += `&filter=${encodeURIComponent(JSON.stringify(fullFilter))}`
  }
  if (groupBy) {
    URL += `&group=${groupBy}`
  }
  return await get(URL, token)
}