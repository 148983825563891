import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Navbar from '../../widgets/navbar/navbar.jsx'
import TopSegment from '../../components/top-segment'
import TopicScorecard from './topics-scorecard'
import TopicTrend from './topics-trend'
import { FILTERS_CHANGED } from '../../reducers/filters'
import { FETCH_TOPICS } from '../../reducers/textAnalytics'
import './topics.css'

const options = [
  { label: "Todos", value: "all"},
  { label: "Servicio", value: "Servicio"},
  { label: "Producto", value: "Producto"},
  { label: "Proceso", value: "Proceso"},
  { label: "Herramientas", value: "Herramientas"},
];

const Topics = () => {
  const dispatch = useDispatch()
  const [selectedTopic, setSelectedTopic] = useState('all')
  const needUpdate = useSelector(state => state.TextAnalytics.topics.needUpdate )

  function onTopicChange (t) {
    setSelectedTopic(t)
    dispatch({ type: FILTERS_CHANGED })
  }
  
  const dispatchLoad = useCallback(() => {
    dispatch({ type: FETCH_TOPICS.START })
  }, [dispatch])

  useEffect(() => {
    needUpdate && dispatchLoad()
  }, [needUpdate, dispatchLoad])

  return (
    <>
      <TopSegment
        title="Tema"
        options={options}
        value={selectedTopic}
        onChange={onTopicChange}
      />
      <div className="grid-container">
        <div className="topic-scorecard">
          <TopicScorecard topic={selectedTopic} />
        </div>
        <div className="topic-trend">
          <TopicTrend title="Tendencia por sentimiento" topic={selectedTopic} breakdown="COMENTARIO_comprehend.Sentiment" colorMode="KEYS" />
        </div>
        <div className="topic-topics">
          <TopicTrend
            title="Tendencia por temas positivos"
            topic={selectedTopic}
            breakdown="COMENTARIO_classify.Topic"
            colorMode="RANDOM"
            extraFilter={{ 'COMENTARIO_comprehend.Sentiment': 'POSITIVE' }}
          />
        </div>
        <div className="topic-drilldown">
        <TopicTrend
            title="Tendencia por temas negativos"
            topic={selectedTopic}
            breakdown="COMENTARIO_classify.Topic"
            colorMode="RANDOM"
            extraFilter={{ 'COMENTARIO_comprehend.Sentiment': 'NEGATIVE' }}
          />
        </div>
      </div>
    </>
  )
}

export default Topics;
