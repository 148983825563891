import React from 'react';
import Card from '../../widgets/card.jsx';
import CardToolbar from '../../widgets/cardToolbar.jsx';
import { connect } from 'react-redux';
import Loading from '../../widgets/loading';
import { setDistributionGroup, requestDistribution } from '../../reducers/textAnalytics';
import { BarChart, XAxis, YAxis, Tooltip,
         CartesianGrid,
         Bar, Legend, LabelList } from 'recharts';
import { getColorByKey } from '../../utils/colors';
import { toPercent } from '../../utils/formatter';

const groupOptions = [
    {label: "Área", value: "area"},
    {label: "Subárea", value: "subArea"},
];

class Chart extends React.Component {
    render() {
        const { data } = this.props;
        let bars = [];
        const chartData = data.map(it => {
            const { aditional, display } = it;
            let item = {
                display,
            };
            aditional.forEach(a => {
                const display = a.display;
                if (display !== "WASD") {
                    item[display] = a.percentage;
                    if (bars.indexOf(display) === -1) {
                        bars.push(display);
                    }
                }
            });
            return item;
        });
        bars = bars.reverse();
        return(
            <BarChart width={422}
                       height={228}
                       data={chartData}>
              <XAxis dataKey="display"
                     tickFormatter={(t) => ''}
              />
              <YAxis/>
              <CartesianGrid strokeDasharray="3 3"/>
              <Tooltip
                offset={40}
                separator=": "
                formatter={(v, name) => [`${toPercent(v, 2)} (${name})`]}
              />
              <Legend />
              {bars.map((b, index) => {
                  return (
                      <Bar
                        key={b}
                        stackId="a"
                        dataKey={b}
                        fill={getColorByKey(b)}
                        maxBarSize={48}
                        >
                        <LabelList
                          style={{ fontSize: '10px' }}
                          dataKey={b}
                          formatter={v => `${toPercent(v, 2)}`}
                          position="insideTop"/>
                      </Bar>
                  );
              })}
            </BarChart>);
    }
}
class Distribution extends React.Component {

    componentDidMount = () => {
      this.loadData();
    }

    componentDidUpdate = () => {
        const { needUpdate } = this.props;
        if (needUpdate) {
            this.loadData();
        }
    }

    loadData = () => {
        const { request, hasConstraint, segmentData, constraintSetted } = this.props;
        if (!segmentData.length > 0) return;
        if (hasConstraint && !constraintSetted) return;
        request();
    }

    handleGroupOnChange = (option) =>  {
        const { setGroup } = this.props;
        const { label, value } = option;
        setGroup(value, label);
    }

    render() {
        const { groupDisplay, fetching, data} = this.props;
        const chart = Object.keys(data).length > 0 ? <Chart data={data}/> : null;
        const view = fetching === true ? (<Loading/>) : chart;
        return(
              <Card size="md">
                <CardToolbar
                  title="Distribución de sentimiento"
                  display={groupDisplay}
                  options={groupOptions}
                  onChange={this.handleGroupOnChange}
                />
                {view}
              </Card>
        );
    }
}

const mapStateToProps = state => {
    const {
      TextAnalytics: { distribution: { groupDisplay, needUpdate, fetching, data }},
      Segments: { segments: { data: segmentData }, hasConstraint, constraintSetted }
    } = state;
    return {
        groupDisplay,
        data,
        fetching,
        needUpdate,
        hasConstraint,
        segmentData,
        constraintSetted,
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestDistribution()),
    setGroup: (group, display) => dispatch(setDistributionGroup(group, display)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Distribution);
