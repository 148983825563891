import React from 'react'

const TopSegment = ({ title = 'Tema', value, onChange, options, styles = undefined }) => {
  const selectedLabel = options.find(o => o.value === value)?.label

  return (
    <div className="flexColumn" style={styles}>
      <div className="navbar-collapse"
        style={{
          marginBottom: "20px",
          color: "#0288D1",
          marginLeft: "8px",
          display: 'flex',
          flexDirection: 'row'
        }}>
        <div
          style={{ color: '#333333', marginRight: '0.5em' }}>
          { title }:
        </div>
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <span
              role="button"
              className="nav-link dropdown-toggle centered"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ padding: "0px" }}
              id="invitationSent"
              data-toggle="dropdown">
              {selectedLabel || 'Seleccionar'}
            </span>
            <div
              className="dropdown-menu"
              aria-labelledby="invitationSent">
              {options.map(option => (
                <span
                  key={option.value}
                  onClick={() => onChange(option.value)}
                  className="dropdown-item"
                  style={{ cursor: 'pointer' }}>
                  {option.label}
                </span>
              ))}
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TopSegment