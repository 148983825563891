import React from 'react';
// import Agents from './agents';
// import Reason from './reason';
import { connect } from 'react-redux';
import Card from '../../widgets/card.jsx';
import CardToolbar from '../../widgets/cardToolbar.jsx';
import Table from './detail/table.jsx';
import TrendsChart from './detail/trendsChart.jsx';
import { setTrendsGroup } from '../../reducers/kpis';

const groupOptions = [
    {label: "Mensual", value: "monthly"},
    {label: "Semanal", value: "weekly"},
    {label: "Día", value: "day"},
];


class Detail extends React.Component {

    handleGroupOnChange = (option) => {
        const { setGroup } = this.props;
        setGroup(option.value, option.label);
    }

    render() {
        const { display } = this.props;
        return (
            <Card size="xxl">
              <CardToolbar
                title=""
                display={display}
                options={groupOptions}
                onChange={this.handleGroupOnChange}
              />
              <div className="flexRow">
                <Table/>
                <TrendsChart/>
              </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const { Kpis: { trends: { group, groupDisplay }}} = state;
    return {
        group,
        display: groupDisplay
    };
};

const mapDispatchToProps = dispatch => ({
    setGroup: (group, display) => dispatch(setTrendsGroup(group, display)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
