import { BASE_URL } from './settings';
import axios from 'axios';
import moment from 'moment';

const get = async (url, token) => {
    const URL = `${BASE_URL}/${url}`;
    const options = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        }
    };
    return await axios(URL, options);
};

const post = async (url, token, body) => {
    const URL = `${BASE_URL}/${url}`;
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json'
    };
    return await axios({
        method: 'post',
        url: URL,
        data: body,
        headers
    });
};

const deleteRequest = async (url, token, body = {}) => {
    const URL = `${BASE_URL}/${url}`;
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json'
    };
    return await axios({
        method: 'delete',
        url: URL,
        data: body,
        headers
    });
};

const getIntervalsQuery = (interval) => {
    const current = moment().startOf('day');
    const start = interval.start
          .clone()
          .startOf('day')
          .format('x');
    let endDate = interval.end
        .clone()
        .startOf('day');
    if (current.diff(endDate) === 0) {
        endDate = moment()
            .second(0)
            .millisecond(0)
            .subtract(1, 'minute');
    }
    else {
        endDate = interval.end.clone().endOf('day');
    }
    const end = endDate.format('x');
    return `interval={"start": ${start}, "end": ${end}}`;
};

export { get, getIntervalsQuery, post, deleteRequest };
