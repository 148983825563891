import React from 'react';
import { connect } from 'react-redux';
import { setBreakdown } from '../../../reducers/kpis';

const options = [
    { label: "Área", value: "ticketArea"},
    { label: "Subárea", value: "ticketSubArea"},
    { label: "Canal", value: "ticketChannel"},
    { label: "Categoría", value: "ticketCategory"},
    { label: "Subcategoría", value: "ticketSubCategory"},
    { label: "Nombre del Agente", value: "ticketAgentName"},
];

class Breakdown extends React.Component {

    handleTypeOptionChanged = ({label, value}) => () => {
        const { setType } = this.props;
        setType(value, label);
    }

    render() {
        const { display } = this.props;
        return(
            <div style={{marginLeft: "128px"}} className="flexRow">
              <div
                style={{ color: '#333333', marginRight: '0.5em' }}>
                Desglose:
              </div>
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <span
                    role="button"
                    className="nav-link dropdown-toggle centered"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{padding: "0px"}}
                    id="invitationSent"
                    data-toggle="dropdown">
                    {display}
                  </span>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="invitationSent">
                    {options.map(option => (
                        <span
                          key={option.value}
                          onClick={this.handleTypeOptionChanged(option)}
                          className="dropdown-item"
                          style={{ cursor: 'pointer' }}>
                          {option.label}
                        </span>
                    ))}
                  </div>
                </li>
              </ul>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { Kpis: { breakdownDisplay } } = state;
    return {
        display: breakdownDisplay
    };
};

const mapDispatchToProps = dispatch => ({
    setType: (breakdown, display) => dispatch(setBreakdown(breakdown, display)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Breakdown);
