import React, { Component } from 'react';

import PasswordInput from '../../widgets/PasswordInput';
import { isPasswordValid } from '../../utils/formValidation';
import LoadingButton from '../../widgets/LoadingButton';

const styles = {
    form: {
        width: "100vw",
        maxWidth: "400px",
        padding: "15px",
        margin: "auto"
    },
};

const validate = (password) => {
    const errors = {};

    try {
        isPasswordValid(password);
    }
    catch(e) {
        errors.password = e.message;
    }
    return errors;
};

export default class RecoverPasswordForm extends Component {
    state = {
        errors: {}
    }

    handleChange = target => {
        this.setState({
            [target.name]: target.value
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        const { onSubmit } = this.props;
        const { password } = this.state;
        const result = validate(password);
        this.setState({errors: result});
        if (!Object.keys(result).length) {
            onSubmit(password);
        }
    }

    render() {
        const { isLoading } = this.props;
        return (
            <form
                onSubmit={this.handleSubmit}
                style={styles.form}
                className="text-start"
                noValidate>
              <PasswordInput
                name="password"
                placeholder="Contraseña"
                errorMessage={this.state.errors.password}
                handleChange={this.handleChange} />
                <LoadingButton
                    title="Guarnar Nueva Contraseña"
                    isLoading={isLoading}
                />
            </form>
        );
    }
}
