import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAuditSummary } from '../../../services/audit'
import { useQueryConfig } from '../../../hooks/use-query-config'
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid'
import TopSegment from '../../../components/top-segment'
import { FETCH_TOPICS } from '../../../reducers/textAnalytics'
import { FILTERS_CHANGED } from '../../../reducers/filters'
import { breakdownOptions, tableCols, demoData } from './breakdown-table.config'

export const Audit = () => {
  const dispatch = useDispatch()
  const [breakdownField, setBreakdownField] = useState(breakdownOptions[0].value)
  const { token, filter, interval, needUpdate } = useQueryConfig()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getAuditSummary({ token, interval, filter, groupBy: breakdownField })
      setData(response.data)
    } catch (err) {
      setData([])
    } finally {
      dispatch({ type: FETCH_TOPICS.START })
      setLoading(false)
    }
  }, [token, interval, filter, breakdownField, dispatch])

  useEffect(() => {
    needUpdate && loadData()
  }, [needUpdate, loadData])

  function onFieldChange (field) {
    setBreakdownField(field)
    dispatch({ type: FILTERS_CHANGED })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TopSegment
        title="Ver por"
        options={breakdownOptions}
        value={breakdownField}
        onChange={onFieldChange}
      />
      <DataGrid
        loading={loading}
        getRowId={r => r.display}
        rows={data}
        columns={tableCols}
        hideFooter
        style={{ fontSize: '13px', backgroundColor: '#FFFFFF' }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: GridToolbar
        }}
        componentsProps={{
          toolbar: {
            csvOptions: { fileName: 'ResumenTickets' },
            printOptions: { disableToolbarButton: true },
          }
        }}
      />
    </div>
  )
}

export default Audit;