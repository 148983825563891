import React, { useCallback, useEffect, useState } from 'react'
import CardToolbar from '../../widgets/cardToolbar.jsx'
import { useSelector } from 'react-redux'
import Loading from '../../widgets/loading'
import { getTopicTrend } from '../../services/textAnalytics-topic'
import { ComposedChart, Line, XAxis, YAxis,
         CartesianGrid,
         Tooltip, Bar, LabelList, Legend, ResponsiveContainer } from 'recharts'
import { getColorByKey, COLORS_CONTRAST } from '../../utils/colors'
import { toPercent } from '../../utils/formatter'

const groupOptions = [
    {label: "Semanal", value: "weekly"},
    {label: "Mensual", value: "monthly"},
    {label: "Día", value: "day"},
];

const xTickFormatter = (tick, dataLen, group = "") => {
    if (dataLen > 5) {
        return '';
    }
    if (group === "weekly") {
        const values = tick.split(" ").slice(0, 2);
        return values.join(" ");
    }
    return tick;
};

class Chart extends React.Component {
  render() {
    const { data, group, colorMode } = this.props;
    let bars = [];
    const chartData = data.map(it => {
      const { aditional, display, total } = it;
      let item = {
        display,
        n: total
      };
      aditional.forEach(a => {
        const display = a.display;
        if (display !== "WASD") {
          item[display] = a.percentage;
          if (bars.indexOf(display) === -1) {
            bars.push(display);
          }
        }
      });
      return item;
    });
    bars = bars.reverse();
    const len = chartData.length;
    const showLabels = len < 8;
    return (
      <ResponsiveContainer
        width="99%"
        height={228}
      >
        <ComposedChart
          margin={{ left: -20, right: 8 }}
          data={chartData}>
          <XAxis
            padding={{ left: 16, right: 16 }}
            dataKey="display"
            tickFormatter={(t) => xTickFormatter(t, len, group)}
          />
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <YAxis hide={true} domain={[0, 100]} />
          <YAxis yAxisId='line' />
          <Tooltip
            offset={40}
            separator=": "
            formatter={(v, name, { dataKey }) => {
              return [['n'].includes(dataKey) ? `${v}` : `${toPercent(v, 2)}`, ['n'].includes(dataKey) ? `Comentarios` : `${dataKey}`]
            }}
          />
          <Legend />
          {bars.map((b, index) => {
            return (
              <Bar
                key={b}
                stackId="a"
                isAnimationActive={false}
                dataKey={b}
                fill={colorMode === 'KEYS' ? getColorByKey(b) : COLORS_CONTRAST[index] }
                maxBarSize={48}>
                { showLabels && (
                  <LabelList
                    style={{ fontSize: '10px' }}
                    dataKey={b}
                    formatter={v => v < 10 ? '' : `${toPercent(v, 2)}`}
                    position="insideTop" />
                )}
              </Bar>
            );
          })}
          <Line
            yAxisId='line'
            type='monotone'
            dataKey='n'
            stroke='#070707' />

        </ComposedChart>
      </ResponsiveContainer>
    )
  }
}

const TopicTrend = ({ title, topic, breakdown, colorMode, extraFilter = {} }) => {
  const [isMounting, setIsMounting] = useState(true)
  const [loading, setLoading] = useState(true)
  const [groupDisplay, setGroupDisplay] = useState('Semanal')
  const [group, setGroup] = useState('weekly')
  const [data, setData] = useState()
  const token = useSelector(state => state.Session.token)
  const filter = useSelector(state => state.Filters.filter)
  const interval = useSelector(state => state.Filters.filters.interval)
  const needUpdate = useSelector(state => state.TextAnalytics.topics.needUpdate )

  const loadData = useCallback(async () => {
    setLoading(true)
    let extras = extraFilter
    if (topic && topic !== 'all') {
      extras['COMENTARIO_classify.Topic'] = topic
    }
    const response = await getTopicTrend(token, interval, group, filter, extras, breakdown)
    setData(response.data)
    if (isMounting) setIsMounting(false)
    setLoading(false)
    return () => null
  }, [token, interval, group, filter, topic, breakdown, extraFilter])

  function onChangeGroup (o) {
    setGroup(o.value)
    setGroupDisplay(o.label)
  }

  useEffect(() => {
    needUpdate && loadData()
  }, [needUpdate, loadData])

  useEffect(() => {
    if (!isMounting) {
      loadData()
    }
  }, [group])

  return (
    <div className="topic-card">
      <CardToolbar
        title={title}
        display={groupDisplay}
        options={groupOptions}
        onChange={onChangeGroup}
      />
      {loading && <Loading />}
      {!loading && <Chart data={data.chart} group={group} colorMode={colorMode} />}
    </div>
  )
}

export default TopicTrend
