import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Navbar from '../../widgets/navbar/navbar.jsx'
import TopSegment from '../../components/top-segment'
import TopicFeed from './topics-feed'
import { FILTERS_CHANGED } from '../../reducers/filters'
import { FETCH_TOPICS } from '../../reducers/textAnalytics'
import './topics.css'

const options = [
  { label: "Todos", value: "all"},
  { label: "Servicio", value: "Servicio"},
  { label: "Producto", value: "Producto"},
  { label: "Proceso", value: "Proceso"},
  { label: "Herramientas", value: "Herramientas"},
];

const Feed = () => {
  const dispatch = useDispatch()
  const [selectedTopic, setSelectedTopic] = useState('all')
  const needUpdate = useSelector(state => state.TextAnalytics.topics.needUpdate )

  function onTopicChange (t) {
    setSelectedTopic(t)
    dispatch({ type: FILTERS_CHANGED })
  }
  
  const dispatchLoad = useCallback(() => {
    dispatch({ type: FETCH_TOPICS.START })
  }, [dispatch])

  useEffect(() => {
    needUpdate && dispatchLoad()
  }, [needUpdate, dispatchLoad])

  return (
    <>
      <TopSegment
          title="Tema"
          options={options}
          value={selectedTopic}
          onChange={onTopicChange}
        />
        <div className="grid-container-feed">
          <div className="topic-feed">
            <TopicFeed topic={selectedTopic} />
          </div>
        </div>
    </>
  )
}

export default Feed;
