import React from 'react';

class Dropdown extends React.Component {

    handleOptionOnClick = (option) => () => {
        const { onChange } = this.props;
        if (typeof onChange !==  'function') return;
        onChange(option);
    }

    render() {
        const { display, options } = this.props;
        return(
            <div className="cardTitleDropDown flexAlignRight">
              <div className="navbar-collapse">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <span
                      role="button"
                      className="nav-link dropdown-toggle centered"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{padding: "0px"}}
                      id="invitationSent"
                      data-toggle="dropdown">
                      <div className="flexAlignRight">
                        {display}
                      </div>
                    </span>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="invitationSent">
                      {options.map(option => (
                          <span
                            key={option.label}
                            className="dropdown-item"
                            onClick={this.handleOptionOnClick(option)}>
                            {option.label}
                          </span>
                      ))}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
        );
    }
}

class CardToolbar extends React.Component {

    handleOnChange = (option) => {
        const { onChange } = this.props;
        if (typeof onChange !==  'function') return;
        onChange(option);
    }

    handleOnCheckboxChanged = ({target: { checked }}) => {
        const { onCheckboxChanged } = this.props;
        if (typeof onCheckboxChanged !== 'function' ) return;
        onCheckboxChanged(checked);
    }

    render() {
        const { title, display, options,
              checkboxTitle } = this.props;
        const showOptions =  (options !== undefined && options !== null && options.length > 0);
        return(
            <div className="flexRow">
              {title &&
               <div className="cardTitle">
                 {title}
               </div>
              }
              <div className="flexRow flexAlignRight">
                { checkboxTitle &&
                  <label
                    style={{
                        fontSize: "10px",
                        fontWeight: "normal",
                        marginRight: "32px",
                    }}>
                    <input
                      style={{ marginRight: "8px", verticalAlign: "text-top"}}
                      type="checkbox"
                      onChange={this.handleOnCheckboxChanged}
                    />
                    {checkboxTitle}
                  </label>
                }
                {showOptions &&
                 <Dropdown
                   display={display}
                   options={options}
                   onChange={this.handleOnChange}
                 />
                }
              </div>
            </div>
        );
    }
}

export default CardToolbar;
