import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import './DatesPicker.css';
export default class DatesPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: props.start || moment().startOf("isoWeek"),
            endDate: props.end || moment(),
            focusedInput: null,
        };
    }

    handleChange = ({ startDate, endDate}) => {
        this.setState({
            startDate,
            endDate
        });
        const { onDatesChange } = this.props;
        if (typeof onDatesChange === 'function') {
            onDatesChange(startDate, endDate);
        }
    }

    handleOnFocusChange = focusedInput =>  {
        this.setState({ focusedInput });
    }

    handleIsDayBlocked = (day) => {
        const current = moment().startOf('day');
        const diff = current.diff(day.startOf('day'));
        if (parseInt(diff) >= 0) {
            return false;
        }
        return true;
    }

    render() {
        const { startDate, endDate, focusedInput } = this.state;
        return (
            <DateRangePicker
            startDateId="startDateId"
            endDateId="endDateId"
            startDate={startDate}
            endDate={endDate}
            firstDayOfWeek={1}
            onDatesChange={this.handleChange}
            displayFormat="DD/MM/YYYY"
            focusedInput={focusedInput}
            onFocusChange={this.handleOnFocusChange}
            isDayBlocked={this.handleIsDayBlocked}
              minimumNights={0}
            isOutsideRange={() => ( false )}
            initialVisibleMonth={null}/>
        );
    }
}
