import React from 'react';
import moment from 'moment';
import DatesPicker from '../DatesPicker/DatesPicker';
import Select from 'react-select';
import { connect } from 'react-redux';
import { setFilterInterval } from '../../reducers/filters';

const options = [
    {label: "Personalizado", value: "0"},
    {label: "Hoy", value: "1"},
    {label: "Ayer", value: "2"},
    {label: "Esta Semana", value: "3"},
    {label: "La semana Pasada", value: "4"},
    {label: "Este mes", value: "5"},
    {label: "El mes pasado", value: "6"},
    {label: "Este año", value: "7"},
    {label: "El año pasado", value: "8"},
];

/**
 * @props {Function} onChange
 */
class DateFilter extends React.Component {

    postDates = (startDate, endDate, defaultValue) => {
        const { setInterval } = this.props;
        setInterval(startDate, endDate, defaultValue);
    }

    getStartDateAndEndDateOf = (rangeType, dayOffset) => {
        const startDate = dayOffset.clone().startOf(rangeType);
        const endDate = dayOffset.clone().endOf(rangeType);
        return [
            startDate,
            endDate
        ];
    }

    handleDatesChange = (startDate, endDate) => {
        const { defaultValue } = this.props;
        this.setState({
            startDate,
            endDate
        });
        if (startDate !== null && endDate !== null) {
            this.postDates(startDate, endDate, defaultValue);
        }
    }

    handleSelectOnChange = (target) => {
        var showDatePicker = false;
        var startDate = undefined;
        var endDate = undefined;

        switch (target.value) {
        case "0":
            showDatePicker = true;
            startDate = moment().startOf("isoWeek");
            endDate = moment();
            break;
        case "1": // TODAY
            [startDate, endDate] = this.getStartDateAndEndDateOf('day', moment());
            break;
        case "2": // Yesterday
            [startDate, endDate] = this.getStartDateAndEndDateOf('day', moment().subtract(1, 'day'));
            break;
        case "3": //'This week'
            [startDate, endDate ] = this.getStartDateAndEndDateOf('isoWeek', moment());
            endDate = moment();
            break;
        case "4": //Last Week
            const lastWeek = moment().subtract(1, 'week');
            [startDate, endDate ] = this.getStartDateAndEndDateOf('isoWeek', lastWeek);
            break;
        case "5": //Current Month
            [startDate, endDate ] = this.getStartDateAndEndDateOf('month', moment());
            break;
        case "6": //Last Month
            const lastMonth = moment().subtract(1, 'month');
            [startDate, endDate ] = this.getStartDateAndEndDateOf('month', lastMonth);
            break;
        case "7": //Current Year
            [startDate, endDate ] = this.getStartDateAndEndDateOf('year', moment());
            break;
        case "8": //Last Year
            const lastYear = moment().subtract(1, 'year');
            [startDate, endDate ] = this.getStartDateAndEndDateOf('year', lastYear);
            break;
        default:
            break;
        }
        this.setState({
            showDatePicker,
            startDate,
            endDate,
            defaultValue: target,
        });
        this.postDates(startDate, endDate, target);
    }


    render() {
        let showDatePicker = true;
        const { interval } = this.props;
        let { start, end, defaultValue = options[0] } = interval;
        if (defaultValue === null) {
            defaultValue = options[0];
        }
        if (defaultValue.value !== "0") {
            showDatePicker = false;
        }
        return(
            <>
              <Select
                style={{ container: () => ({ marginBottom: 0 }) }}
                options={options}
                className="filtersSelectSm"
                defaultValue={defaultValue}
                onChange={this.handleSelectOnChange}
              />
              { showDatePicker &&
                <DatesPicker
                  start={start}
                  end={end}
                  onDatesChange={this.handleDatesChange}/>
              }
            </>
        );
    }
}

const mapStateToProps = state => {
    const { Filters: { filters: { interval } } } = state;
    return {
        interval,
    };
};

const mapDispatchToProps = dispatch => ({
    setInterval: (start, end, dv) => dispatch(setFilterInterval(start, end, dv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
