import React from 'react';
import Card from '../../widgets/card.jsx';
import CardToolbar from '../../widgets/cardToolbar.jsx';
import { connect } from 'react-redux';
import Loading from '../../widgets/loading';
import { setHistoricalGroup, requestHistorical } from '../../reducers/textAnalytics';
import { ComposedChart, Line, XAxis, YAxis,
         CartesianGrid,
         Tooltip, Bar, LabelList, Legend } from 'recharts';
import { getColorByKey } from '../../utils/colors';
import { toPercent } from '../../utils/formatter';

const groupOptions = [
    {label: "Semanal", value: "weekly"},
    {label: "Mensual", value: "monthly"},
    {label: "Día", value: "day"},
];

const xTickFormatter = (tick, dataLen, group = "") => {
    if (dataLen > 5) {
        return '';
    }
    if (group === "weekly") {
        const values = tick.split(" ").slice(0, 2);
        return values.join(" ");
    }
    return tick;
};

class Chart extends React.Component {
    render() {
        const { data, group } = this.props;
        let bars = [];
        const chartData = data.map(it => {
            const { aditional, display, value} = it;
            let item = {
                display,
                NSS: value
            };
            aditional.forEach(a => {
                const display = a.display;
                if (display !== "WASD") {
                    item[display] = a.percentage;
                    if (bars.indexOf(display) === -1) {
                        bars.push(display);
                    }
                }
            });
            return item;
        });
        bars = bars.reverse();
        const len = chartData.length;
        const showLabels = len < 8;
        return(
            <ComposedChart width={422}
                       height={228}
                       data={chartData}>
              <XAxis dataKey="display"
                     tickFormatter={(t) => xTickFormatter(t, len, group)}
              />
              <CartesianGrid strokeDasharray="3 3"/>
              <YAxis/>
              <Tooltip
                offset={40}
                separator=": "
                formatter={(v, name, {dataKey}) => [ ['NSS'].includes(dataKey) ? `${v}` : `${toPercent(v, 2)}`, `${dataKey}`]}
              />
              <Legend/>
              {bars.map((b, index) => {
                  return (
                      <Bar
                        key={b}
                        stackId="a"
                        dataKey={b}
                        fill={getColorByKey(b)}
                        maxBarSize={48}>
                          { showLabels && (
                            <LabelList
                              style={{ fontSize: '12px' }}
                              dataKey={b}
                              formatter={v => `${toPercent(v, 2)}`}
                              position="insideTop"/>
                          )}
                      </Bar>
                  );
              })}
              <Line
                type='monotone'
                dataKey='NSS'
                stroke='#070707' />

            </ComposedChart>);
    }
}
class Historical extends React.Component {

    componentDidMount = () => {
      this.loadData();
    }

    componentDidUpdate = () => {
        const { needUpdate } = this.props;
        if (needUpdate) {
            this.loadData();
        }
    }

    loadData = () => {
        const { request, hasConstraint, segmentData, constraintSetted } = this.props;
        if (!segmentData.length > 0) return;
        if (hasConstraint && !constraintSetted) return;
        request();
    }

    handleGroupOnChange = (option) =>  {
        const { setGroup } = this.props;
        const { label, value } = option;
        setGroup(value, label);
    }

    render() {
        const { groupDisplay, group, fetching, data} = this.props;
        const chart = Object.keys(data).length > 0 ? <Chart data={data}  group={group}/> : null;
        const view = fetching === true ? (<Loading/>) : chart;
        return(
            <Card size="md">
              <CardToolbar
                title="Histórico Sentimiento"
                display={groupDisplay}
                options={groupOptions}
                onChange={this.handleGroupOnChange}
              />
              {view}
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {
      TextAnalytics: { historical: { groupDisplay, needUpdate, group, fetching, data } },
      Segments: { segments: { data: segmentData }, hasConstraint, constraintSetted }
    } = state;
    return {
        groupDisplay,
        data,
        group,
        fetching,
        needUpdate,
        hasConstraint,
        segmentData,
        constraintSetted,
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestHistorical()),
    setGroup: (group, display) => dispatch(setHistoricalGroup(group, display)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Historical);
