import { get, getIntervalsQuery } from './clipAPI'

const getTopicScorecard = async (token, interval, filter, extraFilter) => {
    const intervalQuery = getIntervalsQuery(interval)
    const fullFilter = Object.assign({}, filter, extraFilter)
    let URL = `/TextAnalytics/topic`
    URL += `?${intervalQuery}`
    URL += `&survey=5f10be84dfc8a23be8fca484`
    const hasFilter = Object.keys(fullFilter).length > 0
    if (hasFilter) {
        URL += `&filter=${encodeURIComponent(JSON.stringify(fullFilter))}`
    }
    return await get(URL, token)
}

const getTopicTrend = async (token, interval, group, filter, extraFilter, breakdown) => {
  const intervalQuery = getIntervalsQuery(interval)
  const fullFilter = Object.assign({}, filter, extraFilter)
  let URL = `/TextAnalytics/topic/trends-v2`
  URL += `?${intervalQuery}`
  URL += `&survey=5f10be84dfc8a23be8fca484`
  URL += `&group=${group}`
  URL += `&breakdown=${breakdown}`
  const hasFilter = Object.keys(fullFilter).length > 0
  if (hasFilter) {
    URL += `&filter=${encodeURIComponent(JSON.stringify(fullFilter))}`
  }
  return await get(URL, token)
}

const getTopicFeed = async (token, interval, filter, extraFilter) => {
  const intervalQuery = getIntervalsQuery(interval)
  const fullFilter = Object.assign({}, filter, extraFilter)
  let URL = `/TextAnalytics/topic/comments`
  URL += `?${intervalQuery}`
  URL += `&survey=5f10be84dfc8a23be8fca484`
  const hasFilter = Object.keys(fullFilter).length > 0
  if (hasFilter) {
      URL += `&filter=${encodeURIComponent(JSON.stringify(fullFilter))}`
  }
  return await get(URL, token)
}

export { getTopicScorecard, getTopicTrend, getTopicFeed }