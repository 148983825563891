import React from 'react';
import Card from '../../../widgets/card.jsx';
import CardToolbar from '../../../widgets/cardToolbar.jsx';
import CardMenu from '../../../widgets/cardMenu.jsx';
import { setGroup, setType, request } from '../../../reducers/trends';
import { connect } from 'react-redux';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line} from 'recharts';
import Loading from '../../../widgets/loading';
import { COLORS } from '../../../utils/colors';
import { toNumber, toPercent } from '../../../utils/formatter';

const groupOptions = [
    {label: "Mensual", value: "monthly"},
    {label: "Semanal", value: "weekly"},
    {label: "Día", value: "day"},
];

const menuOptions = [
    {label: "Tickets", value: "interactions"},
    {label: "Invitaciones", value: "invitations"},
    {label: "Respuestas", value: "responses"},
    // {label: "Tasa de Respuestas", value: "responseRate"},
];

const xTickFormatter = (tick, dataLen, group = "") => {
    if (dataLen > 5) {
        return '';
    }
    if (group === "weekly") {
        const values = tick.split(" ").slice(0, 2);
        return values.join(" ");
    }
    return tick;
};

class Chart extends React.Component {
    render() {
        const { data, type, group } = this.props;
        const dataKey = type === "responseRate" ? "percentage" : "n";
        const metric = menuOptions.find(o => o.value === type).label;
        const len = data.length;

        return(
            <LineChart width={550} height={233} data={data}>
              <XAxis
                tickFormatter={(t) => xTickFormatter(t, len, group)}
                dataKey="display"/>
              <YAxis dataKey={dataKey}/>
              <CartesianGrid strokeDasharray="3 3"/>
              <Tooltip
                offset={40}
                separator=""
                formatter={(v, n, p) => [`${toNumber(v)} ${metric} - ${toPercent(p.payload.percentage, 2)} del periodo`, '']}
              />
              <Line type="monotone"
                    dataKey={dataKey}
                    stroke={COLORS[0]}/>
            </LineChart>);
    }
}

class Trends extends React.Component {

    componentDidUpdate = (prevProps) => {
        const { group: prevGroup, type: prevType } = prevProps;
        const { needUpdate,  group, type } = this.props;
        if (needUpdate || prevGroup !== group || type !== prevType) {
            this.loadChart();
        }
    }

    loadChart = () => {
        const { request } = this.props;
        request();
    }

    handleGroupOnChange = (option) =>  {
        const { setGroup } = this.props;
        setGroup(option.label, option.value);
    }

    handleOptionOnChange = (option) => {
        const { setType } = this.props;
        setType(option);
    }


    render() {
        const { display, type, fetching } = this.props;
        const view = fetching ? (
            <div style={{margin: "auto auto"}}>
              <Loading/>
            </div>
        ) : (
            <div style={{marginLeft: "auto"}}>
              <Chart {...this.props}/>
            </div>
        );
        return(
            <Card size="lg">
              <CardToolbar
                title="Tendencia"
                options={groupOptions}
                display={display}
                onChange={this.handleGroupOnChange}
              />
              <div className="flexRow">
                <CardMenu
                  options={menuOptions}
                  onChange={this.handleOptionOnChange}
                  selected={type}
                />
                {view}
              </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const { Trends: { group,
                      groupDisplay,
                      type,
                      data,
                      needUpdate,
                      fetching }} = state;
    return {
        group,
        display: groupDisplay,
        type,
        data,
        fetching,
        needUpdate,
    };
};

const mapDispatchToProps = dispatch => ({
    setGroup: (display, group) => dispatch(setGroup(display, group)),
    setType: (type) => dispatch(setType(type)),
    request: () => dispatch(request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Trends);
