import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import 'react-dates/initialize';
import { connect } from 'react-redux';

import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import RecoverPassword from './pages/RecoverPassword/RecoverPassword';
// import Export from './pages/Export/Export';
import Main from './pages/Main';

const styles = {
    container: {
        width: "100%",
        height: "100%",
    },
};

class App extends Component {
    render() {
        return (
            <div style={styles.container}>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/olvide-contrasena" component={ForgotPassword} />
                <Route exact path="/recuperar-contrasena" component={RecoverPassword} />
                <Route path="/" component={Main} />
              </Switch>
            </div>
        );
    }
}

const stateToProps = state => state;

const dispatchToProps = dispatch => ({});

export default connect(stateToProps, dispatchToProps)(App);
