import app from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC63ZOlM86FjSzhLs4-HAElAGCIZTZlwcc",
    authDomain: "clip-cx.firebaseapp.com",
    databaseURL: "https://clip-cx.firebaseio.com",
    projectId: "clip-cx",
    storageBucket: "clip-cx.appspot.com",
    messagingSenderId: "773651008983",
    appId: "1:773651008983:web:3d0720d47b4421f8a88f92"
};

class Firebase {

    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
    }

    login(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    isInitialized() {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve);
        });
    }

    logout = () => {
        return this.auth.signOut();
    }

    sendPasswordResetEmail = email => {
        return this.auth.sendPasswordResetEmail(email);
    }

    sendNewPassword = (code, password) =>  {
        return this.auth.confirmPasswordReset(code, password);
    }

    refreshToken = async () => {
        return await this.auth.currentUser.getIdToken(true);
    }
}

export default new Firebase();
