import React from 'react';
import ResponseReceived from './responseReceived';
import InvitationSent from './invitationSent';
import Distribution from './distribution';
import Summary from './summary';

const styles = {
    main: {
        display: "flex",
        flexFlow: "column wrap",
    },
    content: {
        display: "flex",
        flexFlow: "row wrap",
    },

};

class Indicators extends React.Component {

    render() {
        return(
            <div style={styles.main}>
              <div style={styles.content}>
                <ResponseReceived/>
                <InvitationSent />
              </div>
              <div style={{
                  ...styles.content,
                  marginTop: "24px"
              }}>
                <Distribution/>
                <Summary/>
              </div>
            </div>
        );
    }
}

export default Indicators;
