import React from 'react';
import Card from '../../widgets/card.jsx';
import CardToolbar from '../../widgets/cardToolbar.jsx';
import { connect } from 'react-redux';
import Loading from '../../widgets/loading';
import { useTable, useSortBy } from 'react-table';
import { requestMentions, setMentionsGroup } from '../../reducers/textAnalytics';
import { toNumber } from '../../utils/formatter';

const groupOptions = [
    {label: "Frases", value: "comp1"},
    {label: "Sustantivos", value: "noun"},
    {label: "Adjetivos", value: "adjective"},
];

const styles = {
    input: {
        width: "190px",
        height: "32px",
    },
};

const Table = ({columns, data}) => {
    const { getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
          } = useTable({columns, data,}, useSortBy);

    return (
        <table
          style={{
              display: "block",
              height: "300px",
              maxWidth: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              fontSize: "12px",
              color: "#707070",
          }}
          {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                      <th
                        width={column.width}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{textAlign: (i !== 0) ? "right" : "left"}}>
                        {column.render('Header')}
                        <span
                        >
                          {column.isSorted
                           ? column.isSortedDesc
                           ? ' 🔽'
                           : ' 🔼'
                           : ''}
                        </span>
                      </th>
                  ))}
                </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(
                (row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, i) => {
                              return (
                                  <td
                                    style={{textAlign: (i !== 0) ? "right" : "left"}}
                                    {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                              );
                          })}
                        </tr>
                    );
                }
            )}
          </tbody>
        </table>
    );
};

export const toFixed = (decimal, fixed = 0) => {
    return `${decimal.toFixed(fixed)}`;
};

const Chart = (props) => {

    const { data } = props;
    const columns = React.useMemo( () => [
        { Header: "Frase", accessor: "display", minWidth: "35%" },
        { Header: "NSS", accessor: ({ nss }) => `${toFixed(nss)}`, minWidth: "10%" },
        { Header: "Positivas", accessor: ({ positive: p }) => `${toNumber(p.n)} (${p.percentage}%)`, minWidth: "20%"},
        { Header: "Negativas", accessor: ({ negative: n }) => `${toNumber(n.n)} (${n.percentage}%)`, minWidth: "20%"},
        { Header: "Totales", accessor: ({ totals }) => `${toNumber(totals)}`, minWidth: "15%"},
    ], []);
    return(
        <Table
          columns={columns}
          data={data}
        />
    );
};


class Mentions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            hideZeroResponse: false,
        };
    }

    componentDidMount = () => {
      this.loadData();
    }

    componentDidUpdate = () => {
        const { needUpdate } = this.props;
        if (needUpdate) {
            this.loadData();
        }
    }

    loadData = () => {
        const { request, hasConstraint, segmentData, constraintSetted } = this.props;
        if (!segmentData.length > 0) return;
        if (hasConstraint && !constraintSetted) return;
        request();
    }

    handleGroupOnChange = (option) =>  {
        const { setGroup } = this.props;
        const { label, value } = option;
        setGroup(value, label);
    }

    handleInputOnChange = ({target}) => {
        this.setState({search: target.value});
    }

    handleZeroResponseOnChange = ({target: { checked}}) => {
        this.setState({hideZeroResponse: checked});
    }

    render() {
        const { groupDisplay, fetching = true, data = [] } = this.props;
        const { search, hideZeroResponse } = this.state;
        const options = data.filter(it => {
            if (search === "") {
                return true;
            }
            if (it.display.toLowerCase().includes(search.toLowerCase())) {
                return true;
            }
            if (hideZeroResponse && it.totals > 0) {
                return true;
            }
            return false;
        });
        const view = fetching === true ? (<Loading/>) : <Chart data={options}/>;
        return(
            <Card size="mdLg">
              <CardToolbar
                title="Menciones de palabras"
                display={groupDisplay}
                options={groupOptions}
                onChange={this.handleGroupOnChange}
              />
              <input
                style={styles.input}
                type="text"
                className="form-control"
                placeholder="Buscar"
                onChange={this.handleInputOnChange}
                value={search}
              />
              <label
                style={{marginTop: "16px"}}
                className="base-checkbox">
                <input
                  style={{marginRight: "8px"}}
                  type="checkbox"
                  checked={hideZeroResponse}
                  onChange={this.handleZeroResponseOnChange}
                />
                Ocultar registro sin respuestas
              </label>
              {view}
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {
      TextAnalytics: { mentions: { needUpdate, data, fetching, groupDisplay }},
      Segments: { segments: { data: segmentData }, hasConstraint, constraintSetted }
    } = state;
    return {
        needUpdate,
        data,
        fetching,
        groupDisplay,
        hasConstraint,
        segmentData,
        constraintSetted,
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestMentions()),
    setGroup: (group, display) => dispatch(setMentionsGroup(group, display)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mentions);
