import { makeTypes, makeActions as mac } from '../utils/reducers';
import { handleError } from './session';
import { getTrends } from '../services/performance';
import { FILTERS_CHANGED, getFilterToApply } from './filters';

const t = makeTypes('trends');

//TYPES
const SET_GROUP = t('setGroup', false);
const SET_TYPE = t('setType', false);
const FETCH_DATA = t('fetchData', true);

//ACTIONS
export const setGroup = mac(SET_GROUP, 'display', 'group');
export const setType = mac(SET_TYPE, 'payload');

const fetchDataStart = mac(FETCH_DATA.START);
const fetchDataSuccess = mac(FETCH_DATA.SUCCESS, 'payload');
const fetchDataError = mac(FETCH_DATA.ERROR, 'error');

//STATE
const initState = {
    groupDisplay: 'Mensual',
    group: 'monthly',
    type: 'interactions',
    data: [],
    fetching: false,
    error: null,
    needUpdate: false,
};

//REDUCER
export default function reducer(state = initState, action) {
    switch(action.type) {
    case SET_GROUP:
        return {
            ...state,
            groupDisplay: action.display,
            group: action.group,
        };
    case SET_TYPE:
        return {
            ...state,
            type: action.payload,
        };
    case FETCH_DATA.START:
        return {
            ...state,
            fetching: true,
            error: null,
            needUpdate: false,
        };
    case FETCH_DATA.SUCCESS:
        return {
            ...state,
            fetching: false,
            data: action.payload,
        };
    case FETCH_DATA.ERROR:
        return {
            ...state,
            fetching: false,
            error: action.error,
        };
    case FILTERS_CHANGED:
        return {
            ...state,
            needUpdate: true,
        };
    default:
        return state;
    }
}

//THUNKS
export const request = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { Trends: { group, type }} = state;
        const filters = getFilterToApply(state);
        dispatch(fetchDataStart());
        try {
            const response = await getTrends(type,
                                             token,
                                             filters,
                                             group);
            const chart = response.data.chart;
            dispatch(fetchDataSuccess(chart));
        }
        catch(error) {
            console.error(`[trends] request:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchDataError(error));
        }
    };
