import { makeTypes, makeActions as mac } from '../utils/reducers';

import { handleError } from './session';
import { getInfo, getInfoKeyOptions } from '../services/kpis';
import { FILTERS_CHANGED, getFilterToApply } from './filters';

const t = makeTypes('kpis');

//TYPES
const SET_HISTORY_GROUP = t('setHistory', false);
const SET_TYPE = t('setType', false);
const FETCH_HISTORY = t('fetchHistory', true);
const FETCH_GENERAL = t('fetchGeneral', true);
const FETCH_AREA = t('fetchArea', true);
const SET_AREA_GROUP = t('setAreaGroup', false);
const SET_REASONS_GROUP = t('setReasonsGroup', true);
const SET_BREAKDOWN = t('setBreakdown', false);
const FETCH_DETAIL = t('fetchDetail', true);
const SET_DETAIL_SELECTED_ITEMS = t('setDetailSelectedItems', true);
const SET_TRENDS_GROUP = t('setTrendsGroup', false);
const FETCH_TRENDS = t('fetchTrends', true);
const DETAIL_UPDATE_BTN_CLICKED = t('detailUpdateBtnClicked', false);

//ACTIONS
export const setType = mac(SET_TYPE, 'payload');

export const setHistoryGroup = mac(SET_HISTORY_GROUP, 'group', 'display');
const fetchHistoryStart = mac(FETCH_HISTORY.START);
const fetchHistorySuccess = mac(FETCH_HISTORY.SUCCESS, 'payload');
const fetchHistoryError = mac(FETCH_HISTORY.ERROR, 'error');

const fetchGeneralStart = mac(FETCH_GENERAL.START);
const fetchGeneralSuccess = mac(FETCH_GENERAL.SUCCESS, 'payload');
const fetchGeneralError = mac(FETCH_GENERAL.ERROR, 'error');

export const setAreaGroup = mac(SET_AREA_GROUP, 'group', 'display');
const fetchAreaStart = mac(FETCH_AREA.START);
const fetchAreaSuccess = mac(FETCH_AREA.SUCCESS, 'payload');
const fetchAreaError = mac(FETCH_AREA.ERROR, 'error');

export const setBreakdown = mac(SET_BREAKDOWN, 'breakdown', 'display');
const fetchDetailStart = mac(FETCH_DETAIL.START);
const fetchDetailSuccess = mac(FETCH_DETAIL.SUCCESS, 'payload');
const fetchDetailError = mac(FETCH_DETAIL.ERROR, 'error');
export const setDetailSelectedItems = mac(SET_DETAIL_SELECTED_ITEMS, 'payload');

export const setTrendsGroup = mac(SET_TRENDS_GROUP, 'group', 'display');
const fetchTrendsStart = mac(FETCH_TRENDS.START);
const fetchTrendsSuccess = mac(FETCH_TRENDS.SUCCESS, 'payload');
const fetchTrendsError = mac(FETCH_TRENDS.ERROR, 'error');

export const detailUpdateBtnClicked = mac(DETAIL_UPDATE_BTN_CLICKED);

//STATE
const initState = {
    history: {
        data: [],
        error: null,
        fetching: false,
        group: "weekly",
        groupDisplay: "Semanal",
        needUpdate: false,
    },
    general: {
        data: {
            display: "",
        },
        error: null,
        fetching: false,
        needUpdate: false,
    },
    area: {
        data: [],
        error: null,
        fetching: false,
        group: "area",
        groupDisplay: "Área",
        needUpdate: false,
    },
    detail: {
        data: [],
        error: null,
        fetching: false,
        needUpdate: false,
        selectedItems: [],
    },
    trends: {
        data: [],
        error: null,
        fetching: false,
        group: "weekly",
        groupDisplay: "Semanal",
        needUpdate: false,
    },
    filter: null,
    type: 'nps',
    breakdown: 'ticketArea',
    breakdownDisplay: 'Área',
};

//REDUCER

export default function reducer(state = initState, action) {
    switch(action.type) {

    case SET_HISTORY_GROUP:
        return {
            ...state,
            history: {
                ...state.history,
                group: action.group,
                groupDisplay: action.display,
            }
        };
    case FETCH_HISTORY.START:
        return {
            ...state,
            history: {
                ...state.history,
                fetching: true,
                error: null,
                needUpdate: false,
            },
        };
    case FETCH_HISTORY.SUCCESS:
        return {
            ...state,
            history: {
                ...state.history,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_HISTORY.ERROR:
        return {
            ...state,
            history: {
                ...state.history,
                fetching: false,
                error: action.error
            },
        };
    case FILTERS_CHANGED:
        return {
            ...state,
            history: {
                ...state.history,
                needUpdate: true,
            },
            general: {
                ...state.general,
                needUpdate: true,
            },
            area: {
                ...state.area,
                needUpdate: true,
            },
            detail: {
                ...state.detail,
                needUpdate: true,
            },
            trends: {
                ...state.trends,
                needUpdate: true,
            },
        };
    case FETCH_GENERAL.START:
        return {
            ...state,
            general: {
                ...state.general,
                fetching: true,
                error: null,
                needUpdate: false,
            },
        };
    case FETCH_GENERAL.SUCCESS:
        return {
            ...state,
            general: {
                ...state.general,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_GENERAL.ERROR:
        return {
            ...state,
            general: {
                ...state.general,
                fetching: false,
                error: action.error
            },
        };
    case SET_TYPE:
        return {
            ...state,
            general: {
                ...state.general,
                needUpdate: true,
            },
            history: {
                ...state.history,
                needUpdate: true,
            },
            area: {
                ...state.area,
                needUpdate: true,
            },
            detail: {
                ...state.detail,
                needUpdate: true,
            },
            trends: {
                ...state.trends,
                needUpdate: true,
            },
            type: action.payload,
        };
    case SET_AREA_GROUP:
        return {
            ...state,
            area: {
                ...state.area,
                group: action.group,
                groupDisplay: action.display,
            }
        };
    case FETCH_AREA.START:
        return {
            ...state,
            area: {
                ...state.area,
                fetching: true,
                error: null,
                needUpdate: false,
            },
        };
    case FETCH_AREA.SUCCESS:
        return {
            ...state,
            area: {
                ...state.area,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_AREA.ERROR:
        return {
            ...state,
            area: {
                ...state.area,
                fetching: false,
                error: action.error
            },
        };
    case SET_REASONS_GROUP:
        return {
            ...state,
            reasons: {
                ...state.reasons,
                group: action.group,
                groupDisplay: action.display,
            }
        };
    case SET_BREAKDOWN:
        return {
            ...state,
            detail: {
                ...state.detail,
                needUpdate: true,
                selectedItems: [],
            },
            trends: {
                ...state.trends,
                needUpdate: true,
            },
            breakdown: action.breakdown,
            breakdownDisplay: action.display
        };
    case FETCH_DETAIL.START:
        return {
            ...state,
            detail: {
                ...state.detail,
                fetching: true,
                error: null,
                needUpdate: false,
            },
        };
    case FETCH_DETAIL.SUCCESS:
        return {
            ...state,
            detail: {
                ...state.detail,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_DETAIL.ERROR:
        return {
            ...state,
            detail: {
                ...state.detail,
                fetching: false,
                error: action.error
            },
        };
    case SET_DETAIL_SELECTED_ITEMS:
        return {
            ...state,
            detail: {
                ...state.detail,
                selectedItems: action.payload,
            },
        };
    case DETAIL_UPDATE_BTN_CLICKED:
        return {
            ...state,
            trends: {
                ...state.trends,
                needUpdate: true
            }
        };
    case FETCH_TRENDS.START:
        return {
            ...state,
            trends: {
                ...state.trends,
                fetching: true,
                error: null,
                needUpdate: false,
            },
        };
    case FETCH_TRENDS.SUCCESS:
        return {
            ...state,
            trends: {
                ...state.trends,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_TRENDS.ERROR:
        return {
            ...state,
            trends: {
                ...state.trends,
                fetching: false,
                error: action.error
            },
        };
    case SET_TRENDS_GROUP:
        return {
            ...state,
            trends: {
                ...state.trends,
                group: action.group,
                groupDisplay: action.display,
                needUpdate: true,
            },
        };
    default:
        return state;
    }
}

export const requestHistory = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { Kpis: { type, history: { group } } } = state;
        try {
            dispatch(fetchHistoryStart());
            const filters = getFilterToApply(state);
            const response = await getInfo(token,
                                           type,
                                           'trends',
                                           group,
                                           filters);
            const data = response.data;
            let res = [];
            if (Array.isArray(data)) {
                res = data[0].chart;
            }
            else {
                res = data.chart;
            }
            dispatch(fetchHistorySuccess(res));
        }
        catch(error) {
            console.error(`[kpis] requestHistory:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchHistoryError(error));
        }
    };

export const requestGeneral = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { Kpis: { type, history: { group } } } = state;
        try {
            dispatch(fetchGeneralStart());
            const filters = getFilterToApply(state);
            const response = await getInfo(token,
                                           type,
                                           'summary',
                                           group,
                                           filters);
            const data = response.data;
            dispatch(fetchGeneralSuccess(data));
        }
        catch(error) {
            console.error(`[kpis] requestGeneral:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchGeneralError(error));
        }
    };

export const requestArea = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { Kpis: { type, area: { group } } } = state;
        try {
            dispatch(fetchAreaStart());
            const filters = getFilterToApply(state);
            const response = await getInfo(token,
                                           type,
                                           'group',
                                           group,
                                           filters);
            const data = response.data.chart;
            dispatch(fetchAreaSuccess(data));
        }
        catch(error) {
            console.error(`[kpis] requestArea:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchAreaError(error));
        }
    };

export const requestDetail = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { Kpis: { type, breakdown } } = state;
        try {
            dispatch(fetchDetailStart());
            const filters = getFilterToApply(state);
            const response = await getInfo(token,
                                           type,
                                           'detail',
                                           breakdown,
                                           filters);
            const data = response.data.chart;
            dispatch(fetchDetailSuccess(data));
        }
        catch(error) {
            console.error(`[kpis] requestDetail:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchDetailError(error));
        }
    };

export const requestTrends = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { Kpis: { type, breakdown,
                        detail: { selectedItems },
                        trends: { group }} } = state;
        try {
            dispatch(fetchTrendsStart());
            const filters = getFilterToApply(state);
            const response = await getInfoKeyOptions(token,
                                                     type,
                                                     group,
                                                     filters,
                                                     breakdown,
                                                     selectedItems);
            const data = response.data;
            dispatch(fetchTrendsSuccess(data));
        }
        catch(error) {
            console.error(`[kpis] requestTrends:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchTrendsError(error));
        }
    };
