import React from 'react';

import firebase from '../firebase.js';
import './sidebar.css';
import img from '../images/brand-logo-white_2.png';
import Menu from './menu.jsx';

export default class Sidebar extends React.Component {

    logout = async () => {
        const { onLogout } = this.props;
        if (typeof onLogout === 'function') {
            try {
                await firebase.logout();
            }
            catch(ex) {
                console.log(`Sidebar] logout:: ${ex}`);
            }
            finally {
                onLogout();
            }
        }
    }

    render() {
        var { username } = this.props;
        username = username || "Usuario Clip";
        const avatar = username.substring(0, 1);
        return(
            <div className="sidebar">
              <span className="logo">
                <img
                  className="mr-3"
                  src={img}
                  width="48"
                  height="48"
                  alt="logo"
                />
                Customer Happiness
              </span>
              <Menu/>
              <div className="navbar-collapse userBtn">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <span
                      role="button"
                      className="nav-link dropdown-toggle centered"
                      aria-haspopup="true"
                      aria-expanded="false"
                      id="navbarDropdown"
                      data-toggle="dropdown">

                      <img src={`https://via.placeholder.com/40/fc4c02/ffffff?text=${avatar}`}
                           alt="Logo"
                           className="avatar"/>
                      <div className="ml-2">{username}</div>
                    </span>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown">
                      <span className="dropdown-item"
                         onClick={this.logout}>
                        Logout
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
        );
    }
}

