import React from 'react';
import History from './history';
import General from './general';
import Area from './area';
import { connect } from 'react-redux';

class View extends React.Component {

    render() {
        return(
            <>
              <div className="flexRow">
                <General/>
                <div style={{marginLeft: "24px"}}>
                  <History/>
                </div>
              </div>
              <div style={{marginTop: "24px"}}>
                <Area />
              </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    const { Kpis: { filter, type } } = state;
    return {
        filter,
        type
    };
};

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
