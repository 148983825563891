import React from 'react';
import Navbar from '../../widgets/navbar/navbar';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import View from './view';
import Detail from './detail';
import { setType } from '../../reducers/kpis';
import Breakdown from './detail/breakdown.jsx';

const options = [
    { label: "NPS", value: "nps"},
    { label: "CSAT", value: "csat"},
    { label: "CES", value: "ces"},
];

class KPIS extends React.Component {

  handleTypeOptionChanged = ({label, value}) => () => {
    const { setType } = this.props;
    setType(value);
  }

  render() {
    const { type } = this.props;
    return (
      <div className="flexColumn">
        <div
          className="navbar-collapse"
          style={{ marginBottom: "20px",
                   color: "#0288D1",
                   marginLeft: "8px",
                   display: 'flex',
                   flexDirection: 'row'}}
        >
          <div style={{ color: '#333333', marginRight: '0.5em' }}>
            Métrica:
          </div>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <span
                role="button"
                className="nav-link dropdown-toggle centered"
                aria-haspopup="true"
                aria-expanded="false"
                style={{padding: "0px"}}
                id="invitationSent"
                data-toggle="dropdown">
                {type.toUpperCase()}
              </span>
              <div
                className="dropdown-menu"
                aria-labelledby="invitationSent"
              >
                {options.map(option => (
                  <span
                    key={option.value}
                    onClick={this.handleTypeOptionChanged(option)}
                    className="dropdown-item"
                    style={{ cursor: 'pointer' }}>
                    {option.label}
                  </span>
                ))}
              </div>
            </li>
          </ul>
          <Route exact
                  path="/kpis/detalle"
                  component={Breakdown}/>
        </div>
        <Route
          exact
          path="/kpis/general"
          component={View} />
        <Route
          exact
          path="/kpis/detalle"
          component={Detail}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
    const { Filters: { filters: { data },
                       filter,
                       defaults,
                     } } = state;
    const { Kpis: { type } } = state;
    return {
        data,
        filter,
        type,
        defaults
    };
};

const mapDispatchToProps = dispatch => ({
    setType: (type) => dispatch(setType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIS);
