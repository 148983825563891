import React from 'react';
import Card from '../../widgets/card';
import Loading from '../../widgets/loading';
import CardToolbar from '../../widgets/cardToolbar';
import CardMenu from '../../widgets/cardMenu';
import { connect } from 'react-redux';
import { setDistributionGroup,
         setDistributionType,
         requestDistribution } from '../../reducers/performance';
import { Bar, XAxis, YAxis, Tooltip, ComposedChart, LabelList, CartesianGrid } from 'recharts';
import { COLORS } from '../../utils/colors';
import { toPercent, toNumber } from '../../utils/formatter';

const menuOptions = [
    {label: "Tickets", value: "interactions"},
    {label: "Invitaciones", value: "invitations"},
    {label: "Respuestas", value: "responses"},
    {label: "Tasa de Respuestas", value: "responseRate"},
];

class Chart extends React.Component {
    render() {
        const { data, type } = this.props;
        const metric = menuOptions.find(o => o.value === type).label;

        return(
            <ComposedChart layout="vertical"
              width={550}
              height={226}
              data={data} >
              <XAxis type="number" tickFormatter={toPercent} />
              <YAxis dataKey="display" type="category" orientation="right"/>
              <CartesianGrid strokeDasharray="3 3"/>
              {type === 'responseRate' ? (
                <Tooltip
                  cursor={false}
                  offset={40}
                  separator=""
                  formatter={(v, n, p) => [`${toNumber(p.payload.n)} respuestas de ${toNumber(p.payload.total)} invitaciones (${v}%)`, '']}
                />
              ) : (
                <Tooltip
                  cursor={false}
                  offset={40}
                  separator=""
                  formatter={(v, n, p) => [`${toNumber(p.payload.n)} ${metric} (${v}%)`, '']}
                />
              ) }
              <Bar
                dataKey="percentage"
                fill={COLORS[0]}>
                <LabelList dataKey="n" position="insideLeft" offset={8} formatter={toNumber} />
                <LabelList dataKey="percentage" position="insideLeft" formatter={v => v === 0 ? '' : `(${v}%)`} offset={56} />
              </Bar>
            </ComposedChart>
        );
    }
}

const groupOptions = [
    {label: "Área", value: "area"},
    {label: "Subárea", value: "subArea"},
    {label: "Canal", value: "channel"},
];
class Distribution extends React.Component {


    componentDidMount = () => {
      this.loadChart(this.props);
    }

    componentDidUpdate = (prevProps) => {
        const { fetching, group: prevGroup, type: prevType } = prevProps;
        const { needUpdate, group, type } = this.props;
        if ((needUpdate || prevGroup !== group || type !== prevType) && !fetching) {
            this.loadChart(this.props);
        }
    }

    loadChart = (props) => {
        const { request, hasConstraint, segmentData, constraintSetted } = props;
        if (!segmentData.length > 0) return;
        if (hasConstraint && !constraintSetted) return;
        request();
    }

    handleGroupOnChange = (option) => {
        const { setDistributionGroup } = this.props;
        setDistributionGroup(option.label, option.value);
    }

    handleOptionOnChange = (value) => {
        const { setDistributionType } = this.props;
        setDistributionType(value);
    }

    render() {
        const { fetching, display, type } = this.props;
        const view = fetching ? (
            <div style={{margin: "auto auto"}}>
              <Loading/>
            </div>
        ) : (
            <div style={{marginLeft: "auto"}}>
              <Chart {...this.props}/>
            </div>
        );
        return(
            <Card size="lg">
              <CardToolbar
                title="Distribución"
                options={groupOptions}
                display={display}
                onChange={this.handleGroupOnChange}
              />
              <div className="flexRow">
                <CardMenu
                  options={menuOptions}
                  onChange={this.handleOptionOnChange}
                  selected={type}
                />
                {view}
              </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const {
      Performance: { distribution: { group, display, type, data, needUpdate, fetching } },
      Segments: { segments: { data: segmentData }, hasConstraint, constraintSetted }
    } = state;
    return {
        group,
        display,
        type,
        data,
        fetching,
        needUpdate,
        hasConstraint,
        segmentData,
        constraintSetted,
    };
};

const mapDispatchToProps = dispatch => ({
    setDistributionType: type => dispatch(setDistributionType(type)),
    setDistributionGroup: (display, option) => dispatch(setDistributionGroup(display, option)),
    request: () => dispatch(requestDistribution()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Distribution);
