import { getData, getInfo, getDistribution,
         getSummary } from '../services/performance';
import { makeTypes, makeActions as mac } from '../utils/reducers';
import { handleError } from './session';
import { FILTERS_CHANGED, getFilterToApply } from './filters';

const t = makeTypes('performance');

const FETCH_RESPONSES_RECEIVED = t('fetchResponseReceived', true);
const FETCH_VARIANTS = t('fetchVariants', true);
const FILTER_SET_DATES = t('filter/set/date', false);
const FILTER_SET_FILTER = t('filter/set/filter', false);
const VARIANT_OPTION = t('variantOption', false);
const SET_DIST_GROUP = t('setDistributionGroup', false);
const SET_DIST_TYPE = t('setDistributionType', false);
const FETCH_DIST = t('fetchDistribution', true);
const FETCH_SUMMARY = t('fetchSummary', true);

//ACTIONS
//-- VARIANTS

const fetchVariantsStart = mac(FETCH_VARIANTS.START);
const fetchVariantsSuccess = mac(FETCH_VARIANTS.SUCCESS, 'payload');
const fetchVariantsError = mac(FETCH_VARIANTS.ERROR, 'error');
export const setVariantOption = mac(VARIANT_OPTION, 'option', 'display');

//-- Responses Received
const fetchResponsesReceivedStart = mac(FETCH_RESPONSES_RECEIVED.START);
const fetchResponsesReceivedSuccess = mac(FETCH_RESPONSES_RECEIVED.SUCCESS, 'payload');
const fetchResponsesReceivedError = mac(FETCH_RESPONSES_RECEIVED.SUCCESS, 'error');

//-- distribution
const fetchDistStart = mac(FETCH_DIST.START);
const fetchDistSuccess = mac(FETCH_DIST.SUCCESS, 'payload');
const fetchDistError = mac(FETCH_DIST.ERROR, 'error');
export const setDistributionGroup = mac(SET_DIST_GROUP, 'display', 'group');
export const setDistributionType = mac(SET_DIST_TYPE, 'payload');

//Summary
const fetchSummaryStart = mac(FETCH_SUMMARY.START);
const fetchSummarySuccess = mac(FETCH_SUMMARY.SUCCESS, 'payload');
const fetchSummaryError = mac(FETCH_SUMMARY.ERROR, 'error');


//Initial State
const initState = {
    responsesReceived: {
        fetching: false,
        data: {},
        error: null,
        needUpdate: false,
    },
    variants: {
        fetching: false,
        data: {},
        error: null,
        display: "Invitaciones enviadas",
        option: "invitationSent",
        needUpdate: false,
    },
    distribution: {
        group: "area",
        display: "Área",
        type: "interactions",
        data: [],
        fetching: false,
        error: null,
        needUpdate: false,
    },
    summary: {
        fetching: false,
        error: null,
        data: [],
        needUpdate: false,
    }
};

//Reducer
export default function reducer(state = initState, action) {
    switch (action.type) {
        //RESPONSES
    case FETCH_RESPONSES_RECEIVED.START:
        return {
            ...state,
            responsesReceived: {
                ...state.responsesReceived,
                fetching: true,
                needUpdate: false,
            }
        };

    case FETCH_RESPONSES_RECEIVED.SUCCESS:
        return {
            ...state,
            responsesReceived: {
                ...state.responsesReceived,
                fetching: false,
                data: action.payload,
                needUpdate: false,
            },
        };
    case FETCH_RESPONSES_RECEIVED.ERROR:
        return {
            ...state,
            responsesReceived: {
                ...state.responsesReceived,
                fetching: false,
                error: action.error,
                needUpdate: false,
            },
        };
        //VARIANTS
    case  VARIANT_OPTION:
        return {
            ...state,
            variants: {
                ...state.variants,
                display: action.display,
                option: action.option
            }
        };
    case FETCH_VARIANTS.START:
        return {
            ...state,
            variants: {
                ...state.variants,
                fetching: true,
                needUpdate: false,
            }
        };
    case FETCH_VARIANTS.SUCCESS:
        return {
            ...state,
            variants: {
                ...state.variants,
                fetching: false,
                data: action.payload,
                needUpdate: false,
            },
        };
    case FETCH_VARIANTS.ERROR:
        return {
            ...state,
            variants: {
                ...state.variants,
                fetching: false,
                error: action.error,
                needUpdate: false,
            },
        };
        //FILTERS
    case FILTER_SET_DATES:
        let filters = {
            ...state.filters,
            filter: state.filters.filter,
            interval: {
                start: action.start,
                end: action.end
            },
        };
        return {
            ...state,
            filters
        };
    case FILTER_SET_FILTER:
        const { filter } = action;
        let filters2 = {
            ...state.filters,
            interval: state.filters.interval,
        };
        filters2.filter = Object.keys(filter).length === 0 ? null : filter;
        return {
            ...state,
            filters: filters2
        };
        //DISTRIBUTION
    case SET_DIST_GROUP:
        return {
            ...state,
            distribution: {
                ...state.distribution,
                group: action.group,
                display: action.display
            },
        };
    case SET_DIST_TYPE:
        return {
            ...state,
            distribution: {
                ...state.distribution,
                type: action.payload
            },
        };
    case FETCH_DIST.START:
        return {
            ...state,
            distribution: {
                ...state.distribution,
                fetching: true,
                needUpdate: false,
            },
        };
    case FETCH_DIST.SUCCESS:
        return {
            ...state,
            distribution: {
                ...state.distribution,
                fetching: false,
                data: action.payload,
                needUpdate: false,
            },
        };
    case FETCH_DIST.ERROR:
        return {
            ...state,
            distribution: {
                ...state.distribution,
                fetching: false,
                error: action.error,
                needUpdate: false,
            },
        };
        //SUMMARY
    case FETCH_SUMMARY.START:
        return {
            ...state,
            summary: {
                ...state.summary,
                fetching: true,
                needUpdate: false,
            },
        };
    case FETCH_SUMMARY.SUCCESS:
        return {
            ...state,
            summary: {
                ...state.summary,
                fetching: false,
                data: action.payload,
                needUpdate: false,
            },
        };
    case FETCH_SUMMARY.ERROR:
        return {
            ...state,
            summary: {
                ...state.summary,
                fetching: false,
                error: action.error,
                needUpdate: false,
            },
        };
    case FILTERS_CHANGED:
        return {
            ...state,
            responsesReceived: {
                ...state.responsesReceived,
                needUpdate: true,
            },
            summary: {
                ...state.summary,
                needUpdate: true,
            },
            distribution: {
                ...state.distribution,
                needUpdate: true,
            },
            variants: {
                ...state.variants,
                needUpdate: true,
            },
        };
    default:
        return state;
    };
}

//Thunks
export const requestResponsesReceived = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const filters = getFilterToApply(state);
        dispatch(fetchResponsesReceivedStart());
        try {
            const response = await getData(token, filters);
            dispatch(fetchResponsesReceivedSuccess(response.data));
        }
        catch(error) {
            console.error(`[requestResponsesReceived]:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchResponsesReceivedError(error));
        }
    };

export const requestVariants = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { Performance: { variants: { option } } } = state;
        const filters = getFilterToApply(state);
        dispatch(fetchVariantsStart());
        try {
            const response = await getInfo(option, token, filters);
            dispatch(fetchVariantsSuccess(response.data));
        }
        catch(error) {
            console.error(`[performance] requestVariants:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchVariantsError(error));
        }
    };

export const requestDistribution = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const { Performance: { distribution: { group, type } } } = state;
        const filters = getFilterToApply(state);
        try {
            dispatch(fetchDistStart());
            const response = await getDistribution(type,
                                                   token,
                                                   filters,
                                                   group);
            const chart = response.data.chart;
            dispatch(fetchDistSuccess(chart));
        }
        catch(error) {
            console.error(`[performance] requestDistribution:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchDistError(error));
        }
    };

export const requestSummary = () =>
    async (dispatch, getState) => {
        const state = getState();
        const { Session: { token }} = state;
        const filters = getFilterToApply(state);
        try {
            dispatch(fetchSummaryStart());
            const response = await getSummary(token, filters);
            const data = response.data;
            dispatch(fetchSummarySuccess(data));
        }
        catch(error) {
            console.error(`[performance] requestSummary:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchSummaryError(error));
        }
    };
