import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAuditTrend } from '../../../services/audit'
import { useQueryConfig } from '../../../hooks/use-query-config'
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid'
import TopSegment from '../../../components/top-segment'
import { FETCH_TOPICS } from '../../../reducers/textAnalytics'
import { FILTERS_CHANGED } from '../../../reducers/filters'
import {
  metricOptions,
  breakdownOptions,
  timeOptions,
  tableCols,
  numberColumn,
} from './trend-table.config'
import TrendChart from './trend-chart'

function normalizeData (data = []) {
  let timeHeaders = new Set()
  const normalized = data.map(entity => {
    let entry = { ...entity, total: 0 }
    entity.chart.forEach(item => {
      const header = item.display.split('.')[0]
      entry.total += item.n
      entry[header] = item.n
      timeHeaders.add(header)
    })
    return entry
  });
  return { data: normalized, timeHeaders }
}

export const AuditTrend = () => {
  const dispatch = useDispatch()
  const [metricField, setMetricField] = useState(metricOptions[0].value)
  const [breakdownField, setBreakdownField] = useState(breakdownOptions[0].value)
  const [timeUnit, setTimeUnit] = useState(timeOptions[0].value)
  const { token, filter, interval, needUpdate } = useQueryConfig()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [cols, setCols] = useState([])
  const [selected, setSelected] = useState({})

  function mergeHeaders(timeHeaders) {
    let fullCols = [...tableCols]
    timeHeaders.forEach(value => {
      fullCols.push({
        headerName: value,
        field: value,
        ...numberColumn
      })
    })
    setCols(fullCols)
  }

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getAuditTrend({
        token,
        interval,
        filter,
        groupBy: breakdownField,
        countField: metricField,
        timeUnit,
      })
      const normalized = normalizeData(response.data)
      setData(normalized.data)
      mergeHeaders(normalized.timeHeaders)
    } catch (err) {
      setData([])
    } finally {
      dispatch({ type: FETCH_TOPICS.START })
      setLoading(false)
    }
  }, [token, interval, filter, breakdownField, metricField, timeUnit, dispatch])

  function onFieldChange (field) {
    if (field !== breakdownField) {
      setBreakdownField(field)
      dispatch({ type: FILTERS_CHANGED })
    }
  }
  
  function onMetricChange (field) {
    if (field !== metricField) {
      setMetricField(field)
      dispatch({ type: FILTERS_CHANGED })
    }
  }
  
  function onTimeUnitChange (unit) {
    if (unit !== timeUnit) {
      setTimeUnit(unit)
      dispatch({ type: FILTERS_CHANGED })
    }
  }

  function renderChart (selectedRows) {
    const selected = data.find(item => item.display === selectedRows[0])
    setSelected(selected)
  }

  useEffect(() => {
    needUpdate && loadData()
  }, [needUpdate, loadData])

  useEffect(() => {
    if (data.length) {
      renderChart([data[0].display])
    }
  }, [data])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TopSegment
          title="Mostrar"
          options={metricOptions}
          value={metricField}
          onChange={onMetricChange}
          styles={{ marginRight: '2em' }}
          />
        <TopSegment
          title="Agrupar por"
          options={breakdownOptions}
          value={breakdownField}
          onChange={onFieldChange}
          styles={{ marginRight: '2em' }}
        />
        <TopSegment
          title="Ver por"
          options={timeOptions}
          value={timeUnit}
          onChange={onTimeUnitChange}
        />
      </div>
      <TrendChart data={selected.chart} label={selected.display} />
      <DataGrid
        loading={loading}
        getRowId={r => r.display}
        rows={data}
        columns={cols}
        hideFooter
        style={{ fontSize: '13px', backgroundColor: '#FFFFFF' }}
        density="compact"
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        onSelectionModelChange={renderChart}
        disableColumnSelector
        components={{
          Toolbar: GridToolbar
        }}
        componentsProps={{
          toolbar: {
            csvOptions: { fileName: 'ResumenTickets' },
            printOptions: { disableToolbarButton: true },
          }
        }}
      />
    </div>
  )
}

export default AuditTrend;