import React, { useCallback, useEffect, useState } from 'react'
import CardToolbar from '../../widgets/cardToolbar.jsx'
import { useSelector } from 'react-redux'
import Loading from '../../widgets/loading'
import { getTopicFeed } from '../../services/textAnalytics-topic'
import { topicDate } from '../../utils/formatter'
import { Dropdown } from '../../components/dropdown'

function sortBy (k, list = []) {
  const order = k.substring(0, 1)
  const field = k.substring(1)

  list.sort((f, s) => {
    let a, b
    // Ambos son numéricos
    if (!isNaN(f[field]) && !isNaN(s[field])) {
      a = +f[field]
      b = +s[field]
    } else {
      a = f[field]
      b = s[field]
    }
    if (a > b) return -1
    if (b > a) return 1
    return 0
  })

  if (order === '+') {
    return list.reverse()
  }

  return list
}

const segmentOptions = [
    { label: "Todos", value: "all" },
    { label: "NPS: Detractores", value: { NPS: { $in: ['0', '1', '2', '3', '4', '5', '6'] } } },
    { label: "NPS: Neutro", value: { NPS: { $in: ['7', '8'] } } },
    { label: "NPS: Promotores", value: { NPS: { $in: ['9', '10'] } } },
    { label: "Sentimiento: Negativo", value: { 'COMENTARIO_comprehend.Sentiment': 'NEGATIVE' } },
    { label: "Sentimiento: Mixto", value: { 'COMENTARIO_comprehend.Sentiment': 'MIXED' } },
    { label: "Sentimiento: Neutro", value: { 'COMENTARIO_comprehend.Sentiment': 'NEUTRAL' } },
    { label: "Sentimiento: Positivo", value: { 'COMENTARIO_comprehend.Sentiment': 'POSITIVE' } },
]

const sortOptions = [
  { label: "NPS (0-10)", value: '+NPS' },
  { label: "NPS (10-0)", value: '-NPS' },
  { label: "Más recientes", value: "-RESPONSE_DATE" },
  { label: "Más antiguos", value: "+RESPONSE_DATE" },
]

const Tag = ({ label, extraClasses }) => (
  <span className={`t-tag ${extraClasses}`}>
    {label}
  </span>
)

const Comment = ({ NPS, COMENTARIO, SENTIMIENTO, TOPIC, CATEGORY, RESPONSE_DATE, CHANNEL, TICKET }) => (
  <div className="t-comment">
    <span className={`t-score _${NPS}`}>{NPS}</span>
    <div style={{ flex: 1 }}>
      <p className="t-comment_text">{COMENTARIO}</p>
      <div>
        <Tag label={SENTIMIENTO} extraClasses={SENTIMIENTO} />
        <Tag label={TOPIC} extraClasses={TOPIC} />
        <Tag label={CATEGORY} extraClasses="" />
      </div>
      <div className="t-meta">
        <span>{`${topicDate(RESPONSE_DATE)} · ${CHANNEL}`}</span>
        <span>Ticket: {TICKET}</span>
      </div>
    </div>
  </div>
)

const Feed = ({ comments = [] }) => {
  const [query, setQuery] = useState('')
  const [list, setList] = useState([])
  const [sortKey, setSortKey] = useState('-RESPONSE_DATE')

  const filterList = useCallback(() => {
    const searchText = query.toLowerCase()
    let newList = []
    if (searchText.length > 2) {
      newList = sortBy(sortKey, comments.filter(c => c.COMENTARIO.toLowerCase().search(searchText) > -1 ))
    } else {
      newList = sortBy(sortKey, comments.slice())
    }
    setList(newList)
  }, [comments, sortKey, query])

  useEffect(() => {
    setList(comments)
    filterList()
  }, [comments, query, filterList])

  return (
    <div className="t-feed">
      <div className="t-controls">
        <div>
          <img src="/images/t_feed-icon-search.svg" style={{ marginRight: '12px' }} alt=""/>
          <input
            value={query}
            onChange={e => setQuery(e.target.value)}
            placeholder={`Buscar comentarios`}
          />
          <span style={{ paddingLeft: '1em', fontStyle: 'italic', fontSize: '10px' }}>
            { comments.length === list.length ? `${list.length} comentarios` : `${list.length} / ${comments.length} comentarios` }
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          {/* <div className="t-controls_button">
            <img src="/images/t_feed-icon-download.svg" />
          </div> */}
          <Dropdown
            display="Ordenar"
            menuTitle="Ordenar por"
            options={sortOptions}
            selectedValue={sortKey}
            onChange={o => setSortKey(o.value)}
          />
        </div>
      </div>
      <div className="t-list">
        { list.map(c => <Comment key={c._id} {...c} />) }
      </div>
      {list.length === 0 && (
        <div>
          No hay comentarios
        </div>
      )}
    </div>
  )
}

const TopicFeed = ({ topic }) => {
  const [isMounting, setIsMounting] = useState(true)
  const [loading, setLoading] = useState(true)
  const [segmentDisplay, setSegmentDisplay] = useState('Todos')
  const [segment, setSegment] = useState({})
  const [data, setData] = useState()
  const token = useSelector(state => state.Session.token)
  const filter = useSelector(state => state.Filters.filter)
  const interval = useSelector(state => state.Filters.filters.interval)
  const needUpdate = useSelector(state => state.TextAnalytics.topics.needUpdate )

  function onChangeSegment (o) {
    setSegment(o.value)
    setSegmentDisplay(o.label)
  }

  const loadData = useCallback(async () => {
    setLoading(true)
    let extraFilter = {}
    if (topic !== 'all') {
      extraFilter['COMENTARIO_classify.Topic'] = topic
    }
    if (segment !== 'all') {
      Object.assign(extraFilter, segment)
    }
    const response = await getTopicFeed(token, interval, filter, extraFilter)
    setData(response.data)
    if (isMounting) setIsMounting(false)
    setLoading(false)
  }, [token, interval, filter, topic, segment])

  useEffect(() => {
    needUpdate && loadData()
  }, [needUpdate, loadData])
  
  useEffect(() => {
    if (!isMounting) {
      loadData()
    }
  }, [segment])

  return (
    <div className="topic-card feed">
      <div style={{ padding: '0 24px' }}>
        <CardToolbar
          title="Comentarios"
          display={segmentDisplay}
          options={segmentOptions}
          onChange={onChangeSegment}
        />
      </div>
      {loading && <Loading />}
      {!loading && <Feed comments={data.comments} />}
    </div>
  )
}

export default TopicFeed
