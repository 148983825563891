import { useSelector } from 'react-redux'

export const useQueryConfig = () => {
  const token = useSelector(state => state.Session.token)
  const filter = useSelector(state => state.Filters.filter)
  const interval = useSelector(state => state.Filters.filters.interval)
  const needUpdate = useSelector(state => state.TextAnalytics.topics.needUpdate )

  return {
    token,
    filter,
    interval,
    needUpdate
  }
} 