import React, { Component } from 'react';

export default class ErrorAlert extends Component {

    render() {
        const { error, onDismiss } = this.props;
        return (
            <div className="alert alert-danger mt-5 text-left" role="alert">
                <button type="button" className="close" onClick={onDismiss}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 className="alert-heading">Error</h5>
                {error}
            </div>
        );
    }
}