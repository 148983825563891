import React, { Component } from 'react';
import img from '../../images/brand-logo-white_2.png'
import firebase from '../../firebase';

import ForgetPasswordForm from './ForgotPasswordForm';
import ErrorAlert from '../../widgets/ErrorAlert';

const styles = {
    description: {
        width: "100%",
        maxWidth: "352px",
        padding: "15px"
    },
};

export default class ForgotPassword extends Component {

    state = {
        showSuccessMessage: false,
        isLoading: false,
        error: null,
    }

    handleOnSubmit = async (email) => {
        this.setState({
            isLoading: true,
            error: null,
        });
        try {
            await firebase.sendPasswordResetEmail(email);
            this.setState({
                showSuccessMessage: true,
            });
        }
        catch(ex) {
            const errorCode = ex.code || "default";
            this.handleError(errorCode);
        }
        finally {
            this.setState({isLoading: false});
        }
    }

    handleError = (errorCode) => {
        var error = "";
        switch(errorCode) {
        case "auth/invalid-email":
        case "auth/user-not-found":
            error = "El Correo electrónico no es una cuenta valida.";
            break;
        default:
            error = "Se ha producido un error al intentar enviar el correo elecrónico.";
            break;
        }
        this.setState({error: error});
    }

    handleErrorDismiss = () => {
        this.setState({
            error: null
        });
    }

    render() {
        const { showSuccessMessage, isLoading, error } = this.state;

        const view = showSuccessMessage ?
              <p style={styles.description}>
                Te hemos enviado un correo con las instrucciones para generar una nueva contraseña.
              </p>
              : <ForgetPasswordForm
              isLoading={isLoading}
              onSubmit={this.handleOnSubmit}
       />;

        const errorComponent = (
             error !== null ? 
             <ErrorAlert error={error} onDismiss={this.handleErrorDismiss}/>
             : null
        );

        return (
            <div className="login-wrapper">
                <div className="login-card">
                    <div className="login-brand">
                        <img src={img}  height="80" alt=""/>
                        <h2 className="login-title">Customer Happiness</h2>
                    </div>
                    { errorComponent }
                    <div className="w-form">
                        {view}
                    </div>
                </div>
                <div className="login-media">
                    <div className="login-media-overlay"></div>
                </div>
            </div>
        );
    }
}
