import React from 'react';
import Navbar from '../../widgets/navbar/navbar.jsx';
import Segment from './segment';
import Historical from './historical';
import Distribution from './distribution';
import Mentions from './mentions';

const TextAnalytics = () => (
  <div className="flexRow">
    <div className="flexColumn">
      <Segment/>
      <div style={{marginTop: "24px"}}>
        <Mentions/>
      </div>
    </div>
    <div style={{marginLeft: "24px"}}>
      <Historical/>
      <div style={{marginTop: "24px"}}>
        <Distribution/>
      </div>
    </div>
  </div>
)

export default TextAnalytics;
