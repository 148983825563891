import moment from 'moment';

import { makeTypes, makeActions as mac } from '../utils/reducers';
import { handleError } from './session';
import { SET_CONSTRAINT, SET_SEGMENT } from './segments';
import { getFilters } from '../services/filters';

const t = makeTypes('filters');

//TYPES
const FETCH_FILTERS = t('fetchFilters', true);
const FILTER_SET_INTERVAL = t('setInterval', false);
export const FILTERS_CHANGED = t('filtersChanged', false);
export const SET_FILTER = t('setFilter', false);
const CLEAR_FILTERS = t('clearFilters', false);

//ACTIONS
const fetchFiltersStart = mac(FETCH_FILTERS.START);
const fetchFiltersSuccess = mac(FETCH_FILTERS.SUCCESS, 'payload');
const fetchFiltersError = mac(FETCH_FILTERS.ERROR, 'error');

export const setFilterInterval = mac(FILTER_SET_INTERVAL, 'start', 'end', 'defaultValue');
export const setFilter = mac(SET_FILTER, 'values', 'name');
export const filtersChanged = mac(FILTERS_CHANGED);
export const clearFilters = mac(CLEAR_FILTERS);

const initState = {
    filters: {
        data: [],
        error: null,
        fetching: false,
        interval: {
            start: moment().startOf("month"),
            end: moment(),
            defaultValue: null,
        },
    },
    filter: null,
    defaults: {},
};

const loadFiltersFromSegment = (segment, data) => {
    const defaults = {};
    const filters = {};
    const query = decodeURIComponent(segment.query);
    const queryObj = JSON.parse(query);
    for (const key in queryObj) {
        const filter = data.find(it => it.var === key);
        if (filter !== undefined) {
            const { choices } = filter;
            const defaultArray = [];
            const filtersArray = [];
            const item = queryObj[key];
            const values = item["$in"];
            values.forEach(value => {
                const choice = choices.find(c => c.value === value);
                if (choice !== undefined) {
                    defaultArray.push({
                        label: choice.display,
                        value: choice.value
                    });
                    filtersArray.push(choice.value);
                }
            });
            defaults[key] = defaultArray;
            filters[key] = { "$in": filtersArray };
        }
    }
    return {
        defaults,
        filters: Object.keys(filters).length === 0 ? null : filters
    };
};


//REDUCER

export default function reducer(state = initState, action) {
    switch(action.type) {

    case FETCH_FILTERS.START:
        return {
            ...state,
            filters: {
                ...state.filters,
                fetching: true,
                error: null,
            },
        };
    case FETCH_FILTERS.SUCCESS:
        return {
            ...state,
            filters: {
                ...state.filters,
                fetching: false,
                data: action.payload
            },
        };
    case FETCH_FILTERS.ERROR:
        return {
            ...state,
            filters: {
                ...state.filters,
                fetching: false,
                error: action.error
            },
        };
    case FILTER_SET_INTERVAL:
        return {
            ...state,
            filters: {
                ...state.filters,
                interval: {
                    start: action.start,
                    end: action.end,
                    defaultValue: action.defaultValue
                },
            },
        };
    case SET_FILTER:
        const { name, values } = action;
        let { filter } = state;
        if (filter === null) {
            filter = {};
        }
        let newFilter = {
            ...filter
        };
        let defaults = {
            ...state.defaults
        };

        if (values != null) {
            const vals = values.map(({value}) => (value));
            if (vals.length !== 0) {
                newFilter[name] = { "$in": vals };
                defaults[name] = values;
            }
            else {
                delete newFilter[name];
                delete defaults[name];
            }
        }
        else {
            delete newFilter[name];
            delete defaults[name];
        }
        newFilter = Object.keys(newFilter).length === 0 ? null : newFilter;
        return {
            ...state,
            filter: newFilter,
            defaults,
        };
    case CLEAR_FILTERS:
        return {
            ...state,
            filter: null,
            defaults: {},
        };
    case SET_CONSTRAINT:
    case SET_SEGMENT:
        if (action.payload === null) return state;
        const data = loadFiltersFromSegment(action.payload, state.filters.data);
        const filts = Object.assign({}, state.filter, data.filters)
        const defs = Object.assign(state.defaults, data.defaults)
        return {
            ...state,
            filter: filts,
            defaults: defs
        };
    default:
        return state;
    }
}

export const requestFilters = () =>
    async (dispatch, getState) => {
        const { Session: { token }} = getState();
        dispatch(fetchFiltersStart());
        try {
            const response = await getFilters(token);
            const data = response.data;
            dispatch(fetchFiltersSuccess(data));
        }
        catch(error) {
            console.error(`[filters] requestFilters:: ${error}`);
            dispatch(handleError(error));
            dispatch(fetchFiltersError(error));
        }
    };

export const getFilterToApply = (state) => {
    const { Filters: {filter, filters: { interval } } } = state;
    const { Segments: { selectedValue }} = state;
    const value = selectedValue === null ? encodeURIComponent(JSON.stringify(filter)) : selectedValue.query;
    const filters = {
        filter: value,
        interval,
    };
    return filters;
};
