import React from 'react';
import { connect } from 'react-redux';
import { requestTrends } from '../../../reducers/kpis';
import { COLORS } from '../../../utils/colors';
import { LineChart, CartesianGrid,
         XAxis, YAxis, Tooltip, Line} from 'recharts';
import Loading from '../../../widgets/loading';

const xTickFormatter = (tick, dataLen, group = "") => {
    if (dataLen > 5) {
        return '';
    }
    if (group === "weekly") {
        const values = tick.split(" ").slice(0, 2);
        return values.join(" ");
    }
    return tick;
};

class Chart extends React.Component {
    render() {
        const { data, lines, group, type } = this.props;
        const typeDisplay = type.toUpperCase();
        const len = data.length;
        return(
            <LineChart width={415} height={226} data={data}>
              <XAxis dataKey="display"
                     tickFormatter={(t) => xTickFormatter(t, len, group)}
              />
              <YAxis orientation="right"/>
              <CartesianGrid strokeDasharray="3 3"/>
              <Tooltip offset={40}
                    formatter={(value, label, p) => [`${value} (${p.payload[`${label}_total`]} res.)`, `${label}`]}
                       labelFormatter={(v) => `${typeDisplay} ${v}`}
              />

              {lines.map((b, index) => {
                  return (
                      <Line
                        key={b}
                        type="monotone"
                        dataKey={b}
                        stroke={COLORS[index]}/>
                  );
              })}
            </LineChart>);
    }
}
class TrendsChart extends React.Component {

    loadData = () => {
        const { request } = this.props;
        request();
    }

    componentDidUpdate = (prevProps) => {
        const { needUpdate } = this.props;
        if (needUpdate) {
            this.loadData();
        }
    }

    render() {
        const { data, fetching, group, type, selectedItems } = this.props;
        const chartData = [];
        data.forEach((item, itemIndex) => {
                const { chart } = item;
                chart.forEach((it, itIndex) => {
                    const { display, value, total } = it;
                    if (itemIndex === 0) {
                        chartData.push({
                            display,
                            [item.display]: value,
                            [`${item.display}_total`]: total
                        });
                    }
                    else {
                        const temp = chartData[itIndex];
                        chartData[itIndex] = {
                            ...temp,
                            [item.display]: value,
                            [`${item.display}_total`]: total
                        };
                    }
                });
        });
        const chart = chartData.length === 0 ? null : (
            <Chart
              group={group}
              lines={selectedItems}
              type={type}
              data={chartData}/>
        );
        const view = fetching ? (
            <div style={{margin: "auto auto"}}>
              <Loading/>
            </div>
        ) : (chart);
        return(
            <div style={{marginLeft: "32px",
                         marginTop: "16px",
                         width: "415px",
                         height: "226px"}}>
              {view}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { Kpis: { type,
                    trends: { data, fetching, needUpdate, group },
                    detail: { selectedItems } } } = state;
    return {
        data,
        fetching,
        needUpdate,
        selectedItems,
        group,
        type,
    };
};

const mapDispatchToProps = dispatch => ({
    request: () => dispatch(requestTrends()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrendsChart);
