import React from 'react';

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
};

class Loading extends React.Component {

    render() {
        return(
            <div style={styles.container}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Cargando...</span>
              </div>
            </div >
        );
    }
}

export default Loading;
